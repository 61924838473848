import { PayloadAction } from '@reduxjs/toolkit';
import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import { fileNames } from 'containers/batchProcessing/types';
import {
  clearNotifications,
  notifyErrorAction,
  systemErrorNotificationAction
} from 'containers/notifications/notificationsActions';
import { format, isAfter, parse } from 'date-fns';
import { isString } from 'lodash';
import Router from 'next/router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { validatePhoneNumber } from 'services';
import {
  getEmployeeInfo,
  getOmersOffersNFT,
  getPaginationInformation,
  loadMemberData,
  submitForm101,
  updateForm101,
  getFileBuilder,
  validateUploads,
  submitUploads,
  fetchUploadedBatchNFTs
} from 'services/form101';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { RootState, SysDateObj } from 'types';
import { FileExtensions } from 'types/file-extensions';
import { responseTypes } from 'types/response-type';
import { downloadFile, getApiErrorMessage, tokenSelector } from 'utils';
import clientSide from 'utils/logger/client-side';
import {
  saveOrUpdateForm101Successful,
  setPhoneNumberValidityStatusAction,
  validatePhoneNumberWithCanadaPostAction,
  getEmployeesAction,
  getEmployeesSuccessful,
  getEmployeeInfoAction,
  getEmployeeInfoSuccessAction,
  getPaginationInfoAction,
  getPaginationInfoSuccessAction,
  getOmersOffersNFTAction,
  getOmersOffersNFTSuccessful,
  saveOrUpdateForm101,
  batchUploadRequestAction,
  batchUploadSuccessAction,
  downloadFileBuilderAction,
  fetchUploadedBatchNFTListAction,
  fetchUploadedBatchNFTListSuccessAction,
  fetchUploadedBatchNFTListFailAction,
  getEmployeesFailed,
  batchUploadFailedAction
} from './sliceReducer';

export const earliestDate = '2023-01-01';

export const noEarlierThanDate = (selected: string, noEarlierThan: string) => {
  if (
    isAfter(
      parse(selected, 'yyyy-MM-dd', new Date()),
      parse(noEarlierThan, 'yyyy-MM-dd', new Date())
    )
  ) {
    return selected;
  }
  return noEarlierThan;
};

export const filterPayload = (
  action: PayloadAction<any>,
  sysDate: SysDateObj
): any => ({
  hireDateFrom:
    action?.payload?.hireDate?.fromDate !== undefined
      ? noEarlierThanDate(action?.payload?.hireDate?.fromDate, earliestDate)
      : action?.payload?.hireDateFrom !== undefined
      ? noEarlierThanDate(action?.payload?.hireDateFrom, earliestDate)
      : earliestDate,
  hireDateTo:
    action?.payload?.hireDate?.toDate !== undefined
      ? noEarlierThanDate(action?.payload?.hireDate?.toDate, earliestDate)
      : action?.payload?.hireDateTo !== undefined
      ? noEarlierThanDate(action?.payload?.hireDateTo, earliestDate)
      : sysDate?.dbsysdate?.split('T')?.[0],
  lastName: action?.payload?.lastName,
  createdDateFrom:
    action?.payload?.createdDate?.fromDate !== undefined
      ? action?.payload?.createdDate?.fromDate
      : action?.payload?.createdDateFrom !== undefined
      ? action?.payload?.createdDateFrom
      : undefined,
  createdDateTo:
    action?.payload?.createdDate?.toDate !== undefined
      ? action?.payload?.createdDate?.toDate
      : action?.payload?.createdDateTo !== undefined
      ? action?.payload?.createdDateTo
      : undefined
});

export function* validatePhoneNumberSaga(
  action: PayloadAction<{
    phoneNumber: string;
    isPrimary: boolean;
  }>
): any {
  const token = yield select(tokenSelector);
  const { requestNumber } = yield select((state: RootState) => state.nft);
  const { phoneNumber, isPrimary } = action.payload;

  const res = yield call(
    safeAuthEffect,
    validatePhoneNumber,
    { number: phoneNumber },
    requestNumber,
    token
  );

  if (res?.result?.confidence) {
    let phoneNumberValidityStatus = '';
    switch (res?.result?.confidence) {
      case 'Verified':
      case 'Absent':
        phoneNumberValidityStatus = 'Valid';
        break;
      case 'Unverified':
      case 'Dead':
        phoneNumberValidityStatus = 'Invalid';
        break;
      default:
        phoneNumberValidityStatus = 'Unknown';
        break;
    }

    yield put(
      setPhoneNumberValidityStatusAction({
        phoneNumberValidity: phoneNumberValidityStatus,
        isPrimary
      })
    );
  } else {
    yield put(
      setPhoneNumberValidityStatusAction({
        phoneNumberValidity: 'Unknown',
        isPrimary
      })
    );
  }
}

export function* saveOrUpdateForm101Saga(action: PayloadAction<any>): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const { isEditModeForm101, loadedNFTEmployee } = yield select(
    (state: RootState) => state.nft
  );
  const { form101Data } = action.payload;
  const clientToken = yield select(tokenSelector);
  yield put(startLoadingAction());
  const refineData = {
    ...form101Data,
    dateOfBirth: format(
      parse(form101Data.dateOfBirth, 'MM/dd/yyyy', new Date()),
      'yyyy-MM-dd'
    ),
    hireDate: format(
      parse(form101Data.hireDate, 'MM/dd/yyyy', new Date()),
      'yyyy-MM-dd'
    ),
    homePhoneValidInd: form101Data.homePhoneValidInd === 'Valid',
    mobilePhoneValidIn: form101Data.mobilePhoneValidIn === 'Valid',
    canadaPostValidInd: form101Data.addressInvalidInd === 'valid',
    emailValidInd: form101Data.emailValidInd === 'verified',
    salaryAmount: parseFloat(form101Data.salaryAmount?.split(',')?.join('')),
    modifiedBy: userData.username,
    unionAffiliation: form101Data.unionAffiliation
  };

  if (!refineData.salaryAmount) {
    delete refineData.salaryAmount;
  }

  const addressedHasChanged =
    loadedNFTEmployee?.addressLine1 !== form101Data.addressLine1 ||
    loadedNFTEmployee?.addressLine2 !== form101Data.addressLine2 ||
    loadedNFTEmployee?.aptSuitUnitNumber !== form101Data.aptSuitUnitNumber ||
    loadedNFTEmployee?.country !== form101Data.country ||
    loadedNFTEmployee?.province !== form101Data.province ||
    loadedNFTEmployee?.city !== form101Data.city ||
    loadedNFTEmployee?.postalCode !== form101Data.postalCode;

  const {
    addressInvalidInd,
    homePhoneValidInd,
    mobilePhoneValidIn,
    emailValidInd,
    ...rest
  } = refineData;

  if (loadedNFTEmployee?.addressInvalidInd && addressedHasChanged) {
    rest.addressInvalidInd = false;
  }

  if (clientToken) {
    const res = isEditModeForm101
      ? yield call(
          updateForm101,
          {
            ...rest,
            nftId: loadedNFTEmployee?.nftId
          },
          clientToken,
          userData.username,
          userData.omersUser
        )
      : yield call(
          submitForm101,
          rest,
          clientToken,
          userData.username,
          userData.omersUser
        );
    if (res.result?.success) {
      yield put(saveOrUpdateForm101Successful());
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }
  }
  yield put(clearLoadingAction());
}

export function* getPaginationInfoSaga(action: PayloadAction<any>): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { isAuthenticated } = yield select(
    (state: RootState) => state.userSession
  );

  const sysDate: SysDateObj = yield select(
    (state: RootState) => state.userSession.sysDate
  );

  const dataObject = {
    groupNo: selectGroupNumber,
    employeeId: action?.payload?.employeeId,
    firstName: action?.payload?.firstName,
    ...filterPayload(action, sysDate)
  };

  const clientToken = yield select(tokenSelector);

  if (clientToken && isAuthenticated) {
    try {
      const res = yield call(
        getPaginationInformation,
        dataObject,
        clientToken,
        userData.username,
        userData.omersUser
      );
      const resInvalid = yield call(
        getPaginationInformation,
        {
          groupNo: selectGroupNumber,
          addressInvalidInd: true,
          hireDateFrom: '2023-01-01',
          hireDateTo: sysDate?.dbsysdate?.split('T')?.[0]
        },
        clientToken,
        userData.username,
        userData.omersUser
      );
      if (res.result?.success) {
        yield put(
          getPaginationInfoSuccessAction({
            ...res.result.data,
            invalidCount: resInvalid?.result?.data?.count ?? 0
          })
        );
      } else {
        yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
      }
      yield put(clearLoadingAction());
    } catch (e) {
      clientSide.error((e as Error).message, {}, e as Error);
      yield put(clearLoadingAction());
    }
  }
}

export function* getEmployeesActionSaga(action: PayloadAction<any>): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { reportedEmployeesTable } = yield select(
    (state: RootState) => state.nft
  );

  const sysDate: SysDateObj = yield select(
    (state: RootState) => state.userSession.sysDate
  );

  const clientToken = yield select(tokenSelector);

  const memberFilters = {
    groupNo: selectGroupNumber,
    page: reportedEmployeesTable.currentPage,
    employeeId: action?.payload?.employeeId,
    firstName: action?.payload?.firstName,
    ...filterPayload(action, sysDate)
  };
  if (clientToken) {
    const res = yield call(
      loadMemberData,
      memberFilters,
      clientToken,
      userData.username,
      userData.omersUser
    );

    if (res.result?.success) {
      yield put(getEmployeesSuccessful(res.result?.data));
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
      yield put(getEmployeesFailed());
    }
  }
}

export function* getEmployeeInfoSaga(
  action: PayloadAction<{ nftId: number; groupNo: string }>
): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const clientToken = yield select(tokenSelector);
  const { nftId, groupNo } = action.payload;
  yield put(startLoadingAction());

  if (clientToken && nftId && groupNo) {
    const res = yield call(
      safeAuthEffect,
      getEmployeeInfo,
      nftId,
      groupNo,
      clientToken,
      userData.username,
      userData.omersUser
    );

    if (res.result?.success) {
      yield put(getEmployeeInfoSuccessAction(res.result.data));
      if (Router.route !== '/nft/[id]') {
        yield call(Router.push, `/nft/${res.result.data.nftId}`);
      }
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }
  }
  yield put(clearLoadingAction());
}

export function* getOmersOffersNFTSaga(
  action: PayloadAction<{ groupNo: string }>
): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const clientToken = yield select(tokenSelector);
  const { groupNo } = action.payload;

  if (clientToken && groupNo) {
    const res = yield call(
      safeAuthEffect,
      getOmersOffersNFT,
      groupNo,
      clientToken,
      userData.username,
      userData.omersUser
    );

    if (res.result?.success) {
      yield put(getOmersOffersNFTSuccessful(res.result?.data));
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }
  }
}

export function* batchUploadSaga(action: PayloadAction<any>): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { fileDataAsJSON } = yield select((state: RootState) => state.nft);
  const clientToken = yield select(tokenSelector);

  const validatePayload = {
    groupNo: selectGroupNumber,
    batchuploadsData: action.payload.validateData?.map((a: any) => ({
      ...a,
      salaryAmount: isString(a.salaryAmount)
        ? a.salaryAmount?.replaceAll(',', '')
        : a.salaryAmount
    })),
    userName: userData.username
  };

  const submitPayload = {
    groupNo: selectGroupNumber,
    batchuploadsData: fileDataAsJSON,
    userName: userData.username
  };

  if (clientToken) {
    const res = yield call(
      safeAuthEffect,
      validateUploads,
      validatePayload,
      selectGroupNumber,
      clientToken,
      userData.username,
      userData.omersUser
    );

    if (res?.result?.success) {
      yield put(
        batchUploadSuccessAction({
          errors: res.result?.data,
          dataSet: fileDataAsJSON
        })
      );
      if (res.result?.data?.validationError) {
        yield call(Router.push, `/nft/batchUpload/fixRecords`);
      } else {
        yield put(startLoadingAction());
        yield call(
          safeAuthEffect,
          submitUploads,
          submitPayload,
          selectGroupNumber,
          clientToken,
          userData.username,
          userData.omersUser
        );
        yield call(Router.push, `/nft/batchUpload/submit`);
        yield put(clearLoadingAction());
      }
    } else {
      yield put(batchUploadFailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }
  }
}

export function* downloadFileBuilderSaga(): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const clientToken = yield select(tokenSelector);

  try {
    if (clientToken) {
      const res = yield call(
        getFileBuilder,
        clientToken,
        userData.username,
        userData.omersUser
      );

      if (res?.type === responseTypes.OCTET_STREAM) {
        yield call(
          downloadFile,
          res,
          fileNames.IMPORT_FILE_BUILDER,
          FileExtensions.XLSM
        );
        yield put(clearNotifications());
      } else {
        yield put(systemErrorNotificationAction());
      }
    }
  } catch (e) {
    console.error(e);
    yield put(systemErrorNotificationAction());
  }
}

export function* fetchUploadedBatchNFTListSaga(): any {
  const { userData } = yield select((state: RootState) => state.userSession);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const clientToken = yield select(tokenSelector);

  if (clientToken) {
    const res = yield call(
      safeAuthEffect,
      fetchUploadedBatchNFTs,
      selectGroupNumber,
      clientToken,
      userData.username,
      userData.omersUser
    );

    if (res?.result?.success) {
      yield put(fetchUploadedBatchNFTListSuccessAction(res.result?.data));
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
      yield put(fetchUploadedBatchNFTListFailAction());
    }
  }
}

export function* nftSaga() {
  yield all([
    takeEvery(
      validatePhoneNumberWithCanadaPostAction.type,
      validatePhoneNumberSaga
    ),
    takeEvery(saveOrUpdateForm101.type, saveOrUpdateForm101Saga),
    takeEvery(getEmployeesAction.type, getEmployeesActionSaga),
    takeEvery(getEmployeeInfoAction.type, getEmployeeInfoSaga),
    takeEvery(getPaginationInfoAction.type, getPaginationInfoSaga),
    takeEvery(getOmersOffersNFTAction.type, getOmersOffersNFTSaga),
    takeEvery(batchUploadRequestAction.type, batchUploadSaga),
    takeEvery(downloadFileBuilderAction.type, downloadFileBuilderSaga),
    takeEvery(
      fetchUploadedBatchNFTListAction.type,
      fetchUploadedBatchNFTListSaga
    )
  ]);
}
