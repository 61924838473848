import { EapAction } from 'types';
import { Record, EcorrTopic } from 'interfaces';
import { filterValues } from 'utils/constants';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import { SEARCH_BY_MEMBER } from './messagesFilter/MessagesFilter';
import { messagesActionTypes } from './messagesActions';

// TODO
export interface Message {
  [key: string]: any;
}

export interface MessagesState {
  inbox: Array<Message>;
  inboxCount: number;
  inboxUnresolvedCount: number;
  inboxFilter: {
    filter: {
      isDirty: boolean;
      status: string;
      topic: string;
      searchByMemberSelect: string;
      searchByMemberText: string;
      contact?: string;
    };
    query: {
      status: Array<string> | null;
      topic: string | null;
      employeeId: string | null;
      membershipNo: string | null;
      lastName: string | null;
      showUnreadOnly: boolean;
    };
  };
  messageNew: {
    caseID: string;
    filesPendingUpload: any[];
    linkedMember: any;
    linkMemberSearch: any[];
    linkMemberNoResults: boolean;
    linkMemberLoading: boolean;
    isSendingMessage: boolean;
    isMessageSent: boolean;
    presetTopic: string | null;
    presetSubject: string | null;
    hasAttachment: boolean | null;
    attachmentCategory: string | null;
    attachmentDocumentType: string | null;
    isNewMessageFromMemberProfile?: boolean;
  };
  dmsDocumentList: {
    dmsMetadata: any[];
    pageable: {
      sort: {
        unsorted: boolean | null;
        sorted: boolean | null;
        empty: boolean | null;
      };
      pageNumber: number | null;
      pageSize: number | null;
      offset: number | null;
      paged: boolean | null;
      unpaged: boolean | null;
    };
    last: boolean | null;
    totalPages: number | null;
    totalElements: number | null;
    first: boolean | null;
    numberOfElements: number | null;
    size: number | null;
    number: number | null;
    sort: {
      unsorted: boolean | null;
      sorted: boolean | null;
      empty: boolean | null;
    };
    empty: boolean | null;
  };
  messageThread: {
    caseID: string;
    showSendSuccessNotification: boolean;
    showResolveSuccessNotification: boolean;
    showReopenSuccessNotification: boolean;
    isSendingMessage: boolean;
    filesPendingUpload: any[];
    isMemberLinked: boolean;
    thread: Record;
  };
  loading: boolean;
  ecorrTopics: Array<EcorrTopic>;
  ecorrContacts: Array<any>;
}

export const initialState = {
  inbox: [],
  inboxCount: 0,
  inboxUnresolvedCount: 0,
  inboxFilter: {
    filter: {
      isDirty: false,
      status: filterValues.ALL,
      topic: filterValues.ALL,
      searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
      searchByMemberText: '',
      contact: filterValues.ALL
    },
    query: {
      status: null,
      topic: null,
      employeeId: null,
      membershipNo: null,
      lastName: null,
      showUnreadOnly: false
    }
  },
  messageNew: {
    caseID: '',
    filesPendingUpload: [],
    linkedMember: null,
    linkMemberSearch: [],
    linkMemberNoResults: false,
    linkMemberLoading: false,
    isSendingMessage: false,
    isMessageSent: false,
    presetTopic: null,
    presetSubject: null,
    hasAttachment: null,
    attachmentCategory: null,
    attachmentDocumentType: null,
    isNewMessageFromMemberProfile: false
  },
  dmsDocumentList: {
    dmsMetadata: [],
    pageable: {
      sort: {
        unsorted: null,
        sorted: null,
        empty: null
      },
      pageNumber: null,
      pageSize: null,
      offset: null,
      paged: null,
      unpaged: null
    },
    last: null,
    totalPages: null,
    totalElements: null,
    first: null,
    numberOfElements: null,
    size: null,
    number: null,
    sort: {
      unsorted: null,
      sorted: null,
      empty: null
    },
    empty: null
  },
  messageThread: {
    caseID: '',
    showSendSuccessNotification: false,
    showResolveSuccessNotification: false,
    showReopenSuccessNotification: false,
    isSendingMessage: false,
    filesPendingUpload: [],
    isMemberLinked: false,
    thread: {
      Subject: '',
      OM_SC_Topic__c: '',
      Status: '',
      Contact: {
        OM_Party_Id__c: '',
        Name: '',
        Email: ''
      },
      OM_Related_Employment__r: {
        OM_Membership_Id__c: '',
        Membership_Status__c: ''
      },
      Secure_Communication_Conversation__r: {
        records: []
      },
      Case_Pension_Documents__r: {}
    }
  },
  loading: false,
  ecorrTopics: [],
  ecorrContacts: []
};

const messages = (
  state: MessagesState = initialState,
  action: EapAction
): MessagesState => {
  const { type, data } = action;
  switch (type) {
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
      return {
        ...initialState,
        ecorrTopics: state.ecorrTopics
      };
    case messagesActionTypes.GET_INBOX_REQUESTED:
      return {
        ...state,
        inboxFilter: data,
        loading: true
      };
    case messagesActionTypes.GET_INBOX_SUCCEEDED:
      return {
        ...state,
        inbox: data,
        loading: false,
        inboxFilter: {
          ...state.inboxFilter,
          filter: {
            ...state.inboxFilter.filter,
            isDirty: false
          }
        }
      };
    case messagesActionTypes.GET_INBOX_FAILED:
      return {
        ...state,
        inbox: [],
        loading: false,
        inboxFilter: {
          ...state.inboxFilter,
          filter: {
            ...state.inboxFilter.filter,
            isDirty: false
          }
        }
      };
    case messagesActionTypes.GET_INBOX_COUNT_SUCCEEDED_FAILED:
      return {
        ...state,
        inboxCount: data ?? 0
      };
    case messagesActionTypes.GET_INBOX_UNRESOLVED_COUNT_SUCCESS_FAILED:
      return {
        ...state,
        inboxUnresolvedCount: data ?? 0
      };
    case messagesActionTypes.SET_INBOX_FILTER_IS_DIRTY:
      return {
        ...state,
        inboxFilter: {
          ...state.inboxFilter,
          filter: {
            ...state.inboxFilter.filter,
            isDirty: data
          }
        }
      };
    case messagesActionTypes.RESOLVE_MESSAGE_SUCCEEDED:
      return {
        ...state,
        inbox: state.inbox.map(message => {
          if (message.Id === data.corrId) {
            // eslint-disable-next-line no-param-reassign
            message.Status = data.status;
            return message;
          }
          return message;
        })
      };
    case messagesActionTypes.LINK_MEMBER_SEARCH_REQUESTED:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkMemberLoading: true
        }
      };
    case messagesActionTypes.LINK_MEMBER_SEARCH_SUCCEEDED:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkMemberSearch: data.memberSearchResult.memberships,
          linkMemberNoResults: false,
          linkMemberLoading: false
        }
      };
    case messagesActionTypes.LINK_MEMBER_SEARCH_FAILED:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkMemberNoResults: true,
          linkMemberLoading: false
        }
      };
    case messagesActionTypes.CLEAR_LINK_MEMBER_SEARCH:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkMemberSearch: [],
          linkMemberNoResults: true
        }
      };
    case messagesActionTypes.LINK_MEMBER:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkedMember: data,
          linkMemberNoResults: false
        }
      };
    case messagesActionTypes.UNLINK_MEMBER:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          linkedMember: null,
          linkMemberNoResults: false,
          isNewMessageFromMemberProfile: false
        }
      };
    case messagesActionTypes.ADD_FILE_TO_NEW_MESSAGE_PENDING_UPLOAD_LIST:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          filesPendingUpload: [...state.messageNew.filesPendingUpload, data]
        }
      };
    case messagesActionTypes.REMOVE_FILE_FROM_NEW_MESSAGE_PENDING_UPLOAD_LIST:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          filesPendingUpload: state.messageNew.filesPendingUpload.filter(
            item => item.id !== data.id
          )
        }
      };
    case messagesActionTypes.CLEAR_FILES_NEW_MESSAGE_PENDING_UPLOAD:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          filesPendingUpload: []
        }
      };
    case messagesActionTypes.SEND_MESSAGE_REQUESTED:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          isSendingMessage: true
        }
      };
    case messagesActionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          isSendingMessage: false,
          isMessageSent: true,
          linkedMember: null
        }
      };
    case messagesActionTypes.SEND_MESSAGE_FAILED:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          isSendingMessage: false
        }
      };
    case messagesActionTypes.CLEAR_MESSAGE_SENT:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          isMessageSent: false
        }
      };
    case messagesActionTypes.ADD_FILE_TO_MESSAGE_THREAD_PENDING_UPLOAD_LIST:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          filesPendingUpload: [...state.messageThread.filesPendingUpload, data]
        }
      };
    case messagesActionTypes.REMOVE_FILE_FROM_MESSAGE_THREAD_PENDING_UPLOAD_LIST:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          filesPendingUpload: state.messageThread.filesPendingUpload.filter(
            item => item.id !== data.id
          )
        }
      };
    case messagesActionTypes.CLEAR_FILES_MESSAGE_THREAD_PENDING_UPLOAD:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          filesPendingUpload: []
        }
      };
    case messagesActionTypes.SET_MESSAGE_THREAD_CASE_ID:
      return {
        ...state,
        messageThread: {
          ...initialState.messageThread,
          caseID: data
        }
      };
    case messagesActionTypes.GET_MESSAGE_THREAD_SUCCESS:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showResolveSuccessNotification: false,
          isSendingMessage: false,
          filesPendingUpload: [],
          thread: data.thread,
          showSendSuccessNotification: data.showSendSuccessNotification,
          showReopenSuccessNotification: data.showReopenSuccessNotification,
          isMemberLinked: data.isMemberLinked
        }
      };
    case messagesActionTypes.GET_MESSAGE_THREAD_FAILED:
      return {
        ...state,
        messageThread: {
          ...initialState.messageThread
        }
      };
    case messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_REQUESTED:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          isSendingMessage: true
        }
      };
    case messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_SUCCESS:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showSendSuccessNotification: true,
          isSendingMessage: false
        }
      };
    case messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_FAILED:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          isSendingMessage: false
        }
      };
    case messagesActionTypes.UPDATE_MESSAGE_THREAD_STATUS_SUCCESS:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showResolveSuccessNotification: true,
          thread: {
            ...state.messageThread.thread,
            Status: data
          }
        }
      };
    case messagesActionTypes.HIDE_SEND_MESSAGE_SUCCESS_NOTIFICATION:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showSendSuccessNotification: false
        }
      };
    case messagesActionTypes.HIDE_RESOLVE_MESSAGE_SUCCESS_NOTIFICATION:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showResolveSuccessNotification: false
        }
      };
    case messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_SUCCEEDED:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          thread: {
            ...state.messageThread.thread,
            Status: data
          }
        }
      };
    case messagesActionTypes.HIDE_REOPEN_MESSAGE_SUCCESS_NOTIFICATION:
      return {
        ...state,
        messageThread: {
          ...state.messageThread,
          showReopenSuccessNotification: false
        }
      };
    case messagesActionTypes.OPEN_NEW_MESSAGE_WITH_PRESET_DATA:
    case messagesActionTypes.SET_PRESET_MESSAGE_DATA:
      return {
        ...state,
        messageNew: {
          ...state.messageNew,
          presetTopic: data?.topic ?? null,
          presetSubject: data?.subject ?? null,
          linkedMember: data?.linkedMember ?? null,
          hasAttachment: data?.hasAttachment ?? null,
          attachmentCategory: data?.attachmentCategory ?? null,
          attachmentDocumentType: data?.attachmentDocumentType ?? null,
          isNewMessageFromMemberProfile:
            data?.isNewMessageFromMemberProfile ?? false
        }
      };
    case messagesActionTypes.GET_ECORR_TOPICS_SUCCESS:
      return {
        ...state,
        ecorrTopics: data
      };
    case messagesActionTypes.GET_ECORR_CONTACTS_SUCCESS:
      return {
        ...state,
        ecorrContacts: data
      };
    case messagesActionTypes.GET_DMS_DOCUMENT_LIST_REQUESTED:
      return {
        ...state,
        dmsDocumentList: {
          ...initialState.dmsDocumentList
        }
      };
    case messagesActionTypes.GET_DMS_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        dmsDocumentList: {
          ...data,
          dmsMetadata: data.dmsMetadata || []
        }
      };
    case messagesActionTypes.GET_DMS_DOCUMENT_LIST_FAILED:
      return {
        ...state,
        dmsDocumentList: {
          ...state.dmsDocumentList,
          dmsMetadata: []
        }
      };
    default:
      return state;
  }
};

export default messages;
