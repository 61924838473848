/* eslint-disable consistent-return */
import { get, forEach } from 'lodash';
import { DataObj, FormDataFieldsObj, FileObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getApiErrorMessage } from 'utils';
import { tokenSelector, getStoreState, getStoreDispatch } from 'utils/redux';
import { sessionRefreshedAction } from 'containers/auth/userSessionActions';
import { isServerSide } from 'utils/nextjs';

require('isomorphic-unfetch');

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');

const getHeaders = (token?: string, requestNo?: string) => {
  const truncatedToken = token?.substring(token.length - 20, token.length);
  if (requestNo) {
    return {
      'x-traceability-Id': `EA|${truncatedToken}-${requestNo}|${Date.now()}`
    };
  }
  return { 'x-traceability-Id': `EA|${truncatedToken}|${Date.now()}` };
};

const getToken = () => tokenSelector(getStoreState());

const refreshUserSession = (path: string) => {
  if (isServerSide()) {
    return;
  }
  const blackListedPaths: RegExp[] = [
    /api\/v1\/ecorr\/inbox\/\d+\/count\/unread/g,
    /api\/v1\/ecorrmss\/\d+\/countmss\/unread/g,
    /api\/v1\/auth\/logout/g,
    /api\/v1\/auth\/session-extend/g,
    /api\/email\/send-mail/g // this is an unauthenticated endpoint
  ];
  const url = new URL(path);

  if (
    blackListedPaths.some(blackListedPath => blackListedPath.test(url.pathname))
  ) {
    return;
  }

  const dispatch = getStoreDispatch?.();

  // Anything going through the eaccess-api gets automatically refreshed however
  // everything going through the Donna api does not and requires a manual session refresh call
  dispatch?.(sessionRefreshedAction(true));
};

export const doFetch = async (
  path: string,
  headers: Headers | {},
  method = 'get',
  dataObj: null | undefined | DataObj = null,
  responseType = 'json',
  contentType: string,
  token?: string
) => {
  try {
    const options: any = {
      headers: { ...headers },
      method
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`
      };
    }
    if (method === 'post' || method === 'put' || method === 'delete') {
      options.headers = {
        ...options.headers,
        'Content-Type': contentType
      };
      options.body = dataObj ? JSON.stringify(dataObj) : null;
    }
    const res = await fetch(path, options);
    refreshUserSession(res.url || path);

    // succesful delete doesn't return a Body, have to send a body back to saga
    if (method === 'delete' && (await res.status) === 204) {
      const deleteResponse = {
        status: 204,
        statusText: 'success'
      };
      return Promise.resolve(deleteResponse);
    }

    if (
      (await res.status) >= 200 &&
      (await res.status) < 399 &&
      responseType === 'blob'
    ) {
      return await res.blob();
    }

    const jsonData =
      responseType === 'text' ||
      !res.headers.get('content-type')?.includes('application/json')
        ? {
            status: await res.status,
            token: null
          }
        : await res.json();

    if ((await res.status) >= 200 && (await res.status) < 399) {
      jsonData.token = res.headers.get('jwt-token');
      const status = get(jsonData, 'status');
      const error = get(jsonData, 'error', []);
      if (
        status === 'fail' &&
        Array.isArray(error) &&
        !!error.find(err => err.errorCode === '-1')
      ) {
        return Promise.reject(jsonData.error);
      }
      return Promise.resolve(jsonData);
    }
    const responseError = {
      status: await res.status,
      statusText: await res.statusText,
      url: path,
      errors: get(jsonData, 'errors', [])
    };
    if (
      NEXT_PUBLIC_APP_ENV !== 'prod' &&
      typeof window === 'undefined' &&
      responseError?.errors?.length > 0
    ) {
      console.error(
        `${method.toUpperCase()} API call to ${path} failed with error message: ${getApiErrorMessage(
          responseError.errors
        )}`,
        responseError
      );
    }
    return Promise.resolve(responseError);
  } catch (err) {
    if (NEXT_PUBLIC_APP_ENV !== 'prod') {
      console.error(err);
    }
    const responseError = {
      body: err,
      url: path
    };
    return Promise.resolve(responseError);
  }
};

export const postFileData = async (
  path: string,
  files: {
    formDataFields?: FormDataFieldsObj;
    data: Array<FileObj>;
  },
  token?: string,
  requestNumber?: string,
  responseType = 'json'
) => {
  const actualToken = token ?? getToken();

  try {
    const formData = new FormData();

    forEach(files.formDataFields, (field: string, key: string) =>
      formData.append(key, field)
    );
    files.data.forEach((fileObj: FileObj) =>
      formData.append('fileContent', fileObj.file, fileObj.name)
    );
    if (files.data.length > 0) {
      const fileMetadata = files.data.map((fileObj: FileObj) => ({
        name: fileObj.name,
        docType: fileObj.documentType
      }));
      formData.append('fileMetadata', JSON.stringify(fileMetadata));
    }

    const options = {
      method: 'post',
      body: formData,
      headers: {}
    };
    options.headers = {
      ...options.headers,
      ...getHeaders(actualToken, requestNumber)
    };
    if (actualToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${actualToken}`
      };
    }

    const res = await fetch(path, options);

    if (
      (await res.status) >= 200 &&
      (await res.status) < 399 &&
      responseType === 'blob'
    ) {
      return res.blob();
    }
    const jsonData = await res.json();
    if ((await res.status) >= 200 && (await res.status) < 399) {
      const status = get(jsonData, 'status');
      const error = get(jsonData, 'error', []);
      if (
        status === 'fail' &&
        !!error.find((err: any) => err.errorCode === '-1')
      ) {
        return Promise.reject(jsonData.error);
      }
      return Promise.resolve(jsonData);
    }
    const responseError = {
      status: await res.status,
      statusText: await res.statusText,
      url: path,
      errors: get(jsonData, 'errors', [])
    };
    if (NEXT_PUBLIC_APP_ENV !== 'prod') {
      console.error(
        `POST API call to ${path} failed with error message: ${getApiErrorMessage(
          responseError.errors
        )}`,
        responseError
      );
    }
    return Promise.resolve(responseError);
  } catch (err) {
    if (NEXT_PUBLIC_APP_ENV !== 'prod') {
      console.error(err);
    }
    const responseError = {
      body: err,
      url: path
    };
    return Promise.resolve(responseError);
  }
};

export const getData = (
  path: string,
  token?: string,
  requestNumber?: string,
  responseType = 'json',
  customHeader?: {}
) => {
  const actualToken = token ?? getToken();
  return doFetch(
    path,
    customHeader
      ? { ...getHeaders(actualToken, requestNumber), ...customHeader }
      : getHeaders(actualToken, requestNumber),
    'get',
    null,
    responseType,
    '',
    actualToken
  );
};

export const postData = (
  path: string,
  dataObj?: DataObj,
  token?: string,
  requestNumber?: string,
  responseType = 'json',
  contentType = 'application/json',
  customHeader?: {}
) => {
  const actualToken = token ?? getToken();

  if (dataObj !== undefined) {
    return doFetch(
      path,
      customHeader
        ? { ...getHeaders(actualToken, requestNumber), ...customHeader }
        : getHeaders(actualToken, requestNumber),
      'post',
      dataObj,
      responseType,
      contentType,
      actualToken
    );
  }
  return doFetch(
    path,
    customHeader
      ? { ...getHeaders(actualToken, requestNumber), ...customHeader }
      : getHeaders(actualToken, requestNumber),
    'post',
    null,
    responseType,
    contentType,
    actualToken
  );
};

export const putData = (
  path: string,
  dataObj?: DataObj,
  token?: string,
  requestNumber?: string,
  responseType = 'json',
  contentType = 'application/json',
  customHeader?: {}
) => {
  const actualToken = token || getToken();
  return doFetch(
    path,
    customHeader
      ? { ...getHeaders(actualToken, requestNumber), ...customHeader }
      : getHeaders(actualToken, requestNumber),
    'put',
    dataObj,
    responseType,
    contentType,
    actualToken
  );
};

export const deleteData = (
  path: string,
  token?: string,
  requestNumber?: string,
  dataObj?: DataObj,
  responseType = 'json',
  contentType = 'application/json'
) => {
  const actualToken = token ?? getToken();
  return doFetch(
    path,
    getHeaders(actualToken, requestNumber),
    'delete',
    dataObj,
    responseType,
    contentType,
    actualToken
  );
};

export const parseQueriesFromString = (url: string) => {
  let urlToParse = url;
  if (urlToParse) {
    const beginsWithQMarkOrHash = /^[?#]/.test(urlToParse);
    if (beginsWithQMarkOrHash) {
      urlToParse = urlToParse.slice(1);
    }
    return urlToParse.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      const tempQueryPair: any = { ...params };
      tempQueryPair[key] = value
        ? decodeURIComponent(value.replace(/\+/g, ' '))
        : ' ';

      return tempQueryPair;
    }, {});
  }
  return {};
};

export const makePingFederateCall = async (
  url: string,
  method: string,
  headers?: DataObj,
  payload?: DataObj
) => {
  const res = await fetch(url, {
    method,
    credentials: 'include',
    headers,
    body: JSON.stringify(payload)
  });

  const jsonData = await res.json();
  return Promise.resolve(jsonData);
};
