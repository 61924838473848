export const errorCodes = {
  ErrorCodeEntry: [
    {
      Code: 'GENERIC_ERROR',
      DefaultMsg:
        'System error. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: 'GENERIC_INVALID_DATE',
      DefaultMsg:
        '%0 is an invalid date. Please provide dates in the mm/dd/yyyy format.'
    },
    {
      Code: 'GENERIC_MANDATORY_FIELD',
      DefaultMsg: '%0 is a required field.'
    },
    {
      Code: 'FORM152_INVALID_DATE_ONSAVE',
      DefaultMsg: 'Unable to save the form. "%0" is not a valid date.'
    },
    {
      Code: 'NOT_FOUND',
      DefaultMsg:
        'Request not found. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: 'INVALID_CREDENTIALS',
      DefaultMsg:
        'Invalid username or password, please try again. Keep in mind that after 5 failed attempts, your account will be locked.'
    },
    {
      Code: 'ACCESS_DENIED',
      DefaultMsg:
        'We are currently unable to provide you with access. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: 'ACCOUNT_LOCKED',
      DefaultMsg:
        'Account is locked. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: 'DISABLED_USER',
      DefaultMsg:
        'Account is disabled. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: 'INVALID_VERIFICATION_CODE',
      DefaultMsg: "Your code doesn't match our records. Please try again."
    },
    {
      Code: '-1',
      DefaultMsg:
        'System error. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: -1'
    },
    {
      Code: '1',
      DefaultMsg:
        'Invalid username or password, please try again. Keep in mind that after 5 failed attempts, your account will be locked.'
    },
    {
      Code: '2',
      DefaultMsg:
        'Account locked because maximum number of sign in attempts exceeded. Please use the Forgotten password? option to reset your password.  OMERS Ref. No: 2'
    },
    {
      Code: '3',
      DefaultMsg:
        'Account locked because password change required. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3'
    },
    {
      Code: '4',
      DefaultMsg:
        'We are currently unable to provide you with access. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 4'
    },
    {
      Code: '5',
      DefaultMsg:
        'Account is locked. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    {
      Code: '6',
      DefaultMsg:
        'The information entered does not match our records. Please try again.'
    },
    {
      Code: '7',
      DefaultMsg:
        'Account is disabled. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
    },
    // Code 8: PSW_CHANGE_DENIED("8", "Password change denied")
    // Code 9: OMERS_PING_ERROR("9", "OmersPing error")
    {
      Code: '11',
      DefaultMsg:
        'The e-Form 143 adjustment request cannot be submitted as the membership is currently active. Please try to submit a new e-Form 143 request.'
    },
    {
      Code: '12',
      DefaultMsg: 'The current password that you entered is incorrect.'
    },
    {
      Code: '13',
      DefaultMsg:
        'You must enter a new password that meets the minimum complexity requirements and is different from your 3 previously used passwords.'
    },
    {
      Code: '14',
      DefaultMsg:
        'Your new password must be different from your current password.'
    },
    {
      Code: '101',
      DefaultMsg:
        'The SIN is invalid. Please check the number. OMERS Ref. No: 101'
    },
    {
      Code: '102',
      DefaultMsg: 'Please enter a SIN. OMERS Ref. No: 102'
    },
    {
      Code: '103',
      DefaultMsg: 'Please enter a group number. OMERS Ref. No: 103'
    },
    {
      Code: '104',
      DefaultMsg: 'The group number is invalid.  OMERS Ref. No: 104'
    },
    {
      Code: '105',
      DefaultMsg: 'The group number does not exist.  OMERS Ref. No: 105'
    },
    {
      Code: '106',
      DefaultMsg:
        'The group number entered is not valid for OMERS Users.  OMERS Ref. No: 106'
    },
    {
      Code: '107',
      DefaultMsg:
        'Please select a form for which you want to create a request. OMERS Ref. No: 107'
    },
    {
      Code: '202',
      DefaultMsg:
        'The membership number must be a valid number greater than zero.  OMERS Ref. No: 202'
    },
    {
      Code: '203',
      DefaultMsg:
        'The employer group number must be a valid six-digit number.  OMERS Ref. No: 203'
    },
    {
      Code: '204',
      DefaultMsg:
        'At least one search criteria must be entered.  OMERS Ref. No: 204'
    },
    {
      Code: '205',
      DefaultMsg:
        'We cannot find a matching membership.  Please check the search criteria you have entered.  OMERS Ref. No: 205'
    },
    {
      Code: '206',
      DefaultMsg:
        'Results matching the entered search criteria exceed the maximum. Please refine your search.  OMERS Ref. No: 206'
    },
    {
      Code: '207',
      DefaultMsg: 'Please select a member.  OMERS Ref. No: 207'
    },
    {
      Code: '208',
      DefaultMsg:
        'More than one search criteria required when searching by middle initial.  OMERS Ref. No: 208'
    },
    {
      Code: '304',
      DefaultMsg: 'The From date is not a valid date.  OMERS Ref. No: 304'
    },
    {
      Code: '305',
      DefaultMsg: 'The To date is not a valid date.  OMERS Ref. No: 305'
    },
    {
      Code: '306',
      DefaultMsg:
        'At least one search criteria must be specified for a event search.  OMERS Ref. No: 306'
    },
    {
      Code: '307',
      DefaultMsg: 'No Events match the search criteria.  OMERS Ref. No: 307'
    },
    {
      Code: '308',
      DefaultMsg:
        'There are too many results returned from your search.  Please refine your search.  OMERS Ref. No: 308'
    },
    {
      Code: '310',
      DefaultMsg:
        "SIN is not valid when searching for 'All members'.  OMERS Ref. No: 310"
    },
    {
      Code: '311',
      DefaultMsg:
        'Employer group number is not valid when searching for a single member.  OMERS Ref. No: 311'
    },
    {
      Code: '312',
      DefaultMsg:
        'The From date can not be after the To date.  OMERS Ref. No: 312'
    },
    {
      Code: '314',
      DefaultMsg:
        "Employer group number is a required field when searching for 'All members'.  OMERS Ref. No: 314"
    },
    {
      Code: '315',
      DefaultMsg:
        'You must enter at least SIN or Employee Id when searching for a single member.  OMERS Ref. No: 315'
    },
    {
      Code: '316',
      DefaultMsg:
        'The selected member cannot be deleted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 316'
    },
    {
      Code: '317',
      DefaultMsg:
        'At least one search criteria must be specified for a request search.  OMERS Ref. No: 317'
    },
    {
      Code: '318',
      DefaultMsg: 'No Requests match the search criteria.  OMERS Ref. No: 318'
    },
    {
      Code: '319',
      DefaultMsg:
        'The status of the selected request was changed by another user. Please press the search button to refresh the search results. OMERS Ref. No: 319'
    },
    {
      Code: '320',
      DefaultMsg: 'Please enter the Form month and year.  OMERS Ref. No: 320'
    },
    {
      Code: '321',
      DefaultMsg:
        'The selected request was deleted by another user. Please click the "New Search" button to perform another search. OMERS Ref. No: 321'
    },
    {
      Code: '322',
      DefaultMsg:
        'You must enter Employer group number and at least SIN or Employee Id when searching for a single member.  OMERS Ref. No: 322'
    },
    {
      Code: '323',
      DefaultMsg:
        'No e-Correspondence match the search criteria.  OMERS Ref. No: 323'
    },
    {
      Code: '402',
      DefaultMsg:
        'Answers have to be longer than 5 characters.  OMERS Ref. No: 402'
    },
    {
      Code: '403',
      DefaultMsg: 'Answer is a required field.  OMERS Ref. No: 403'
    },
    {
      Code: '404',
      DefaultMsg: 'The answers do not match.  OMERS Ref. No: 404'
    },
    {
      Code: '501',
      DefaultMsg: 'Password is a required field.  OMERS Ref. No: 501'
    },
    {
      Code: '602',
      DefaultMsg:
        'New password and confirm password do not match.  OMERS Ref. No:  602'
    },
    {
      Code: '603',
      DefaultMsg:
        'Questions and answers are required fields.  OMERS Ref. No: 603'
    },
    {
      Code: '604',
      DefaultMsg: 'Questions and answers have to be unique.  OMERS Ref. No: 604'
    },
    {
      Code: '605',
      DefaultMsg:
        'Please select another password since you have used this one previously.  OMERS Ref. No: 605 '
    },
    {
      Code: '606',
      DefaultMsg:
        'New password and confirm password are required fields.  OMERS Ref. No: 606 '
    },
    {
      Code: '607',
      DefaultMsg:
        'Your password cannot contain any portion of your username.  OMERS Ref. No: 607 '
    },
    {
      Code: '608',
      DefaultMsg:
        'Your password cannot have repeated characters.  OMERS Ref. No: 608 '
    },
    {
      Code: '609',
      DefaultMsg:
        'Your password must contain a number, letter and one special character (for example: !, @, #, $)  OMERS Ref. No: 609 '
    },
    {
      Code: '610',
      DefaultMsg:
        'Common words (eg., OMERS, OMERS pass, 123, 321) are not permitted in your password.   OMERS Ref. No: 610 '
    },
    {
      Code: '801',
      DefaultMsg:
        'Enter the Date last worked. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 801 '
    },
    {
      Code: '802',
      DefaultMsg:
        'The format of the date is incorrect. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 802 '
    },
    {
      Code: '803',
      DefaultMsg:
        'Select a Reason for requesting an OMERS plan benefit from the dropdown list.   OMERS Ref. No: 803 '
    },
    {
      Code: '804',
      DefaultMsg:
        'Select a Reason for the Difference from the dropdown list. (If the Last date of contibutions is different from the Date employment ended (or Date of Death), you must enter a reason for the difference in dates.)   OMERS Ref. No: 804 '
    },
    {
      Code: '805',
      DefaultMsg:
        'The benefit reason cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 805 '
    },
    {
      Code: '806',
      DefaultMsg:
        'The PA cannot be calculated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 806 '
    },
    {
      Code: '807',
      DefaultMsg:
        'The Employment Information cannot be retrieved. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 807 '
    },
    {
      Code: '809',
      DefaultMsg:
        'Select a Reason for the Difference from the dropdown list. (If the Last date of contributions is different from the Date of death, you must enter a reason for the difference in dates.)  OMERS Ref. No: 809 '
    },
    {
      Code: '810',
      DefaultMsg:
        'The Last date of contributions must be earlier than the Date of Death. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 810 '
    },
    {
      Code: '811',
      DefaultMsg:
        'The Total disability date must be on or before the current date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 811 '
    },
    {
      Code: '812',
      DefaultMsg:
        'Enter the Total disability date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 812 '
    },
    {
      Code: '813',
      DefaultMsg:
        'The Last date of regular earnings must be on or after the Date last worked (or Date of death). Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 813 '
    },
    {
      Code: '814',
      DefaultMsg:
        'Enter the Date of death. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 814 '
    },
    {
      Code: '815',
      DefaultMsg:
        'You do not need to enter the Last date of contributions if it is the same as the Date last worked. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 815 '
    },
    {
      Code: '816',
      DefaultMsg:
        'You do not need to enter the Last date of contributions if it is the same as the Date employment ended. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 816 '
    },
    {
      Code: '817',
      DefaultMsg:
        'You do not need to enter the Last date of contributions if it is the same as the Date of Death. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 817 '
    },
    {
      Code: '826',
      DefaultMsg:
        'Select Yes/No to indicate if the member has applied for a CPP Disability Pension.   OMERS Ref. No: 826 '
    },
    {
      Code: '827',
      DefaultMsg:
        'If you selected Yes, to indicate that the member has applied for a CPP Disability Pension, you must select the status of the application.   OMERS Ref. No: 827 '
    },
    {
      Code: '828',
      DefaultMsg:
        'Select Yes/No to indicate if the benefit request is for a Type 3 Supplementary Pension.   OMERS Ref. No: 828 '
    },
    {
      Code: '829',
      DefaultMsg:
        'Select Yes/No to indicate if the member has applied for the Workplace Safety and Insurance Board (WSIB) benefit.   OMERS Ref. No: 829 '
    },
    {
      Code: '830',
      DefaultMsg:
        'If you selected Yes, to indicate that the member has applied for the Workplace Safety and Insurance Board (WSIB) benefit, you must select the status of the application.   OMERS Ref. No: 830 '
    },
    {
      Code: '831',
      DefaultMsg:
        'Enter the Approved - monthly benefit amount. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 831 '
    },
    {
      Code: '832',
      DefaultMsg:
        'Select Yes/No to indicate if the member has applied for Long-term Disability (LTD).   OMERS Ref. No: 832 '
    },
    {
      Code: '833',
      DefaultMsg:
        'Enter the Annual salary rate at date of disability. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 833 '
    },
    {
      Code: '834',
      DefaultMsg:
        'Select Yes/No to indicate if the member has chosen the Advanced Election Option.   OMERS Ref. No: 834 '
    },
    {
      Code: '835',
      DefaultMsg:
        'Enter a valid monthly benefit amount. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 835 '
    },
    {
      Code: '836',
      DefaultMsg:
        'Enter a valid annual salary rate at date of disability. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 836 '
    },
    {
      Code: '837',
      DefaultMsg:
        'The Supporting Information cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 837 '
    },
    {
      Code: '838',
      DefaultMsg:
        'The Supporting Information cannot be updated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 838 '
    },
    {
      Code: '839',
      DefaultMsg:
        'The Supporting Information cannot be retrieved. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 839 '
    },
    {
      Code: '840',
      DefaultMsg: 'Multiple Selection Error.  OMERS Ref. No: 840 '
    },
    {
      Code: '843',
      DefaultMsg:
        'If you selected Yes for the Advanced Election Option, you must attach a Form 143-Advanced Election Option.  Click the Advanced Election Option link to go to the Advanced Election Option screen and complete the form.   OMERS Ref. No: 843 '
    },
    {
      Code: '844',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the notes field.  Format: Limited to 2000 alphanumeric characters.   OMERS Ref. No: 844  '
    },
    {
      Code: '845',
      DefaultMsg: 'The birth date is mandatory.   OMERS Ref. No: 844  '
    },
    {
      Code: '846',
      DefaultMsg:
        'The Marital Status of the member in the Supporting Information tab is mandatory.   OMERS Ref. No: 846  '
    },
    {
      Code: '850',
      DefaultMsg:
        'The area code must be in the range 200 - 999.  OMERS Ref. No: 850 '
    },
    {
      Code: '851',
      DefaultMsg: 'Max Area Code Exceeded.   OMERS Ref. No: 851 '
    },
    {
      Code: '852',
      DefaultMsg: 'No city   OMERS Ref. No: 852 '
    },
    {
      Code: '853',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Postal/Zip code field. If the country is Canada, the postal code must be Letter#Letter #Letter#, or Letter#Letter#Letter#.  For example, Q1W 2E3 or Q1W2E3. If the country is United States, the zip code can be entered as five or nine numeric characters.   OMERS Ref. No: 853'
    },
    {
      Code: '854',
      DefaultMsg:
        'Enter a valid Area Code. Format: Area code limited to 3 numbers.   OMERS Ref. No: 854 '
    },
    {
      Code: '855',
      DefaultMsg:
        'Enter a valid phone number. Format: Phone number limited to 19 characters. Enter a number with an extension as: 555-1212 ext 123456.  OMERS Ref. No: 855 '
    },
    {
      Code: '866',
      DefaultMsg:
        'Enter a valid phone number. Format: Phone number limited to 8 characters. Enter a number with an extension as: 555-1212  OMERS Ref. No: 866 '
    },
    {
      Code: '856',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Address 1 field. Format: Address 1 is limited to 40 alphanumeric characters.    OMERS Ref. No: 856 '
    },
    {
      Code: '857',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Address 2 field. Format: Address 2 is limited to 40 alphanumeric characters.    OMERS Ref. No: 857 '
    },
    {
      Code: '858',
      DefaultMsg:
        'You have exceeded the maximum number of characters for Apartment/Suite/Unit field. Format:  Apartment/Suite/Unit is limited to 10 alphanumeric characters.   OMERS Ref. No: 858 '
    },
    {
      Code: '859',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the City field. Format: City is limited to 35 alphanumeric characters.  OMERS Ref. No: 859 '
    },
    {
      Code: '860',
      DefaultMsg: 'No Address 1 Input.  OMERS Ref. No: 860 '
    },
    {
      Code: '861',
      DefaultMsg:
        'The province/state selected is invalid for the country you have chosen.  OMERS Ref. No: 861 '
    },
    {
      Code: '862',
      DefaultMsg:
        'The Address Information cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 862 '
    },
    {
      Code: '863',
      DefaultMsg:
        'The Address Information cannot be updated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 863 '
    },
    {
      Code: '864',
      DefaultMsg:
        'The Address Information cannot be retrieved. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 864 '
    },
    {
      Code: '877',
      DefaultMsg:
        'Enter only alphabetic characters in the Middle name field. Format: The middle name is limited to 15 characters.   OMERS Ref. No: 877 '
    },
    {
      Code: '879',
      DefaultMsg:
        'Please input a value in the First address field.   OMERS Ref. No: 879 '
    },
    {
      Code: '880',
      DefaultMsg:
        'Please input a value in the City field.   OMERS Ref. No: 880 '
    },
    {
      Code: '883',
      DefaultMsg: 'Invalid characters in other text field.  OMERS Ref. No: 883 '
    },
    {
      Code: '886',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Middle name. Format: The middle name is alphabetic and is limited to 15 characters. OMERS Ref. No:  886'
    },
    {
      Code: '887',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Department ID. Format: The Department ID is limited to 15 characters. OMERS Ref. No:  887'
    },
    {
      Code: '892',
      DefaultMsg:
        'Enter a valid SIN. Format: Must contain nine numbers.   OMERS Ref. No: 892  '
    },
    {
      Code: '893',
      DefaultMsg:
        'If you selected Other, enter the Relationship to member in the text field.   OMERS Ref. No: 893  '
    },
    {
      Code: '894',
      DefaultMsg:
        'The Contact information cannot be inserted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 894  '
    },
    {
      Code: '895',
      DefaultMsg:
        'The Contact information cannot be updated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 895  '
    },
    {
      Code: '897',
      DefaultMsg:
        'The Contact Information cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 897  '
    },
    {
      Code: '899',
      DefaultMsg:
        'The Contact Information cannot be retrieved. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 899  '
    },
    {
      Code: '901',
      DefaultMsg:
        'Enter only alphabetic characters in the First name field. Format: The first name is limited to 15 characters.   OMERS Ref. No: 901  '
    },
    {
      Code: '902',
      DefaultMsg:
        'Enter only alphabetic characters in the Last name field. Format: The last name is limited to 50 characters.   OMERS Ref. No: 902  '
    },
    {
      Code: '903',
      DefaultMsg:
        'Invalid characters have been input for the Title.  Format: Limited to 40 characters.  OMERS Ref. No: 903  '
    },
    {
      Code: '904',
      DefaultMsg:
        'Invalid characters have been input for the Department. Format: Limited to 40 characters.  OMERS Ref. No: 904  '
    },
    {
      Code: '905',
      DefaultMsg:
        'Enter a valid e-mail address. For example: jsmith@cityofexample.org. Format: Limited to 100 characters.  OMERS Ref. No: 905  '
    },
    {
      Code: '908',
      DefaultMsg:
        'Enter a valid fax area code. Format: Fax area code limited to 3 numbers.   OMERS Ref. No: 908  '
    },
    {
      Code: '909',
      DefaultMsg:
        'Enter a valid fax number. Format: Fax number limited to 20 characters.   OMERS Ref. No: 909  '
    },
    {
      Code: '910',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the First name field. Format: The first name field is alphabetic and is limited to 15 characters.  OMERS Ref. No: 910'
    },
    {
      Code: '911',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Last name. Format: The last name field is alphabetic and is limited to 30 characters.  OMERS Ref. No: 911'
    },
    {
      Code: '912',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Title field. Format: Limited to 40 characters.  OMERS Ref. No: 912  '
    },
    {
      Code: '913',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Department field. Format: Limited to 40 characters.  OMERS Ref. No: 913  '
    },
    {
      Code: '914',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the e-mail field. Format: Limited to 100 characters.  OMERS Ref. No: 914  '
    },
    {
      Code: '915',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Telephone area code field. Format: Area code limited to 3 numbers.  OMERS Ref. No: 915  '
    },
    {
      Code: '916',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Telephone number field. Format: Phone number limited to 20 characters.  OMERS Ref. No: 916  '
    },
    {
      Code: '917',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Fax area code field. Format: Fax area code limited to 3 numbers.  OMERS Ref. No: 917  '
    },
    {
      Code: '918',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Fax number field. Format: Fax number limited to 20 characters.  OMERS Ref. No: 918  '
    },
    {
      Code: '919',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Notepad field.  Format: Limited to 2000 alphanumeric characters.   OMERS Ref. No: 919  '
    },
    {
      Code: '920',
      DefaultMsg:
        'The Employment information cannot be inserted.  Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 920  '
    },
    {
      Code: '921',
      DefaultMsg:
        'The Employment information cannot be updated.  Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 921  '
    },
    {
      Code: '929',
      DefaultMsg:
        'Enter a valid SIN. Format: Must contain nine numbers.   OMERS Ref. No: 929  '
    },
    {
      Code: '932',
      DefaultMsg: 'Max Middle Name Length Exceeded.  OMERS Ref. No: 932  '
    },
    {
      Code: '951',
      DefaultMsg: 'Enter the banking institution number.  OMERS Ref. No: 951  '
    },
    {
      Code: '952',
      DefaultMsg: 'Enter the bank transit number.  OMERS Ref. No: 952  '
    },
    {
      Code: '953',
      DefaultMsg: 'No Info.  OMERS Ref. No: 953  '
    },
    {
      Code: '954',
      DefaultMsg:
        'Invalid Bank #.  Enter only numeric characters in the Bank # field.  OMERS Ref. No: 954  '
    },
    {
      Code: '955',
      DefaultMsg:
        'Invalid Transit #.  Enter only numeric characters in the Transit # field.  OMERS Ref. No: 955  '
    },
    {
      Code: '956',
      DefaultMsg: 'Enter the Bank name.  OMERS Ref. No: 956  '
    },
    {
      Code: '957',
      DefaultMsg: 'Enter the Bank address.  OMERS Ref. No: 957  '
    },
    {
      Code: '958',
      DefaultMsg: 'No Bank Account Number.  OMERS Ref. No: 958  '
    },
    {
      Code: '981',
      DefaultMsg:
        'Enter only numeric characters in the Telephone area code field.    OMERS Ref. No: 981  '
    },
    {
      Code: '983',
      DefaultMsg:
        'Enter only numeric characters in the Fax area code field.   OMERS Ref. No: 983  '
    },
    {
      Code: '984',
      DefaultMsg:
        'Enter only numeric characters in the Fax number field.   OMERS Ref. No: 984  '
    },
    {
      Code: '997',
      DefaultMsg:
        'The Contact information cannot be deleted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 997  '
    },
    {
      Code: '1001',
      DefaultMsg: 'Invalid user name.  OMERS Ref. No: 1001  '
    },
    {
      Code: '1002',
      DefaultMsg: 'User name is a required field.  OMERS Ref. No: 1002  '
    },
    {
      Code: '1003',
      DefaultMsg: 'The account is inactive OMERS Ref. No: 1003  '
    },
    {
      Code: '1004',
      DefaultMsg: 'Maximum password attempts exceeded OMERS Ref. No: 1004  '
    },
    {
      Code: '1005',
      DefaultMsg: 'Questions and answers cannot match OMERS Ref. No: 1005  '
    },
    {
      Code: '1006',
      DefaultMsg:
        'Questions and answers are required fields OMERS Ref. No: 1006  '
    },
    {
      Code: '1007',
      DefaultMsg: 'Invalid user name or password.  OMERS Ref. No: 1007  '
    },
    {
      Code: '1010',
      DefaultMsg:
        'Required data missing for lock request.  OMERS Ref. No: 1010  '
    },
    {
      Code: '1011',
      DefaultMsg:
        'Presently there is no support for the selected request. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 1011  '
    },
    {
      Code: '1012',
      DefaultMsg:
        'A request already exists for the selected member.   OMERS Ref. No: 1012  '
    },
    {
      Code: '1013',
      DefaultMsg: 'Required fields are missing.   OMERS Ref. No: 1013  '
    },
    {
      Code: '1014',
      DefaultMsg:
        'The request cannot be initiated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 1014  '
    },
    {
      Code: '1015',
      DefaultMsg:
        'System exception has occurred. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 1015  '
    },
    {
      Code: '1016',
      DefaultMsg:
        'Postal/Zip code does not conform to standards.  If the country is Canada, the postal code must be Letter#Letter #Letter#, or Letter#Letter#Letter#.  For example, Q1W 2E3 or Q1W2E3. If the country is United States, the zip code can be entered as five or nine numeric characters.  OMERS Ref. No: 1016'
    },
    {
      Code: '1018',
      DefaultMsg:
        'Your password must be no longer than 15 characters.   OMERS Ref. No: 1018  '
    },
    {
      Code: '1019',
      DefaultMsg:
        'Challenge questions must be at least 5 characters long.  OMERS Ref. No: 1019  '
    },
    {
      Code: '1020',
      DefaultMsg:
        'Challenge answers must be at least 2 characters long.  OMERS Ref. No: 1020  '
    },
    {
      Code: '1008',
      DefaultMsg:
        'Your password must be at least 8 characters long.   OMERS Ref. No: 1008  '
    },
    {
      Code: '1021',
      DefaultMsg:
        'If Area Code is entered, Phone number must also be entered. OMERS Ref. No: 1021  '
    },
    {
      Code: '1022',
      DefaultMsg:
        'Area code must be entered for Phone number or Fax number.  OMERS Ref. No: 1022  '
    },
    {
      Code: '1023',
      DefaultMsg:
        'Generation of e-Form PDF had errors. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 1023  '
    },
    {
      Code: '1024',
      DefaultMsg: 'Please enter a search criteria.  OMERS Ref. No: 1024 '
    },
    {
      Code: '1025',
      DefaultMsg: 'Please enter a group number. OMERS Ref. No: 1025 '
    },
    {
      Code: '1026',
      DefaultMsg: 'Invalid Group Number.  OMERS Ref. No: 1026 '
    },
    {
      Code: '1027',
      DefaultMsg: 'Invalid First Name.  OMERS Ref. No: 1027 '
    },
    {
      Code: '1028',
      DefaultMsg: 'Invalid Last Name.  OMERS Ref. No: 1028 '
    },
    {
      Code: '1029',
      DefaultMsg: 'Invalid SIN.  OMERS Ref. No: 1029 '
    },
    {
      Code: '1030',
      DefaultMsg: 'Invalid Email.  OMERS Ref. No: 1030 '
    },
    {
      Code: '1031',
      DefaultMsg: 'Username already exists.  OMERS Ref. No: 1031 '
    },
    {
      Code: '1032',
      DefaultMsg: 'Search exceeds 50 users.  OMERS Ref. No: 1032 '
    },
    {
      Code: '1033',
      DefaultMsg: 'No Email entered.  OMERS Ref. No: 1033 '
    },
    {
      Code: '1034',
      DefaultMsg:
        'The only e-access role that can be assigned to an OMERS user type is Financial.  OMERS Ref. No: 1034 '
    },
    {
      Code: '1101',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Pension adjustment field. Format: Limited to 5 numbers with no decimal.  OMERS Ref. No: 1101  '
    },
    {
      Code: '1102',
      DefaultMsg:
        'You can only enter numbers in the Pension Adjustment field. Format: Limited to 5 numbers with no decimal.   OMERS Ref. No: 1102  '
    },
    {
      Code: '1103',
      DefaultMsg:
        'You can only enter numbers in the Contributory Earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1103 '
    },
    {
      Code: '1104',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Number of pay periods field. Format: Limited to 2 characters.   OMERS Ref. No: 1104 '
    },
    {
      Code: '1106',
      DefaultMsg:
        'Enter the RPP contributions amount. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1106 '
    },
    {
      Code: '1107',
      DefaultMsg:
        'You can only enter  numbers in the RPP Contributions field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1107 '
    },
    {
      Code: '1108',
      DefaultMsg:
        'Enter the RCA contributions amount. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1108 '
    },
    {
      Code: '1110',
      DefaultMsg:
        'Enter only numeric characters in the RCA contributions field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1110 '
    },
    {
      Code: '1112',
      DefaultMsg:
        'Enter the Type 6 supplementary contributions.   OMERS Ref. No: 1112 '
    },
    {
      Code: '1113',
      DefaultMsg:
        'Enter only numeric characters in the Type 6 supplementary field. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1113 '
    },
    {
      Code: '1114',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Type 6 supplementary periods field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1114 '
    },
    {
      Code: '1115',
      DefaultMsg:
        'Enter the Credited service (months). Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1115 '
    },
    {
      Code: '1116',
      DefaultMsg:
        'The amount entered in the Credited service field is invalid. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal. OMERS Ref. No: 1116 '
    },
    {
      Code: '1117',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Credited service field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1117 '
    },
    {
      Code: '1118',
      DefaultMsg:
        'The amount entered in the Service to include field is invalid. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal. OMERS Ref. No: 1118 '
    },
    {
      Code: '1119',
      DefaultMsg:
        'The Start date and End date of an Employment period must be in the same calendar year. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1119 '
    },
    {
      Code: '1120',
      DefaultMsg:
        'Enter the Service to include (months). Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1120 '
    },
    {
      Code: '1123',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Service to include (months) field. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1123 '
    },
    {
      Code: '1121',
      DefaultMsg:
        'The date is not in the correct format. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1121 '
    },
    {
      Code: '1122',
      DefaultMsg:
        'You cannot add another row when the last service period ends on Dec.31 or the event date. Please adjust the end date of your last service period before proceeding. OMERS Ref. No: 1122'
    },
    {
      Code: '1124',
      DefaultMsg:
        'The value entered is greater than the value allowed.   Format:  Limited to 6 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1124 '
    },
    {
      Code: '1125',
      DefaultMsg:
        'Invalid Retroactive payment date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1125 '
    },
    {
      Code: '1126',
      DefaultMsg:
        'Please complete all date fields.  Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1126 '
    },
    {
      Code: '1127',
      DefaultMsg:
        'Please ensure the end date of the retro pay period is on or before the year of the service period in which the retro pay is reported. Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1127'
    },
    {
      Code: '1128',
      DefaultMsg:
        'Please ensure that the start date is before the end date. Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1128'
    },
    {
      Code: '1129',
      DefaultMsg:
        'Enter only numeric characters in this field.   OMERS Ref. No: 1129 '
    },
    {
      Code: '1131',
      DefaultMsg:
        'You have entered more than the maximum number of characters in the Contributory Earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1131'
    },
    {
      Code: '1133',
      DefaultMsg:
        'You have exceeded the maximum number of rows allowed.  OMERS Ref. No: 1133 '
    },
    {
      Code: '1134',
      DefaultMsg:
        'Please enter the Service Period end date. Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1134 '
    },
    {
      Code: '1135',
      DefaultMsg:
        'The months of service in a calendar year must be between 0.00 and 12.00 months. OMERS Ref. No: 1135'
    },
    {
      Code: '1138',
      DefaultMsg:
        'The total of Retroactive Payments must be less than or equal to the Contributory Earnings for the Service Period to which they are attached.  OMERS Ref. No: 1138 '
    },
    {
      Code: '1139',
      DefaultMsg:
        'The Start Date is not in the correct format. Enter all dates as yyyymmdd. For example, enter January 2, 2002 as 20020102. OMERS Ref. No: 1139 '
    },
    {
      Code: '1140',
      DefaultMsg:
        'The End Date is not in the correct format. Enter all dates as yyyymmdd. For example, enter January 2, 2002 as 20020102. OMERS Ref. No: 1140 '
    },
    {
      Code: '1141',
      DefaultMsg:
        'The Start Date of the Retroactive Pay Period must be before the year of the Service Period that the Retro Pay is attached to. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1141 '
    },
    {
      Code: '1142',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Pay Periods field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1142 '
    },
    {
      Code: '1143',
      DefaultMsg:
        'The Last date of entered earnings is invalid. Dates must be entered YYYYMMDD   OMERS Ref. No: 1143 '
    },
    {
      Code: '1144',
      DefaultMsg:
        'Enter only alphanumeric characters in the Employee number field. Format: Limited to 10 characters.   OMERS Ref. No: 1144 '
    },
    {
      Code: '1145',
      DefaultMsg:
        'Enter only alphanumeric characters in the Department ID field. Format: Limited to 15 characters.   OMERS Ref. No: 1145 '
    },
    {
      Code: '1146',
      DefaultMsg:
        'Please ensure the start and end date of the service period are within Form 119 reconciliation year.  OMERS Ref. No: 1146 '
    },
    {
      Code: '1147',
      DefaultMsg:
        'Enter the service period start date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1147 '
    },
    {
      Code: '1202',
      DefaultMsg:
        'The highest earnings cannot be calculated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 1202 '
    },
    {
      Code: '1205',
      DefaultMsg: 'No Rows Returned.  OMERS Ref. No: 1205 '
    },
    {
      Code: '1397',
      DefaultMsg:
        'Enter a numeric value for the 3rd period in the Member cost (14 consecutive days or more) field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1397 '
    },
    {
      Code: '1398',
      DefaultMsg:
        'Enter a numeric value for the 3rd period in the Deemed contributory earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1398 '
    },
    {
      Code: '1399',
      DefaultMsg:
        'Enter a numeric value for the 3rd period in the Number of pay periods field. Format: Limited to 2 characters.   OMERS Ref. No: 1399 '
    },
    {
      Code: '1401',
      DefaultMsg:
        'Enter the Date the leave started for the 1st period. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1401 '
    },
    {
      Code: '1402',
      DefaultMsg:
        'Enter the Date the leave started for the 2nd period. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1402 '
    },
    {
      Code: '1403',
      DefaultMsg:
        'Enter the Date the leave ended for the 1st period.  Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1403 '
    },
    {
      Code: '1404',
      DefaultMsg:
        'Enter the Date the leave ended for the 2nd period.  Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1404 '
    },
    {
      Code: '1405',
      DefaultMsg:
        'Enter the Credited service for the 1st period. Format: Limited to 4 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1405 '
    },
    {
      Code: '1406',
      DefaultMsg:
        'Enter the Credited service for the 2nd period. Format: Limited to 4 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1406 '
    },
    {
      Code: '1407',
      DefaultMsg:
        'Enter the Number of pay periods for the 1st period. Format: Limited to 2 characters.  OMERS Ref. No: 1407 '
    },
    {
      Code: '1408',
      DefaultMsg:
        'Enter the Number of pay periods for the 2nd period. Format: Limited to 2 characters.  OMERS Ref. No: 1408 '
    },
    {
      Code: '1409',
      DefaultMsg:
        'Enter the Deemed contributory earnings for the 1st period. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1409 '
    },
    {
      Code: '1410',
      DefaultMsg:
        'Enter the Deemed contributory earnings for the 2nd period. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1410 '
    },
    {
      Code: '1411',
      DefaultMsg:
        'Enter the Member cost (14 consecutive days or more) for the 1st period. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1411 '
    },
    {
      Code: '1412',
      DefaultMsg:
        'Enter the Member cost (14 consecutive days or more) for the 2nd period. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1412 '
    },
    {
      Code: '1413',
      DefaultMsg:
        'Enter the date of the First day of leave. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1413 '
    },
    {
      Code: '1414',
      DefaultMsg:
        'Enter the date of the Last day of leave. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1414 '
    },
    {
      Code: '1416',
      DefaultMsg:
        'Enter only numeric characters in the Deemed contributory earnings (14 consecutive days or less) field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 1416 '
    },
    {
      Code: '1417',
      DefaultMsg:
        'Enter only numeric characters in the Member cost (14 consecutive days or less) field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1417 '
    },
    {
      Code: '1419',
      DefaultMsg:
        'Enter only numeric characters in the Number of months member wants to buy field. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1419 '
    },
    {
      Code: '1420',
      DefaultMsg:
        'Enter only numeric characters in the Member cost for partial period field. Format: Limited to 7 numbers before the decimal and 2 after the decimal.  OMERS Ref. No: 1420 '
    },
    {
      Code: '1421',
      DefaultMsg:
        'Enter only numeric characters in the Employment status % of full time hours field. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal. This number cannot exceed 100 percent.  OMERS Ref. No: 1421 '
    },
    {
      Code: '1422',
      DefaultMsg:
        "Enter the date for the Period each year the member didn't work (From). Format:  Enter the date as mmdd.  For example, enter May 31 as 0531.  OMERS Ref. No: 1422 "
    },
    {
      Code: '1423',
      DefaultMsg:
        "Enter the date for the Period each year the member didn't work (To). Format:  Enter the date as mmdd.  For example, enter May 31 as 0531.  OMERS Ref. No: 1423 "
    },
    {
      Code: '1425',
      DefaultMsg:
        'Enter the Date the leave ended for the 3rd period. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1425 '
    },
    {
      Code: '1428',
      DefaultMsg:
        'Enter the Date the leave started for the 3rd period. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1428 '
    },
    {
      Code: '1432',
      DefaultMsg:
        'Enter only numeric characters in the 1st period of the Credited service field. Format: Limited to 4 numbers before the decimal and 2 numbers.  OMERS Ref. No: 1432 '
    },
    {
      Code: '1433',
      DefaultMsg:
        'Enter only numeric characters in the 2nd period of the Credited service field. Format: Limited to 4 numbers before the decimal and 2 numbers.  OMERS Ref. No: 1433 '
    },
    {
      Code: '1434',
      DefaultMsg:
        'Enter only numeric characters in the 3rd period of the Credited service field. Format: Limited to 4 numbers before the decimal and 2 numbers.  OMERS Ref. No: 1434 '
    },
    {
      Code: '1435',
      DefaultMsg:
        'Enter only numeric characters in the 1st period of the Number of pay periods field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1435 '
    },
    {
      Code: '1436',
      DefaultMsg:
        'Enter only numeric characters in the 2nd period of the Number of pay periods field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1436 '
    },
    {
      Code: '1437',
      DefaultMsg:
        'Enter only numeric characters in the 3rd period of the Number of pay periods field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal. OMERS Ref. No: 1437 '
    },
    {
      Code: '1438',
      DefaultMsg:
        'Enter only numeric characters in the 1st period of the Deemed contributory earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1438 '
    },
    {
      Code: '1439',
      DefaultMsg:
        'Enter only numeric characters in the 2nd period of the Deemed contributory earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1439 '
    },
    {
      Code: '1440',
      DefaultMsg:
        'Enter only numeric characters in the 3rd period of the Deemed contributory earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1440 '
    },
    {
      Code: '1452',
      DefaultMsg:
        'Enter the Date the leave started for the 4tht period. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 1452 '
    },
    {
      Code: '1453',
      DefaultMsg:
        'Enter the Date the leave ended for the 4th period. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 1453 '
    },
    {
      Code: '1454',
      DefaultMsg:
        'Enter only numeric characters in the 4th period of the Credited service field. Format: Limited to 4 numbers before the decimal and 2 numbers.  OMERS Ref. No: 1454'
    },
    {
      Code: '1455',
      DefaultMsg:
        'Enter only numeric characters in the 4th period of the Number of pay periods field. Format: Limited to 2 numbers before the decimal and 2 numbers.  OMERS Ref. No: 1455'
    },
    {
      Code: '1456',
      DefaultMsg:
        'Enter only numeric characters in the 4th period of the Member cost field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1456 '
    },
    {
      Code: '1457',
      DefaultMsg:
        'Enter only numeric characters in the 4th period of the Deemed contributory earnings field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1457 '
    },
    {
      Code: '1441',
      DefaultMsg:
        'Enter only numeric characters in the 1st period of the Member cost field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1441 '
    },
    {
      Code: '1442',
      DefaultMsg:
        'Enter only numeric characters in the 2nd period of the Member cost field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1442 '
    },
    {
      Code: '1443',
      DefaultMsg:
        'Enter only numeric characters in the 3rd period of the Member cost field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1443 '
    },
    {
      Code: '1444',
      DefaultMsg:
        'Enter only numeric characters in the Member cost for total period(s) field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 1444 '
    },
    {
      Code: '1445',
      DefaultMsg:
        'Enter only numeric characters in the Matching employer cost (total) field. Format:  Limited to 9 numbers.  OMERS Ref. No: 1445 '
    },
    {
      Code: '1448',
      DefaultMsg:
        'Enter only numeric characters in the Matching employer cost (partial) field. Format:  Limited to 9 numbers.  OMERS Ref. No: 1448 '
    },
    {
      Code: '1449',
      DefaultMsg:
        'Enter only numeric characters in the Employment status field. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal. This number cannot exceed 100 percent.  OMERS Ref. No: 1449 '
    },
    {
      Code: '1450',
      DefaultMsg:
        "Invalid date format entered in the Period each year the member didn't work (From) field. Format:  Enter the date as mmdd.  For example, enter May 31 as 0531.  OMERS Ref. No: 1450"
    },
    {
      Code: '1451',
      DefaultMsg:
        "Invalid date format entered in the Period each year the member didn't work (To) field. Format:  Enter the date as mmdd.  For example, enter May 31 as 0531.  OMERS Ref. No: 1451"
    },
    {
      Code: '1501',
      DefaultMsg:
        'Please select a Reason from the dropdown list.  OMERS Ref. No: 1501 '
    },
    {
      Code: '1502',
      DefaultMsg:
        'You have selected "Other - explanation provided in notepad" as an Override reason for one of your warnings.  Please enter information in the Notepad field.  OMERS Ref. No: 1502 '
    },
    {
      Code: '1503',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Override Reason Notepad  field. Format: Notepad is limited to 300 alphanumeric characters.  OMERS Ref. No: 1503 '
    },
    {
      Code: '1701',
      DefaultMsg: 'Invalid Name.  OMERS Ref. No: 1701 '
    },
    {
      Code: '1702',
      DefaultMsg: 'No Results Found.  OMERS Ref. No: 1702 '
    },
    {
      Code: '1703',
      DefaultMsg:
        'This is not an Active Group Number in MSS.  OMERS Ref. No: 1703 '
    },
    {
      Code: '1704',
      DefaultMsg: 'Group already exists.  OMERS Ref. No: 1704 '
    },
    {
      Code: '1727',
      DefaultMsg: 'Amalgamation Failed.  OMERS Ref. No: 1727 '
    },
    {
      Code: '1751',
      DefaultMsg: 'No First Name.  OMERS Ref. No: 1751 '
    },
    {
      Code: '1753',
      DefaultMsg: 'No SIN.  OMERS Ref. No: 1753 '
    },
    {
      Code: '1752',
      DefaultMsg: 'No Last Name.  OMERS Ref. No: 1752 '
    },
    {
      Code: '1754',
      DefaultMsg: 'No Phone Number.  OMERS Ref. No: 1754 '
    },
    {
      Code: '1755',
      DefaultMsg: 'Invalid Phone Number.  OMERS Ref. No: 1755 '
    },
    {
      Code: '1756',
      DefaultMsg: 'Invalid Fax Number.  OMERS Ref. No: 1756 '
    },
    {
      Code: '1779',
      DefaultMsg: 'No Phone.  OMERS Ref. No: 1779 '
    },
    {
      Code: '1780',
      DefaultMsg: 'Invalid Phone.  OMERS Ref. No: 1780 '
    },
    {
      Code: '1781',
      DefaultMsg: 'Invalid Fax.  OMERS Ref. No: 1781 '
    },
    {
      Code: '1801',
      DefaultMsg: 'No Administrator List.  OMERS Ref. No: 1801 '
    },
    {
      Code: '1828',
      DefaultMsg: 'Invalid password.  OMERS Ref. No: 1828 '
    },
    {
      Code: '1829',
      DefaultMsg: 'Empty Password.  OMERS Ref. No: 1829 '
    },
    {
      Code: '1830',
      DefaultMsg: 'Password cannot contain common words.  OMERS Ref. No: 1830 '
    },
    {
      Code: '1831',
      DefaultMsg: 'Password too short.  OMERS Ref. No: 1831 '
    },
    {
      Code: '1832',
      DefaultMsg: 'Password too long.  OMERS Ref. No: 1832 '
    },
    {
      Code: '1833',
      DefaultMsg: 'Password has repeated letters.  OMERS Ref. No: 1833 '
    },
    {
      Code: '1834',
      DefaultMsg:
        'Password and confirm password mismatch.  OMERS Ref. No: 1834 '
    },
    {
      Code: '1851',
      DefaultMsg: 'Please Select a month.  OMERS Ref. No: 1851 '
    },
    {
      Code: '1852',
      DefaultMsg: 'Please Select a year.  OMERS Ref. No: 1852 '
    },
    {
      Code: '3201',
      DefaultMsg:
        'You must enter at least one Milestone or a Retirement Date.  OMERS Ref. No: 3201'
    },
    {
      Code: '3202',
      DefaultMsg:
        'At least one member must be selected to create a batch request.  OMERS Ref. No: 3202'
    },
    {
      Code: '3203',
      DefaultMsg:
        "Primary Contact's First and Last Names are required fields.  OMERS Ref. No: 3203  "
    },
    {
      Code: '3204',
      DefaultMsg:
        "At least one of Primary Contact's: Telephone, Fax or E-mail, is mandatory.  OMERS Ref. No: 3204  "
    },
    {
      Code: '32509',
      DefaultMsg:
        'The end date of the leave is more than 60 days in the future.  A cost cannot be calculated for this leave. OMERS Ref. No: 32509 '
    },
    {
      Code: '3301',
      DefaultMsg:
        'The Retirement Date entered is invalid.  OMERS Ref. No: 3301 '
    },
    {
      Code: '3302',
      DefaultMsg:
        'If you enter the Period Not Worked From, then Period Not Worked To must also be entered.  OMERS Ref. No: 3302 '
    },
    {
      Code: '3303',
      DefaultMsg:
        'OTCFT Period Not Worked From Date is greater than OTCFT Period Not Worked To Date.  OMERS Ref. No: 3303 '
    },
    {
      Code: '3304',
      DefaultMsg:
        'The Retirement Date must be after the current date.  OMERS Ref. No: 3304 '
    },
    {
      Code: '3305',
      DefaultMsg:
        'If the CPP disability pension radio button is "Yes" then only one Milestone or one Retirement Date is allowed.  OMERS Ref. No: 3305 '
    },
    {
      Code: '3306',
      DefaultMsg:
        'An invalid percent has been entered. The percentage cannot be greater than 100%.  OMERS Ref. No: 3306 '
    },
    {
      Code: '3307',
      DefaultMsg:
        'The projection year on the Assumptions screen must be current year or later.  Please change the year you have entered.  OMERS Ref. No: 3307 '
    },
    {
      Code: '3308',
      DefaultMsg:
        'Invalid OTCFT Period Not Worked From/To Dates.  OMERS Ref. No: 3308 '
    },
    {
      Code: '3309',
      DefaultMsg:
        'If you enter the Period Not Worked To, then Period Not Worked From must also be entered.  OMERS Ref. No: 3309 '
    },
    {
      Code: '3310',
      DefaultMsg:
        'The OTCFT Period Not Worked From and To Dates must fall within the same year.  OMERS Ref. No: 3310 '
    },
    {
      Code: '3311',
      DefaultMsg:
        'The field {0}, is not correct. An invalid percent has been entered. Percentage fields must be less than or equal to 100. OMERS Ref. No: 3311 '
    },
    {
      Code: '3312',
      DefaultMsg:
        'The field {0}, is not correct. An invalid percent has been entered. Percentage fields must be between 1.00 and 100.00. OMERS Ref. No: 3312 '
    },
    {
      Code: '3403',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Associated service field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 3403 '
    },
    {
      Code: '3404',
      DefaultMsg:
        'An invalid amount has been entered in the Associated service field. Format: Limited to 2 numbers before the decimal and 2 numbers after the decimal.   OMERS Ref. No: 3404'
    },
    {
      Code: '3407',
      DefaultMsg:
        'The Base Services cannot be updated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3407'
    },
    {
      Code: '3408',
      DefaultMsg:
        'The Base Services cannot be deleted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3408'
    },
    {
      Code: '3409',
      DefaultMsg:
        'The Base Services cannot be inserted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3409'
    },
    {
      Code: '3410',
      DefaultMsg:
        'The Retroactive payments cannot be updated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3410'
    },
    {
      Code: '3411',
      DefaultMsg:
        'The Retroactive payments cannot be deleted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3411'
    },
    {
      Code: '3412',
      DefaultMsg:
        'The Retroactive payments cannot be inserted. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 3412'
    },
    {
      Code: '39045',
      DefaultMsg:
        'The reconciliation year entered must have an in progress employer-level Form 119 business process for that reconciliation year in MSS.  OMERS Ref. No: 39045 '
    },
    {
      Code: '39046',
      DefaultMsg:
        'The member must have an employment period in that reconciliation year.  OMERS Ref. No: 39046 '
    },
    {
      Code: '39047',
      DefaultMsg:
        'There is an e-Form 119 request in progress for this membership for the same reconciliation year.  OMERS Ref. No: 39047 '
    },
    {
      Code: '39048',
      DefaultMsg:
        'OMERS currently has a Form 119 transaction in progress for the same reconciliation year. Please contact OMERS Employer Experience.  OMERS Ref. No: 39048 '
    },
    {
      Code: '4000',
      DefaultMsg: 'The Birth date cannot be in the future.  OMERS Ref. No: 4000'
    },
    {
      Code: '4002',
      DefaultMsg:
        'The Employee # cannot be more than 10 characters.  OMERS Ref. No: 4002 '
    },
    {
      Code: '4003',
      DefaultMsg:
        'The Birth date is invalid.  Dates must be entered as YYYYMMDD.  OMERS Ref. No: 4003'
    },
    {
      Code: '4005',
      DefaultMsg:
        'Temp error message.  check mandatory attributes.  OMERS Ref. No: 4005 '
    },
    {
      Code: '4006',
      DefaultMsg:
        'The Employee # field is limited to 10 alpha/numeric characters.  OMERS Ref. No: 4006 '
    },
    {
      Code: '4008',
      DefaultMsg: 'Please enter birth date.  OMERS Ref. No: 4008 '
    },
    {
      Code: '4009',
      DefaultMsg:
        'Please enter the SIN or employee number.  OMERS Ref. No: 4009 '
    },
    {
      Code: '4101',
      DefaultMsg:
        'The Date leave started is invalid. The date must be entered as YYYYMMDD.  OMERS Ref. No: 4101 '
    },
    {
      Code: '4102',
      DefaultMsg:
        'The Date leave ended is invalid. The date must be entered YYYYMMDD.  OMERS Ref. No: 4102 '
    },
    {
      Code: '4103',
      DefaultMsg:
        'The Date leave started must be before the Date leave ended. The date must be entered as YYYYMMDD. OMERS Ref. No: 4103 '
    },
    {
      Code: '4104',
      DefaultMsg:
        'The breaks in service must fall between the Hire Date and Enrolment Date.  OMERS Ref. No: 4104 '
    },
    {
      Code: '4111',
      DefaultMsg:
        'The Hire date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 4111'
    },
    {
      Code: '4112',
      DefaultMsg:
        'The number of leaves that can be reported in a request cannot exceed 8. OMERS Ref. No: 4112'
    },
    {
      Code: '4113',
      DefaultMsg:
        'The breaks in the eligible service must fall between the Hire Date and Enrolment Date  OMERS Ref. No: 4113 '
    },
    {
      Code: '4114',
      DefaultMsg:
        'The Hire date must be before Enrolment date. OMERS Ref. No: 4114'
    },
    {
      Code: '4115',
      DefaultMsg:
        'The Hire and Enrolment dates must be entered before eligible service can be specified.  OMERS Ref. No: 4115 '
    },
    {
      Code: '4116',
      DefaultMsg:
        'The Normal retirement age must be 65 if the Occupation is Other or Councillors.'
    },
    {
      Code: '4117',
      DefaultMsg:
        'The annualized salary must be greater than or equal to $29,120.  OMERS Ref. No: 4117'
    },
    {
      Code: '4118',
      DefaultMsg:
        'Please specify weather the enrolment is a result of restructuring.  OMERS Ref. No: 4118'
    },
    {
      Code: '4119',
      DefaultMsg:
        'Please specify weather the employee participated in a pension plan.  OMERS Ref. No: 4119'
    },
    {
      Code: '4120',
      DefaultMsg:
        'Please specify the name of prior pension plan.  OMERS Ref. No: 4120'
    },
    {
      Code: '4121',
      DefaultMsg:
        'Please enter the enrolment date with prior pension plan.  OMERS Ref. No: 4121'
    },
    {
      Code: '4122',
      DefaultMsg:
        'The enrolment date with prior pension plan is invalid. Dates must be entered as YYYYMMDD.'
    },
    {
      Code: '4123',
      DefaultMsg: 'Please enter the normal retirement age.  OMERS Ref. No: 4123'
    },
    {
      Code: '4124',
      DefaultMsg: 'Please select the occupation.  OMERS Ref. No: 4124'
    },
    {
      Code: '4125',
      DefaultMsg: 'Please enter the hire date.  OMERS Ref. No: 4125'
    },
    {
      Code: '4126',
      DefaultMsg: 'Please enter the enrolment date.  OMERS Ref. No: 4126'
    },
    {
      Code: '4127',
      DefaultMsg:
        'Please specify weather the hire and enrolment dates are the same.  OMERS Ref. No: 4127'
    },
    {
      Code: '4128',
      DefaultMsg: 'Please enter the annualized salary.  OMERS Ref. No: 4128'
    },
    {
      Code: '4129',
      DefaultMsg: 'Please select the enrolment reason.  OMERS Ref. No: 4129'
    },
    {
      Code: '4130',
      DefaultMsg: 'Please enter the hourly rate.  OMERS Ref. No: 4130'
    },
    {
      Code: '4131',
      DefaultMsg:
        'The hourly rate must be greater than or equal to 14.00.  OMERS Ref. No: 4131'
    },
    {
      Code: '4132',
      DefaultMsg: 'Please select the affiliation.  OMERS Ref. No: 4132'
    },
    {
      Code: '4133',
      DefaultMsg:
        'You have entered more than the maximum number of characters in the Annualized Salary field. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4133'
    },
    {
      Code: '4134',
      DefaultMsg:
        'You have entered more than the maximum number of characters in the Hourly Rate field. Format: Limited to 3 numbers before the decimal and 2 numbers after the decimal. OMERS Ref. No: 4134'
    },
    {
      Code: '100078',
      DefaultMsg:
        'The enrolment date and hire date must be the same when the enrolment is a result of a divestment.  OMERS Ref. No: 100078'
    },
    {
      Code: '100079',
      DefaultMsg:
        'The Enrolment date with prior pension plan must be prior to the Hire date.  OMERS Ref. No: 100079'
    },
    {
      Code: '4105',
      DefaultMsg:
        'The Enrolment date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 4105'
    },
    {
      Code: '4106',
      DefaultMsg:
        'The CFT date is invalid.  Dates must be entered YYYYMMDD.  OMERS Ref. No: 4106 '
    },
    {
      Code: '4107',
      DefaultMsg:
        'The maximum number of characters allowed in the Reason for difference field is 100.  OMERS Ref. No: 4107 '
    },
    {
      Code: '4108',
      DefaultMsg:
        "The maximum number of characters allowed in Employee's affiliation name field is 72.  OMERS Ref. No: 4108 "
    },
    {
      Code: '4109',
      DefaultMsg:
        'You have exceeded the maximum number of characters for the Reason for difference field. Reason for difference is limited to 100 characters.  OMERS Ref. No: 4109 '
    },
    {
      Code: '4110',
      DefaultMsg:
        'The Hire and Enrolment dates are different and you have indicated that the period between the two dates was not continuous.  Please specify the Service breaks.  OMERS Ref. No: 4110 '
    },
    {
      Code: '4200',
      DefaultMsg:
        'Enter only alphabetic characters in the plan name field. Format: The plan name is limited to 40 characters.   OMERS Ref. No: 4200  '
    },
    {
      Code: '4201',
      DefaultMsg:
        'Enter only alphabetic characters in the termination date reason field. Format: The termination date reason is limited to 100 characters.   OMERS Ref. No: 4201  '
    },
    {
      Code: '4202',
      DefaultMsg:
        'The Termination date with previous employer is invalid.  It must be entered YYYYMMDD. Format: OMERS Ref. No: 4202  '
    },
    {
      Code: '4301',
      DefaultMsg:
        'The maximum number of characters allowed in the Institution name field is 50. OMERS Ref. No: 4301 '
    },
    {
      Code: '4302',
      DefaultMsg:
        'The name of the Institution in the Beneficiary tab is mandatory. OMERS Ref. No: 4302'
    },
    {
      Code: '4303',
      DefaultMsg:
        "The beneficiary's percentage in the Beneficiary tab is mandatory. OMERS Ref. No: 4303"
    },
    {
      Code: '4304',
      DefaultMsg:
        'Last name is mandatory and must have at least 2 characters. OMERS Ref. No: 4304'
    },
    {
      Code: '4305',
      DefaultMsg:
        'First name is mandatory and must have at least 2 characters. OMERS Ref. No: 4305'
    },
    {
      Code: '4306',
      DefaultMsg:
        'A Marital Status selection is mandatory, please complete the Retirement Date Spouse section. OMERS Ref. No 4306'
    },
    {
      Code: '4307',
      DefaultMsg: 'Beneficiary is mandatory.  OMERS Ref. No: 4307'
    },
    {
      Code: '4308',
      DefaultMsg:
        'Enter only alphabetic characters in the Institution name field. Format: The institution name is limited to 50 characters.   OMERS Ref. No: 4308 '
    },
    {
      Code: '4400',
      DefaultMsg:
        'The number selected for the Pay Periods in Leave Year is invalid.  Format:  Limited to 2 numbers.  OMERS Ref. No: 4400 '
    },
    {
      Code: '4401',
      DefaultMsg:
        'The amount entered in the field  "The contributory earnings entered in the typical pay period prior to leave" is invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4401 '
    },
    {
      Code: '4402',
      DefaultMsg:
        'The amount entered in the Contributory earnings field is invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4402 '
    },
    {
      Code: '4403',
      DefaultMsg:
        'The amount entered in the Paid in the following year (carry-forward) field is invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4403 '
    },
    {
      Code: '4406',
      DefaultMsg:
        'You have entered an invalid number of pay periods for the leave year. Please verify and revise the number. OMERS Ref. No: 4406 '
    },
    {
      Code: '4407',
      DefaultMsg:
        'Scattered days leaves cannot exceed 20 working days. The credited service reported for this period must be greater than zero and less than 1.00 month. OMERS Ref. No: 4407 '
    },
    {
      Code: '4408',
      DefaultMsg:
        'Carry-forward earnings can only be entered when the end date is after October 31.   OMERS Ref. No: 4408'
    },
    {
      Code: '4409',
      DefaultMsg:
        'The field "How many pay periods in a leave year?" is mandatory.   OMERS Ref. No: 4409 '
    },
    {
      Code: '4410',
      DefaultMsg:
        'The field "What are the contributory earnings in a typical pay period prior to leave?" is mandatory. OMERS Ref. No:  4410 '
    },
    {
      Code: '4411',
      DefaultMsg:
        'The Credited service (months) field is mandatory.   OMERS Ref. No:  4411 '
    },
    {
      Code: '4412',
      DefaultMsg:
        'The Contributory earnings field is mandatory.   OMERS Ref. No.:  4412 '
    },
    {
      Code: '70823',
      DefaultMsg:
        'The Primary rebound RPP contributions are invalid. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 70823 '
    },
    {
      Code: '70824',
      DefaultMsg:
        'The Primary rebound RCA contributions are invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 70824 '
    },
    {
      Code: '70825',
      DefaultMsg:
        'The Catch Up RPP contribution is invalid. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 70825 '
    },
    {
      Code: '70826',
      DefaultMsg:
        'The Supplemental RPP contributions are invalid. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 70826 '
    },
    {
      Code: '4499',
      DefaultMsg:
        'Please select a Retro type from the dropdown list.  OMERS Ref. No: 4499 '
    },
    {
      Code: '4500',
      DefaultMsg:
        'The RPP contributions are invalid. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4500 '
    },
    {
      Code: '4500',
      DefaultMsg:
        'The RPP contributions are invalid. Format: Limited to 5 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4500 '
    },
    {
      Code: '4501',
      DefaultMsg:
        'The RCA contributions are invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4501 '
    },
    {
      Code: '4502',
      DefaultMsg:
        'The Election date is invalid. The date must be entered as YYYYMMDD.  OMERS Ref. No: 4502  '
    },
    {
      Code: '4503',
      DefaultMsg:
        'The election date must be on or after the leave start date.   OMERS Ref. No: 4503 '
    },
    {
      Code: '4506',
      DefaultMsg:
        'The Member cost RPP contributions field is mandatory.   OMERS Ref. No:  4506 '
    },
    {
      Code: '4507',
      DefaultMsg: 'The Election date is mandatory.    OMERS Ref. No: 4507 '
    },
    {
      Code: '4508',
      DefaultMsg:
        'The field "Is the member purchasing the leave?" is mandatory. OMERS Ref. No: 4508 '
    },
    {
      Code: '4509',
      DefaultMsg:
        'The field "Do you want to share the cost with the member" is mandatory.   OMERS Ref. No:  4509 '
    },
    {
      Code: '4510',
      DefaultMsg:
        'The purchasable type for this leave cannot be change.   OMERS Ref. No:  4510 '
    },
    {
      Code: '4600',
      DefaultMsg:
        'The amount entered in the field "Dollar amount member will pay for the leave" is  invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4600 '
    },
    {
      Code: '4601',
      DefaultMsg:
        'The amount entered in the Cheque field is  invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4601 '
    },
    {
      Code: '4602',
      DefaultMsg:
        'The amount entered in the RRSP field is invalid.  Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4602 '
    },
    {
      Code: '4603',
      DefaultMsg:
        'The Form 119 PA amount is  invalid. Format: Limited to 7 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 4603 '
    },
    {
      Code: '4604',
      DefaultMsg:
        'The amount entered in the field  "Dollar amount member will pay for the leave?" is greater than the member\'s cost of the service available to purchase. OMERS Ref. No: 4604  '
    },
    {
      Code: '4605',
      DefaultMsg:
        "The total payment from a Cheque and/or RRSP must be equal to the member's cost of the service available to purchase.  OMERS Ref. No: 4605 "
    },
    {
      Code: '4606',
      DefaultMsg:
        'The purchase information we have on file has changed. Please delete this request and re-submit a new  request. OMERS Ref. No: 4606 '
    },
    {
      Code: '4607',
      DefaultMsg:
        'The Revised PA amount is  invalid. Format: Limited to 5 numbers with no decimal. OMERS Ref. No: 4607  '
    },
    {
      Code: '4608',
      DefaultMsg:
        'The field "Dollar amount the member will pay for the leave"is mandatory.   OMERS Ref. No: 4608 '
    },
    {
      Code: '4611',
      DefaultMsg:
        'The field "Are you reporting a PA for this period?" is mandatory.   OMERS Ref. No: 4611 '
    },
    {
      Code: '4609',
      DefaultMsg:
        'The Cheque or  RRSP field is mandatory.   OMERS Ref. No: 4609 '
    },
    {
      Code: '10005',
      DefaultMsg:
        'The :1: field in the :2: tab is mandatory. OMERS Ref. No: 10005 '
    },
    {
      Code: '4610',
      DefaultMsg:
        'The field "Is the member buying all of the available leave?" is mandatory.   OMERS Ref. No: 4610 '
    },
    {
      Code: '4612',
      DefaultMsg:
        'The election date must be the current date or earlier.   OMERS Ref. No: 4612 '
    },
    {
      Code: '4620',
      DefaultMsg:
        'The adjusting amount must be less than the previously elected amount. OMERS Ref. No: 4620  '
    },
    {
      Code: '4800',
      DefaultMsg:
        'The Year of Leave is invalid. The Year must be entered as YYYY.  OMERS Ref. No: 4800 '
    },
    {
      Code: '4801',
      DefaultMsg:
        'The field "Date leave started" is mandatory. The date must be entered as YYYYMMDD.   OMERS Ref. No: 4801'
    },
    {
      Code: '4802',
      DefaultMsg:
        'The field "Date leave ended" is mandatory. The date must be entered as YYYYMMDD.  OMERS Ref. No: 4802'
    },
    {
      Code: '4803',
      DefaultMsg:
        'The Year of leave is mandatory. The Year must be entered as YYYY. OMERS Ref. No: 4803'
    },
    {
      Code: '4804',
      DefaultMsg: 'The field "Type of leave" is mandatory.  OMERS Ref. No: 4804'
    },
    {
      Code: '4805',
      DefaultMsg:
        "The Date leave started must be on or before today's date. The date must be entered as YYYYMMDD.  OMERS Ref. No: 4805"
    },
    {
      Code: '4808',
      DefaultMsg:
        'The field "Is this a period of scattered days?" is mandatory. OMERS Ref. No: 4808'
    },
    {
      Code: '4809',
      DefaultMsg:
        'The start date of the leave that you are reporting must not be more than three years in the past. OMERS Ref. No: 4809'
    },
    {
      Code: '4810',
      DefaultMsg:
        'The year of scattered days leave must not be in the future and no more than three years in the past. The Year must be entered as YYYY. OMERS Ref. No: 4810'
    },
    {
      Code: '4811',
      DefaultMsg:
        'You cannot change the year of a leave period.  OMERS Ref. No: 4811'
    },
    {
      Code: '4812',
      DefaultMsg:
        'You cannot change a regular leave period to a scattered days leave period. Please delete this leave and add a scattered days leave period. OMERS Ref. No: 4812'
    },
    {
      Code: '4813',
      DefaultMsg:
        'You cannot change a scattered days leave period to a regular leave period. Please delete this leave and add a regular leave period. OMERS Ref. No: 4813'
    },
    {
      Code: '4814',
      DefaultMsg:
        'The field "Is this an open ended leave" is mandatory.  OMERS Ref. No: 4814'
    },
    {
      Code: '4815',
      DefaultMsg:
        'You cannot change the year of a scattered days.  OMERS Ref. No: 4815'
    },
    {
      Code: '4816',
      DefaultMsg:
        'Changing a leave period may cause other data to become invalid. Please review all the information you previously entered in all tabs to ensure that changes are not required. OMERS Ref. No: 4816'
    },
    {
      Code: '4900',
      DefaultMsg: 'The import file name is mandatory. OMERS Ref. No: 4900.'
    },
    {
      Code: '4901',
      DefaultMsg:
        "Your import file must have the file extension of '.txt' OMERS Ref. No: 4901."
    },
    {
      Code: '4902',
      DefaultMsg:
        'The file transferred to OMERS does not contain the correct information. Please check the contents of your import file. OMERS Ref. No: 4902.'
    },
    {
      Code: '4903',
      DefaultMsg: 'The import has been cancelled. OMERS Ref. No: 4903.'
    },
    {
      Code: '4904',
      DefaultMsg: 'Your import has failed. OMERS Ref. No: 4904.'
    },
    {
      Code: '4905',
      DefaultMsg: 'The Import has been successful. OMERS Ref. No: 4905.'
    },
    {
      Code: '4906',
      DefaultMsg:
        'The reconciliation year is mandatory for the selected import type. OMERS Ref. No: 4906.'
    },
    {
      Code: '4907',
      DefaultMsg:
        "The Member's Affiliation Code - Employer is mandatory.  OMERS Ref. No: 4907  "
    },
    {
      Code: '4908',
      DefaultMsg:
        "The Member's Affiliation - OMERS is mandatory.  OMERS Ref. No: 4908 "
    },
    {
      Code: '5500',
      DefaultMsg: 'The Primary contact is mandatory. OMERS Ref. No: 5500'
    },
    {
      Code: '5700',
      DefaultMsg: 'The Contact is mandatory. OMERS Ref. No: 5700'
    },
    {
      Code: '5701',
      DefaultMsg: 'The Category is mandatory. OMERS Ref. No: 5701'
    },
    {
      Code: '5702',
      DefaultMsg: 'The Subject is mandatory. OMERS Ref. No: 5702'
    },
    {
      Code: '5703',
      DefaultMsg: 'The Message is mandatory. OMERS Ref. No: 5703'
    },
    {
      Code: '5704',
      DefaultMsg:
        'Unable to post the reply since the maximum number of {0}, replies has been reached. OMERS Ref. No: 5704'
    },
    {
      Code: '5801',
      DefaultMsg: 'Extract type is mandatory. OMERS Ref. No: 5801'
    },
    {
      Code: '5802',
      DefaultMsg: 'The {0}, is mandatory. OMERS Ref. No: 5802'
    },
    {
      Code: '1035',
      DefaultMsg:
        'The action failed to complete successfully. Please call OMERS Employer Experience at 416.369.2444 to update your contact details.'
    },
    {
      Code: '12315',
      DefaultMsg:
        'The file you are trying to import is not in the correct format. Please check your file, or refer to Section 8.2 in the OMERS Employer Administration Manual for the ASCII import file specification. OMERS Ref. No: 12315.'
    },
    {
      Code: '12316',
      DefaultMsg:
        'A similar file is currently being imported. Please wait until this import is complete before processing another import file. OMERS Ref. No: 12316.'
    },
    {
      Code: '12317',
      DefaultMsg:
        'There is a completed import file with errors that have not been reviewed. Please review these errors before you process another import file. OMERS Ref. No: 12317.'
    },
    {
      Code: '12318',
      DefaultMsg:
        "This import file exceeds the maximum size allowed. Please check your file, or see <a href=\"javascript:onClick=openHelp('help/ascii_info.htm','600','400')\" class=\"search\">HELP</a> for the ASCII import file specifications.  OMERS Ref. No: 12318."
    },
    {
      Code: '12400',
      DefaultMsg:
        'Due to the large amount of activity on our system right now, your import cannot be processed at this time. Please try importing again in 10 minutes. OMERS Ref. No: 12400.'
    },
    {
      Code: '12402',
      DefaultMsg:
        'Due to the large amount of activity on our system right now, your batch print cannot be processed at this time. Please try your print again in 10 minutes. OMERS Ref. No: 12402.'
    },
    {
      Code: '15011',
      DefaultMsg: 'Invalid input parameters.  OMERS Ref. No: 15011 '
    },
    {
      Code: '15125',
      DefaultMsg:
        'Attachment file cannot be saved. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 15125 '
    },
    {
      Code: '15009',
      DefaultMsg: 'Required data not found.  OMERS Ref. No: 15009 '
    },
    {
      Code: '37027',
      DefaultMsg: 'un-captured exception   OMERS Ref. No: 37027 '
    },
    {
      Code: '315011',
      DefaultMsg: 'Required data are not entered. OMERS Ref. No: 315011 '
    },
    {
      Code: '115011',
      DefaultMsg: 'Required data is not entered.  OMERS Ref. No: 115011 '
    },
    {
      Code: '215011',
      DefaultMsg: 'Required data are not entered.  OMERS Ref. No: 215011 '
    },
    {
      Code: '215009',
      DefaultMsg:
        'The benefit request cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 215009 '
    },
    {
      Code: '237027',
      DefaultMsg:
        'The benefit request cannot be initialized. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 237027 '
    },
    {
      Code: '415011',
      DefaultMsg:
        'Unable to calculate Highest earnings.  Required data has not been entered.  OMERS Ref. No: 415011 '
    },
    {
      Code: '237027',
      DefaultMsg: 'Required data not found.  OMERS Ref. No: 237027 '
    },
    {
      Code: '15180',
      DefaultMsg:
        'The information in the membership record is incomplete. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 15180 '
    },
    {
      Code: '11245',
      DefaultMsg:
        " Based on the member's age and NRA, the reason for requesting a benefit is incorrect.  OMERS Ref. No: 11245 "
    },
    {
      Code: '11356',
      DefaultMsg:
        'A benefit request with the same information has already been submitted for this member. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11356 '
    },
    {
      Code: '11630',
      DefaultMsg:
        'Beneficiary List must contain only one spouse.  OMERS Ref. No: 11630'
    },
    {
      Code: '11778',
      DefaultMsg:
        "OMERS' records indicate that a benefit request (Form 143) has already been submitted for this member.  If you have any questions, please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11778"
    },
    {
      Code: '11924',
      DefaultMsg:
        "OMERS' records indicate that a member event has already been submitted for this member.  Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11924"
    },
    {
      Code: '11925',
      DefaultMsg:
        "The benefit request cannot be submitted because the member's record is undergoing changes. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11925"
    },
    {
      Code: '11932',
      DefaultMsg:
        'The end of employment cannot be in a year after the current one when the carryover indicator is set.  OMERS Ref. No: 11932 '
    },
    {
      Code: '11934',
      DefaultMsg:
        'Retirements: the Date employment ended and Last date of contributions cannot be more than 60 days in the future.  Terminations: the Date employment ended and Last date of contributions cannot be greater than the current month. Deaths: the Date of Death cannot be in the future.  OMERS Ref. No: 11934 '
    },
    {
      Code: '11935',
      DefaultMsg:
        'The Date employment ended (or Date of Death) and Last date of contributions must be during the most recent period of membership. Format:  Enter the date as yyyymmdd.  For example, enter May 31, 2002 as 20020531.  OMERS Ref. No: 11935 '
    },
    {
      Code: '12227',
      DefaultMsg:
        'The leave period you have reported overlaps with another leave period. OMERS Ref. No: 12227 '
    },
    {
      Code: '13106',
      DefaultMsg:
        'Earliest Date and Latest Date must be within the same calendar year.  OMERS Ref. No: 13106 '
    },
    {
      Code: '13104',
      DefaultMsg:
        'Pension Adjustment Cannot be Calculated For Years Before 1990.  OMERS Ref. No: 13104 '
    },
    {
      Code: '13101',
      DefaultMsg:
        'Please ensure the credited service is 12.00 or less.  OMERS Ref. No: 13101 '
    },
    {
      Code: '12234',
      DefaultMsg:
        'This member does not have any outstanding purchases, or there is an election in progress. To report a new leave period, please submit a Form 165a request.  OMERS Ref. No: 12234 '
    },
    {
      Code: '12235',
      DefaultMsg:
        'The purchase information with OMERS changed while this request was worked on. Please delete this request and start over again.  OMERS Ref. No: 12235 '
    },
    {
      Code: '13123',
      DefaultMsg:
        'The request cannot be processed because the member has achieved the normal retirement age.  OMERS Ref. No: 13123 '
    },
    {
      Code: '11936',
      DefaultMsg:
        'A request for an OMERS plan benefit cannot be initiated for this member. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11936 '
    },
    {
      Code: '11749',
      DefaultMsg:
        'The Date leave started must be after the Plan Initiation Date. OMERS Ref. No: 11749 '
    },
    {
      Code: '11081',
      DefaultMsg:
        'Start Date of the Pregnancy/Parental Leave must be on or after December 20, 1990. OMERS Ref. No: 11081 '
    },
    {
      Code: '11082',
      DefaultMsg:
        'Service Purchase Start/End Date must be within a valid Employment Period. OMERS Ref. No: 11082 '
    },
    {
      Code: '12253',
      DefaultMsg:
        'The Pregnancy/Parental Leave exceeds the leave period maximum. Please verify and revise the leave period.  OMERS Ref. No: 12253 '
    },
    {
      Code: '12262',
      DefaultMsg:
        'Payment schedules contribution are due in Form month  "month, yyyy". Please create a new reuqest for Form month "month, yyyy" to process outstanding payment schedule. OMERS Ref. No: 12262'
    },
    {
      Code: '11760',
      DefaultMsg:
        'Enter the Service Purchase Start Date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 11760 '
    },
    {
      Code: '11761',
      DefaultMsg:
        'Enter the Service Purchase End Date. Format: Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531.   OMERS Ref. No: 11761 '
    },
    {
      Code: '11762',
      DefaultMsg:
        'The Start and End Dates of the Purchase entered must be in the same calendar year.  OMERS Ref. No: 11762 '
    },
    {
      Code: '11987',
      DefaultMsg:
        'OMERS records show that one or more of the dates entered fall into a previous disability waiver period.  Please correct the information or contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 11987 '
    },
    {
      Code: '3501',
      DefaultMsg:
        'Cannot add any members to batch since maximum count of 100 exceeded. OMERS Ref. No: 3501'
    },
    {
      Code: '3502',
      DefaultMsg:
        'Update failed. Please try again or contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 3502 '
    },
    {
      Code: '3503',
      DefaultMsg:
        'Delete failed. Please try again or contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 3503 '
    },
    {
      Code: '3504',
      DefaultMsg:
        'Insert failed. Please try again or contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.   OMERS Ref. No: 3504'
    },
    {
      Code: '3505',
      DefaultMsg:
        'You have clicked the Save button without entering any information.  You must either enter the Short Description and Comment and then click Save or click the return button to exit this screen. OMERS Ref. No: 3505 '
    },
    {
      Code: '3506',
      DefaultMsg: 'Text Length Exceeded. OMERS Ref. No: 3506 '
    },
    {
      Code: '3507',
      DefaultMsg:
        'The Short description you have entered already exists. OMERS Ref. No: 3507 '
    },
    {
      Code: '3508',
      DefaultMsg:
        'Comment exists in a Request that is not yet submitted. OMERS Ref. No: 3508 '
    },
    {
      Code: '12163',
      DefaultMsg:
        'This membership has an inactive status. OMERS Ref. No: 12163 '
    },
    {
      Code: '12164',
      DefaultMsg:
        'This member is currently on a disability waiver of premium. The waiver must be ended before a benefit can be processed. To end the waiver, please complete <a href="http://www.omers.com/emp-forms.html" target="_new">Form 158</a>.  OMERS Ref. No: 12164'
    },
    {
      Code: '12165',
      DefaultMsg:
        'Another request for this membership is in progress and has been saved in e-access. OMERS Ref. No: 12165 '
    },
    {
      Code: '12166',
      DefaultMsg:
        'There is another submitted request already in progress for this membership. OMERS Ref. No: 12166 '
    },
    {
      Code: '12167',
      DefaultMsg:
        'There is a member event in progress for this membership.  OMERS Ref. No: 12167 '
    },
    {
      Code: '12195',
      DefaultMsg:
        'Form 102 is already in progress for an employee with the same SIN and/or Employee number.  OMERS Ref. No: 12195 '
    },
    {
      Code: '12196',
      DefaultMsg:
        'An e-Form 102 request with the same SIN and/or Employee number is in process.  OMERS Ref. No: 12196 '
    },
    {
      Code: '12200',
      DefaultMsg:
        'This is a member event after ending a waiver and going back to work. Event date cannot be the same as the waiver end date.  OMERS Ref. No: 12200 '
    },
    {
      Code: '12232',
      DefaultMsg:
        'An employment period that is more than the scattered days leave period, must exist with the same employment status and NRA in the year in which you are reporting the scattered days leave. Please submit a paper form.  OMERS Ref. No: 12232 '
    },
    {
      Code: '12246',
      DefaultMsg:
        'The scattered days radio button cannot be selected for members whose NRA or employment status was changed during the year. De-select the scattered days radio button and report each scattered days period separately with the exact start and end dates. OMERS Ref. No: 12246 '
    },
    {
      Code: '12257',
      DefaultMsg:
        'One of the leave periods available for  purchase by this member spans more than 4 years. Please submit a paper Form 165 or contact OMERS OMERS Employer Experience at 1-833-884-0389 or 416-350-6750 for additional assistance. OMERS Ref. No: 12257'
    },
    {
      Code: '32504',
      DefaultMsg:
        'We are unable to process a request for the dates you have indicated. If you need help, please contact OMERS OMERS Employer Experience at 1-833-884-0389 or 416-350-6750. (32504) '
    },
    {
      Code: '38007',
      DefaultMsg:
        'We cannot find a matching membership. Please check the search criteria you have entered. Please contact OMERS OMERS Employer Experience at 1-833-884-0389 or 416-350-6750 for additional assistance. OMERS Ref. No: 38007 '
    },
    {
      Code: '38007',
      DefaultMsg:
        'We cannot find a matching membership. Please check the search criteria you have entered. Please contact OMERS OMERS Employer Experience at 1-833-884-0389 or 416-350-6750 for additional assistance. OMERS Ref. No: 38007 '
    },
    {
      Code: '12260',
      DefaultMsg:
        'Member does not have any elected purchases. If reporting new purchase or election of an existing purchase, select the "New/Elected Purchase" purchase type row. Please contact OMERS OMERS Employer Experience at 1-833-884-0389 or 416-350-6750 for additional assistance. OMERS Ref. No: 12260 '
    },
    {
      Code: '12263',
      DefaultMsg:
        'The employer does not have any elected  purchases that are not fully allocated.  OMERS Ref. No: 12263 '
    },
    {
      Code: '39015',
      DefaultMsg:
        'The Start and End Dates for Broken Service must be within a valid Employment Period. Please check the dates you have entered. OMERS Ref. No: 39015 '
    },
    {
      Code: '5100',
      DefaultMsg:
        'The amount entered in the "Offset - Type 3" field is invalid. Format: Limited to 9 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 5100'
    },
    {
      Code: '5001',
      DefaultMsg:
        'The amount entered in the "Member" field is invalid. Format: Limited to 9 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 5001'
    },
    {
      Code: '5002',
      DefaultMsg:
        'The amount entered in the "Employer" field is invalid. Format: Limited to 9 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 5002'
    },
    {
      Code: '5003',
      DefaultMsg:
        'Please enter the  member or employer amount. OMERS Ref. No: 5003'
    },
    {
      Code: '5004',
      DefaultMsg:
        'member or employer RPP amount must be entered. OMERS Ref. No:5004'
    },
    {
      Code: '5005',
      DefaultMsg:
        'Payments for prior months will lead to late remittance charge. OMERS Ref. No:5005'
    },
    {
      Code: '5006',
      DefaultMsg:
        'Please enter the member or employer RPP amount. OMERS Ref. No: 5006'
    },
    {
      Code: '5007',
      DefaultMsg: 'Please select the Service purchase type. OMERS Ref. No: 5007'
    },
    {
      Code: '9001',
      DefaultMsg:
        'Please fax the inactive member details, and the dates to which the retroactive payment applies to OMERS at 416-361-9809. OMERS Ref. No: 9001'
    },
    {
      Code: '9002',
      DefaultMsg:
        'Please note that there will be a late remittance charge for payments made for prior months.  OMERS Ref. No: 9002'
    },
    {
      Code: '9003',
      DefaultMsg:
        'Click on the "Search and Add member service purchases" link to add the members for whom you are remitting  contributions. OMERS Ref. No: 9003'
    },
    {
      Code: '5200',
      DefaultMsg:
        'Please enter a Reason for adjustment. Format:  Limited to 200 alphanumeric characters. OMERS Ref. No: 5200'
    },
    {
      Code: '5201',
      DefaultMsg:
        'Please enter the Payment schedule remittance details. OMERS Ref. No: 5201'
    },
    {
      Code: '5202',
      DefaultMsg:
        'The Reason for adjustment is invalid.  Format:  Limited to 200 alphanumeric characters. OMERS Ref. No: 5202'
    },
    {
      Code: '5203',
      DefaultMsg:
        'The Remittance detail is invalid.  Format:   Limited to 200 alphanumeric characters. OMERS Ref. No: 5203'
    },
    {
      Code: '5204',
      DefaultMsg:
        'Please select  the Payment Schedule type.  OMERS Ref. No: 5204'
    },
    {
      Code: '5205',
      DefaultMsg:
        'This request is no longer in "Pending" status and cannot be cancelled. OMERS Ref. No: 5205.'
    },
    {
      Code: '5101',
      DefaultMsg:
        'The amount entered in the "Total payment" field is invalid.  Format: Limited to 9 numbers before the decimal and 2 numbers after the decimal.  OMERS Ref. No: 5101'
    },
    {
      Code: '5102',
      DefaultMsg:
        'The Company field is invalid.  Format:  Limited to 20 alphanumeric characters. OMERS Ref. No: 5102'
    },
    {
      Code: '5103',
      DefaultMsg:
        'The Location number is invalid.  Format:  Limited to 30 alphanumeric characters.  OMERS Ref. No: 5103'
    },
    {
      Code: '5104',
      DefaultMsg: 'Bank payment date is required. OMERS Ref. No:5104'
    },
    {
      Code: '5105',
      DefaultMsg:
        'The Confirmation number is invalid.  Format:   Limited to 20 alphanumeric characters. OMERS Ref. No: 5105'
    },
    {
      Code: '5106',
      DefaultMsg:
        'Group # is required for Payment method "Consolidated payment". OMERS Ref. No:5106'
    },
    {
      Code: '5107',
      DefaultMsg: 'Total Paid is required. OMERS Ref. No:5107'
    },
    {
      Code: '5301',
      DefaultMsg:
        'For Basic plan (RPP) contribution Employer and Member amounts should match OMERS Ref. No:5301'
    },
    {
      Code: '5302',
      DefaultMsg:
        'For Full earnings  (RCA) contribution Employer and Member amounts should match OMERS Ref. No:5302'
    },
    {
      Code: '5303',
      DefaultMsg:
        'Cannot submit e-From 105 for Form months in the future OMERS Ref. No:5303'
    },
    {
      Code: '5502',
      DefaultMsg:
        'There are elected purchases for this member.  Click on the "Add new purchase to Form 105" button if you are reporting a new purchase, or making an election of an existing purchase.  OMERS Ref. No: 5502'
    },
    {
      Code: '5600',
      DefaultMsg:
        "Please enter the contact's first and last name with a telephone number including the area code and/or an e-mail address.    OMERS Ref. No: 5600"
    },
    {
      Code: '5601',
      DefaultMsg:
        'The employer contact is being used by a batch process. Please try to delete the contact later.  OMERS Ref. No: 5601'
    },
    {
      Code: '5108',
      DefaultMsg:
        'The Offset - Type 3 amount cannot be higher than the Total Payable amount.  OMERS Ref. No: 5108'
    },
    {
      Code: '5109',
      DefaultMsg:
        'The Bank payment date is invalid. The date must be entered as YYYYMMDD. OMERS Ref. No: 5109'
    },
    {
      Code: '6000',
      DefaultMsg:
        'Please ensure that the Employment occupation Effective from date is before the Effective to date. Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531. OMERS Ref. No: 6000'
    },
    {
      Code: '6001',
      DefaultMsg:
        'Please ensure that the Employment period Effective from date is before the Effective to date. Enter the date as yyyymmdd. For example, enter May 31, 2002 as 20020531. OMERS Ref. No: 6001'
    },
    {
      Code: '6002',
      DefaultMsg:
        'The Employment period Effective to date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6002'
    },
    {
      Code: '6003',
      DefaultMsg:
        'The Employment occupation Effective to date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6003'
    },
    {
      Code: '6004',
      DefaultMsg:
        "The Employment occupation Effective from date should be today's date or before.  Dates must be entered as YYYYMMDD. OMERS Ref. No: 6004"
    },
    {
      Code: '6005',
      DefaultMsg:
        "The Employment period Effective from date should be today's date or before. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6005"
    },
    {
      Code: '6006',
      DefaultMsg:
        'The Employment period Effective from date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6006'
    },
    {
      Code: '6007',
      DefaultMsg:
        'The Employment occupation Effective from date is invalid. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6007'
    },
    {
      Code: '6008',
      DefaultMsg:
        'The Employment period Effective to date must be blank. This indicates that the selected employee information is current. OMERS Ref. No: 6008'
    },
    {
      Code: '6009',
      DefaultMsg:
        'The Employment occupation Effective to date must be blank. This indicates that the selected employee information is current. OMERS Ref. No: 6009'
    },
    {
      Code: '6010',
      DefaultMsg: 'The NRA is mandatory. OMERS Ref. No 6010'
    },
    {
      Code: '6011',
      DefaultMsg: 'The Employment status is mandatory. OMERS Ref. No 6011'
    },
    {
      Code: '6012',
      DefaultMsg: 'The Occupation is mandatory. OMERS Ref. No 6012'
    },
    {
      Code: '6013',
      DefaultMsg:
        'When the NRA is 65 the Occupation must be Councillor, Paramedic or Other. OMERS Ref. No: 6013'
    },
    {
      Code: '6014',
      DefaultMsg:
        'When the NRA is 60 the Occupation must be Police or Fire. OMERS Ref. No: 6014'
    },
    {
      Code: '6015',
      DefaultMsg:
        'The Employment occupation Effective from date is mandatory. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6015'
    },
    {
      Code: '6016',
      DefaultMsg:
        'The Employment period Effective from date is mandatory. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6016'
    },
    {
      Code: '6017',
      DefaultMsg:
        'The Employment period Effective to date is mandatory. Dates must be entered as  YYYYMMDD. OMERS Ref. No: 6017'
    },
    {
      Code: '6018',
      DefaultMsg:
        'The Employment occupation Effective to date is mandatory. Dates must be entered as  YYYYMMDD. OMERS Ref. No: 6018'
    },
    {
      Code: '6019',
      DefaultMsg:
        'The Employment period Effective to date must be the same or later than the End date of  the last posted service period. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6019'
    },
    {
      Code: '6020',
      DefaultMsg:
        'The Employment occupation Effective to date must be the same or later than the End date of the last posted service period. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6020'
    },
    {
      Code: '6021',
      DefaultMsg:
        "You cannot use the e-Form 106 to change the member's NRA from 65 to 60.  Please submit a Form 106. OMERS Ref. No: 6021"
    },
    {
      Code: '6022',
      DefaultMsg:
        "The Employment period Effective to date should be today's date or before. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6022"
    },
    {
      Code: '6023',
      DefaultMsg:
        "The Employment occupation Effective to date should be today's date or before. Dates must be entered as YYYYMMDD. OMERS Ref. No: 6023"
    },
    {
      Code: '6024',
      DefaultMsg:
        'The Employee ID cannot be more than 10 characters.  OMERS Ref. No: 6024'
    },
    {
      Code: '6025',
      DefaultMsg: "The Member's affiliation is mandatory. OMERS Ref. No 6025"
    },
    {
      Code: '7000',
      DefaultMsg:
        "Please check the Member's Affiliation - OMERS codes, one of the codes have been discontinued. OMERS Ref. No: 7000"
    },
    {
      Code: '7001',
      DefaultMsg:
        'The member should have at least one Member Affiliation code selected. OMERS Ref. No: 7001'
    },
    {
      Code: '7002',
      DefaultMsg:
        "Duplicate Member's Affiliation codes are not allowed. OMERS Ref. No: 7002"
    },
    {
      Code: '12261',
      DefaultMsg:
        'The Group number you have entered is invalid. OMERS Ref. No: 12261'
    },
    {
      Code: '12371',
      DefaultMsg:
        'There are no Form 119 years to be reconciled. An e-Form 119 request cannot be created. OMERS Ref. No: 12371'
    },
    {
      Code: '12319',
      DefaultMsg:
        '%1 requests were batched to be submitted. %2 records were skipped. OMERS Ref. No: 12319'
    },
    {
      Code: '12320',
      DefaultMsg:
        'This OMERS form does not have any available NOT submitted request to process. Please select another form number. OMERS Ref. No: 12320'
    },
    {
      Code: '12321',
      DefaultMsg:
        'A request is opened or batched for processing by user id {0}. You will be able to access this request once it has been released by the user.  OMERS Ref. No: 12321'
    },
    {
      Code: '12304',
      DefaultMsg:
        '%1 requests were batched to be deleted %2 records were skipped. OMERS Ref. No: 12304'
    },
    {
      Code: '12308',
      DefaultMsg:
        'Dashboard Refresh Activity is currently in progress. Please try again in 15 minutes. OMERS Ref. No: 12308'
    },
    {
      Code: '12322',
      DefaultMsg:
        'This Import does not have any available NOT submitted request to process. Please select another Import. OMERS Ref. No: 12322'
    },
    {
      Code: '12323',
      DefaultMsg:
        'This Import is already batched to be deleted. OMERS Ref. No: 12323'
    },
    {
      Code: '12324',
      DefaultMsg:
        'There has been more then :1: hours since the import was completed. Deleting this import is not allowed.  OMERS Ref. No: 12324'
    },
    {
      Code: '12372',
      DefaultMsg:
        'This member does not have any adjustable purchases. OMERS Ref. No: 12372'
    },
    {
      Code: '12373',
      DefaultMsg:
        'The e-Form 143 adjustment request cannot be submitted through e-Form, please send an e-Correspondence or revised paper 143. OMERS Ref. No: 12373'
    },
    {
      Code: '12368',
      DefaultMsg:
        'Membership information is no longer available on e-access for this membership.	  OMERS Ref. No: 12368'
    },
    {
      Code: '12403',
      DefaultMsg:
        'An extract is already in progress. Please wait until we complete this request before initiating another extract of the same type.  OMERS Ref. No: 12403'
    },
    {
      Code: '12404',
      DefaultMsg:
        'The input batch is not an extract batch process. OMERS Ref. No: 12404'
    },
    {
      Code: '12405',
      DefaultMsg: 'Extract type not found. OMERS Ref. No: 12405'
    },
    {
      Code: '12406',
      DefaultMsg: 'The input batch is not completed yet. OMERS Ref. No: 12406'
    },
    {
      Code: '5705',
      DefaultMsg:
        'Contact selected is unavailable, go to Add/update contacts to add a new contact.	  OMERS Ref. No: 5705'
    },
    {
      Code: '70447',
      DefaultMsg:
        'The highest earnings cannot be calculated. Please contact OMERS Employer Experience.  OMERS Ref. No: 70447 '
    },
    {
      Code: '12450',
      DefaultMsg:
        'The PA cannot be calculated. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: 12450 '
    },
    {
      Code: '32002',
      DefaultMsg:
        'The Group number for a  consolidated payment cannot be the same as the group number used in this e-Form 105 request.  OMERS Ref. No:32002'
    },
    {
      Code: '70519',
      DefaultMsg:
        'The email address you have entered is already in use. OMERS Ref. No: 70519'
    },
    {
      Code: '70551',
      DefaultMsg:
        'Start Date of the {0}, must be before {1}, or after {2}. For {0}, starting in that period use {3}. OMERS Ref. No: 70551 '
    },
    {
      Code: '70552',
      DefaultMsg:
        'The {0}, exceeds the maximum allowed period of {1}, months. Please verify and revise {2}, period. OMERS Ref. No: 70552 '
    },
    {
      Code: '70553',
      DefaultMsg:
        'Start Date of the {0}, must be between {1}, and {2}. OMERS Ref. No: 70553'
    },
    {
      Code: '70554',
      DefaultMsg:
        'Family Medical Leaves cannot be reported with a start date prior to June 29, 2004. OMERS Ref. No: 70554 '
    },
    {
      Code: '70555',
      DefaultMsg:
        'The Start Date of the {0}, must be on or after {1}. OMERS Ref. No: 70555'
    },
    {
      Code: '70558',
      DefaultMsg:
        'You cannot report a Disability elimination period for a disability recurrence. OMERS Ref. No: 70558'
    },
    {
      Code: '70559',
      DefaultMsg:
        'The Disability elimination period End date, must be before the Date entitled to apply for a disability benefit from OMERS. OMERS Ref. No: 70559'
    },
    {
      Code: '70560',
      DefaultMsg:
        'The field "Date last physically at work" is mandatory. The date must be entered as YYYYMMDD.   OMERS Ref. No: 70560'
    },
    {
      Code: '70561',
      DefaultMsg:
        'The "Date last physically at work" is invalid. The date must be entered as YYYYMMDD.  OMERS Ref. No: 70561'
    },
    {
      Code: '70562',
      DefaultMsg:
        'The field "Is this a disability recurrence?" is mandatory.  OMERS Ref. No: 70562'
    },
    {
      Code: '70563',
      DefaultMsg:
        'The "Date last physically at work" must be on or before today\'s date. The date must be entered as YYYYMMDD. OMERS Ref. No: 70563'
    },
    {
      Code: '70564',
      DefaultMsg:
        'The leave that you are reporting must not be in the future or more than one year in the past. OMERS Ref. No: 70564'
    },
    {
      Code: '70565',
      DefaultMsg:
        'The "Year of leave", must be in the same year as the Date entitled to apply for a disability benefit from OMERS. OMERS Ref. No: 70565'
    },
    {
      Code: '70566',
      DefaultMsg:
        'The field "Start date" is mandatory. The date must be entered as YYYYMMDD. OMERS Ref. No: 70566 '
    },
    {
      Code: '70567',
      DefaultMsg:
        'The field "End date" is mandatory. The date must be entered as YYYYMMDD. OMERS Ref. No: 70567 '
    },
    {
      Code: '70568',
      DefaultMsg:
        'The "End date" of the leave period cannot be in future years. OMERS Ref. No: 70568 '
    },
    {
      Code: '13012',
      DefaultMsg:
        'Mandatory input parameter must be entered. OMERS Ref. No: 13012 '
    },
    {
      Code: '70820',
      DefaultMsg:
        'The Agreement Effective Date must be on or after {0}. OMERS Ref. No: 70820 '
    },
    {
      Code: '70821',
      DefaultMsg:
        'The Agreement Effective Date must be on the first day of the month. OMERS Ref. No: 70821 '
    },
    {
      Code: '70822',
      DefaultMsg:
        'The field {0}, is not correct. It must be entered in {1}, format. OMERS Ref. No: 70822 '
    },
    {
      Code: '8000',
      DefaultMsg:
        'The projection percentage (Year {0},) must be a positive number. Tip: The number must be greater than or equal to 0 and less than or equal to 100. Format: Limited to 3 numeric characters before the decimal point and 2 numeric characters after the decimal point. OMERS Ref. No: 8000 '
    },
    {
      Code: '78788',
      DefaultMsg:
        'This class has an existing agreement. The date entered must be at least 3 years after the effective date of the existing agreement. OMERS Ref. No: 78788 '
    },
    {
      Code: '70905',
      DefaultMsg:
        'The Annual Contributory Earnings Salary must be greater than 0. OMERS Ref. No: 70905 '
    },
    {
      Code: '70800',
      DefaultMsg:
        'The Supplemental Plan Participation Date cannot be in the future. OMERS Ref. No: 70800 '
    },
    {
      Code: '70801',
      DefaultMsg:
        'The Supplemental Plan Participation Date cannot be prior to the current year. OMERS Ref. No: 70801 '
    },
    {
      Code: '14002',
      DefaultMsg:
        'The Agreement Approval Date must be less than 2 years from the current date. OMERS Ref. No: 14002 '
    },
    {
      Code: '14003',
      DefaultMsg:
        'The AED is in the future. This agreement cannot be submitted to OMERS prior to the Agreement Effective Date. OMERS Ref. No: 14003 '
    },
    {
      Code: '70932',
      DefaultMsg:
        'Periods of scattered days are not permitted during a year in which Supplemental Plan coverage began or ended. Please do not report this leave period as scattered days. OMERS Ref. No: 70932 '
    },
    {
      Code: '79092',
      DefaultMsg:
        'A Supplemental Plan Agreement Set-Up is currently in progress for this membership. The Agreement Set-Up must be completed before this transaction can be processed. OMERS Ref. No: 79092 '
    },
    {
      Code: '78790',
      DefaultMsg:
        'There is at least one supplemental coverage record with no matching employment period. OMERS Ref. No: 78790 '
    },
    {
      Code: '15420',
      DefaultMsg:
        'The Event Date cannot be on or before the last Event Date for the membership. OMERS Ref. No: 15420 '
    },
    {
      Code: '79001',
      DefaultMsg:
        'The End of Coverage Date cannot be later than the current month. OMERS Ref. No: 79001 '
    },
    {
      Code: '79002',
      DefaultMsg:
        'The end of coverage can only be processed if the membership is active and there is existing supplemental coverage. OMERS Ref. No: 79002 '
    },
    {
      Code: '79008',
      DefaultMsg:
        'The End of Coverage Date cannot be earlier than the Enrolment Date. OMERS Ref. No: 79008 '
    },
    {
      Code: '79010',
      DefaultMsg:
        'The End of Coverage Date cannot be earlier than the current employment period Effective From Date. OMERS Ref. No: 79010 '
    },
    {
      Code: '79011',
      DefaultMsg:
        'The End of Coverage Date cannot be earlier than the current Employment Occupation Effective From Date. OMERS Ref. No: 79011 '
    },
    {
      Code: '79012',
      DefaultMsg:
        'The End of Coverage Date cannot be earlier than the current Supplemental Plan Participation Effective From Date. OMERS Ref. No: 79012 '
    },
    {
      Code: '70402',
      DefaultMsg:
        'The retirement event date has to be within 60 days from the current date. OMERS Ref. No: 70402 '
    },
    {
      Code: '11110',
      DefaultMsg:
        'This member has an active payment schedule for a service purchase. This request cannot be processed until the payments for the purchase are resolved. OMERS Ref. No: 11110 '
    },
    {
      Code: '70812',
      DefaultMsg:
        'You have reported supplemental plan contributions for a member who does not have supplemental plan coverage. OMERS Ref. No: 70812 '
    },
    {
      Code: '70813',
      DefaultMsg:
        'This member does not have supplemental plan coverage. Therefore, primary plan rebound contributions cannot be reported. OMERS Ref. No: 70813 '
    },
    {
      Code: '79049',
      DefaultMsg:
        'Service to Include cannot be greater than total service available to be purchased. No: 79049 '
    },
    {
      Code: '79077',
      DefaultMsg:
        'There is a period of Broken Service that overlaps a Supplemental Plan coverage start date. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750. OMERS Ref. No: 79077 '
    },
    {
      Code: '15424',
      DefaultMsg:
        'You have not made any changes to the Member or Employment information.  If you want to report changes to information that cannot be handled in this request please contact OMERS OMERS Employer Experience at {0}, or {1}.  OMERS Ref. No: 15424 '
    },
    {
      Code: '12736',
      DefaultMsg:
        'You cannot enter {1}, in the {0}, field. OMERS Ref. No: 12736'
    },
    {
      Code: '85304',
      DefaultMsg:
        'The "Authorization for Disclosure of Personal Information" is mandatory. OMERS Ref. No: 85304'
    },
    {
      Code: '85302',
      DefaultMsg:
        'Consent for Paperless Communication is not selected. OMERS Ref. No: 85302'
    },
    {
      Code: '85305',
      DefaultMsg:
        'Either the Home Phone Number or the Mobile Number is mandatory. OMERS Ref. No: 85305'
    },
    {
      Code: '85303',
      DefaultMsg:
        'If the "Consent for paperless communication" is selected, then the "Email Address" in the "Address" tab is mandatory. OMERS Ref. No: 85303'
    },
    {
      Code: '85306',
      DefaultMsg:
        'There was a problem submitting this request. Please re-submit this request in 15 minutes. It can be found under the "Requests-View not Submitted". OMERS Ref. No:85306'
    },
    {
      Code: '85307',
      DefaultMsg:
        'Currently multiple leave periods cannot be reported in a single e-Form 165a when submitting a Disability Leave. Please contact Employer Experience if you have any questions. OMERS Ref. No: 85307'
    },
    {
      Code: '715445',
      DefaultMsg:
        'The e-Form 143 adjustment request cannot be submitted through e-Form, please send an e-Correspondence or revised paper 143. OMERS Ref. No: 715445'
    },
    {
      Code: '715449',
      DefaultMsg:
        'The e-Form 143 adjustment request cannot be submitted through e-Form, please send an e-Correspondence or revised paper 143. OMERS Ref. No: 715449'
    },
    {
      Code: '715450',
      DefaultMsg:
        'The annual reconciliation process is currently in progress; please try this request later. OMERS Ref. No: 715450'
    },
    {
      Code: '350',
      DefaultMsg:
        'The request can no longer be recalled, please close this window and attempt an e-Form 143 adjustment request. OMERS Ref. No: 350'
    },
    {
      Code: '100091',
      DefaultMsg:
        'The member already has an email address saved. OMERS Ref. No: 100091'
    }
  ],
  ErrorCodeRegistry: {
    ExceptionEntry: [
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SignInMultipleInputEditException',
        PageBeanName: 'SignIn',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName: 'com.omers.emss.common.exception.SignInCommandException',
        PageBeanName: 'SignIn',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.HomeMultipleInputEditException',
        PageBeanName: 'Home',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ResetPasswordMultipleInputEditException',
        PageBeanName: 'ResetPassword',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ResetPasswordUserNameMultipleInputEditException',
        PageBeanName: 'ResetPasswordUserName',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ResetPasswordQuestionsMultipleInputEditException',
        PageBeanName: 'ResetPasswordQuestions',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.PasswordMultipleInputEditException',
        PageBeanName: 'Password',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ChangePasswordMultipleInputEditException',
        PageBeanName: 'ChangePassword',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.RetroPayMultipleInputEditException',
        PageBeanName: 'RetroPay',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.EmploymentServPeriodsMultipleInputEditException',
        PageBeanName: 'EmplServPeriods',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.FinancialForm143MultipleInputEditException',
        PageBeanName: 'Financial',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.EmploymentForm143MultipleInputEditException',
        PageBeanName: 'Employment',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.AddressForm143MultipleInputEditException',
        PageBeanName: 'Address',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ChangingMemberInfoForm106MultipleInputEditException',
        PageBeanName: 'ChangingMemberInfo',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.AdvanceElectionForm143MultipleInputEditException',
        PageBeanName: 'AdvanceElection',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.EmployersAuthForm143MultipleInputEditException',
        PageBeanName: 'EmployerInformation',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SupportingInfoForm143MultipleInputEditException',
        PageBeanName: 'SupportingInformation',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.HighestEarningsForm143MultipleInputEditException',
        PageBeanName: 'HighestEarnings',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ContactForm143MultipleInputEditException',
        PageBeanName: 'Contact',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.BrokenServiceForm165MultipleInputEditException',
        PageBeanName: 'BrokenService',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.PregnancyParentalForm166MultipleInputEditException',
        PageBeanName: 'PregnancyParental',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ContactMgmtForm143MultipleInputEditException',
        PageBeanName: 'ContactManagement',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ExceptionMultipleInputEditException',
        PageBeanName: 'Exception',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.RequestPageMultipleInputEditException',
        PageBeanName: 'Request',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.RequestSearchMultipleInputEditException',
        PageBeanName: 'RequestSearch',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityEmployerSearchMultipleInputEditException',
        PageBeanName: 'SecurityEmployerSearch',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityEmployeeSearchMultipleInputEditException',
        PageBeanName: 'SecurityEmployeeSearch',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityEmployeeDetailsMultipleInputEditException',
        PageBeanName: 'SecurityEmployeeDetails',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityAdministratorDetailsMultipleInputEditException',
        PageBeanName: 'SecurityAdministratorDetails',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityAdministratorListMultipleInputEditException',
        PageBeanName: 'SecurityAdministratorList',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityConfirmPasswordMultipleInputEditException',
        PageBeanName: 'SecurityConfirmPassword',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.SecurityReportMultipleInputEditException',
        PageBeanName: 'SecurityReport',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.EventStatusCommandException',
        PageBeanName: 'EventStatus',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.MembershipSearchMultipleInputEditException',
        PageBeanName: 'MembershipSearch',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesAssumptionMultipleInputEditException',
        PageBeanName: 'QuotesAssumption',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesAddressMultipleInputEditException',
        PageBeanName: 'QuotesAddress',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesEmployerInformationMultipleInputEditException',
        PageBeanName: 'QuotesEmployerInformation',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesGroupAssumptionMultipleInputEditException',
        PageBeanName: 'QuotesGroupAssumptions',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesGroupMembersMultipleInputEditException',
        PageBeanName: 'QuotesGroupMembers',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102EmployeePageMultipleInputEditException',
        PageBeanName: 'Form102Employee',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102BeneficiaryPageMultipleInputEditException',
        PageBeanName: 'Form102Beneficiary',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102SuppPlanPageMultipleInputEditException',
        PageBeanName: 'Form102SuppPlanInfo',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102AddressMultipleInputEditException',
        PageBeanName: 'Form102Address',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102EmployerInformationPageMultipleInputEditException',
        PageBeanName: 'Form102EmployerInformation',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F102InsertEmployeeCommandException',
        PageBeanName: 'Form102Employee',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102EmploymentPageMultipleInputEditException',
        PageBeanName: 'Form102Employment',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102BrokenServicePageMultipleInputEditException',
        PageBeanName: 'Form102BrokenService',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.EMSSBaseForm102PageMultipleInputEditException',
        PageBeanName: 'EMSSBaseForm102',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.CreateBatchQuoteRequestCommandException',
        PageBeanName: 'QuotesGroupMembers',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesGroupEmployerInformationMultipleInputEditException',
        PageBeanName: 'QuotesGroupEmployerInformation',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.MembershipSearchCommandException',
        PageBeanName: 'MembershipSearch',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.ServiceSummaryCommandException',
        PageBeanName: 'ServiceSummary',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.EventStatusMultipleInputEditException',
        PageBeanName: 'EventStatus',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.ResetPasswordCommandException',
        PageBeanName: 'ResetPassword',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.ResetPasswordUserNameCommandException',
        PageBeanName: 'ResetPasswordUserName',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.ResetPasswordQuestionsCommandException',
        PageBeanName: 'ResetPasswordQuestions',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.PasswordCommandException',
        PageBeanName: 'Password',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.ChangePasswordCommandException',
        PageBeanName: 'ChangePassword',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.CreateRequestCommandException',
        PageBeanName: 'MembershipSearch',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143EmploymentCommandException',
        PageBeanName: 'Employment',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143AddressCommandException',
        PageBeanName: 'Address',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143SupportingInfoCommandException',
        PageBeanName: 'SupportingInformation',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143ContactCommandException',
        PageBeanName: 'Contact',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143HighestEarningsCommandException',
        PageBeanName: 'HighestEarnings',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName: 'com.omers.emss.common.exception.F143PACommandException',
        PageBeanName: 'EmplServPeriods',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143FinancialPACommandException',
        PageBeanName: 'Financial',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143ServPeriodsCommandException',
        PageBeanName: 'Financial',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143AddressCommandException',
        PageBeanName: 'Financial',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.RequestSearchCommandException',
        PageBeanName: 'RequestSearch',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.F143ExceptionsCommandException',
        PageBeanName: 'Exception',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.SecurityChangePasswordCommandException',
        PageBeanName: 'SecurityConfirmPassword',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesHighestEarningsMultipleInputEditException',
        PageBeanName: 'QuotesHighestEarnings',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesPurchaseRequestMultipleInputEditException',
        PageBeanName: 'QuotesPurchaseRequest',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesFinancialMultipleInputEditException',
        PageBeanName: 'QuotesFinancial',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesRetroPayMultipleInputEditException',
        PageBeanName: 'QuotesRetroPay',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuoteCommentMultipleInputEditException',
        PageBeanName: 'QuotesComment',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.QuotesGroupCommentMultipleInputEditException',
        PageBeanName: 'QuotesGroupComment',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.MaintainCommentsMultipleInputEditException',
        PageBeanName: 'MaintainComments',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.ReportExtractMultipleInputEditException',
        PageBeanName: 'SecurityExtracts',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.Form102PreviousEmploymentMultipleInputEditException',
        PageBeanName: 'Form102PreviousEmployment',
        DefaultMsg: 'Command Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.snap.exception.RequestPrioritySearchMultipleInputEditException',
        PageBeanName: 'PrioritySearch',
        DefaultMsg: 'Validation Exception'
      },
      {
        ExceptionName:
          'com.omers.emss.common.exception.RequestPrioritySearchCommandException',
        PageBeanName: 'PrioritySearch',
        DefaultMsg: 'Command Exception'
      }
    ]
  },
  GenericErrorCode: {
    Code: 'GENERIC_ERROR',
    DefaultMsg:
      'System error. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.'
  }
};

export default errorCodes;
