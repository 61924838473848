const iconMap: { [key: string]: string | null } = {
  Error: 'images/toaster/error.svg',
  Warn: 'images/toaster/warn.svg',
  Info: 'images/toaster/info.svg',
  Success: 'images/toaster/success.svg',

  HomeNavigationIcon: 'images/v2/navigationIcons/home.svg',
  StewardshipNavigationIcon: 'images/v2/navigationIcons/stewardship.svg',
  ArchivedMessagesNavigationIcon:
    'images/v2/navigationIcons/archived-messages.svg',
  BatchProcessingNavigationIcon:
    'images/v2/navigationIcons/batch-processing.svg',
  DashboardNavigationIcon: 'images/v2/navigationIcons/dashboard.svg',
  DataExtractNavigationIcon: 'images/v2/navigationIcons/data-extract.svg',
  ElectionFormNavigationIcon: 'images/v2/navigationIcons/election-form.svg',
  MembersNavigationIcon: 'images/v2/navigationIcons/members.svg',
  MessageNavigationIcon: 'images/v2/navigationIcons/message.svg',
  NFTMembersNavigationIcon: 'images/v2/navigationIcons/NFTmembers.svg',
  RequestsNavigationIcon: 'images/v2/navigationIcons/requests.svg',
  ReturnedRequestsNavigationIcon:
    'images/v2/navigationIcons/returned-requests.svg',

  DocumentLargeIcon: 'images/v2/largeIcons/document.svg',
  MenuLargeIcon: 'images/v2/largeIcons/menu.svg',
  MessageOutlinePartialLargeIcon:
    'images/v2/largeIcons/message-outline-partial.svg',
  MessageOutlineLargeIcon: 'images/v2/largeIcons/message-outline.svg',
  UserDefaultLargeIcon: 'images/v2/largeIcons/user-default.svg',
  FileUploadLargeIcon: 'images/v2/largeIcons/file-upload.svg',
  TodoLargeIcon: 'images/v2/largeIcons/todo.svg',
  ListLargeIcon: 'images/v2/largeIcons/list.svg',
  MoneyBagLargeIcon: 'images/v2/largeIcons/money-bag.svg',
  StarLargeIcon: 'images/v2/largeIcons/star.svg',
  OpeningTimesLargeIcon: 'images/v2/largeIcons/opening-times.svg',
  CheckSingleSmallIcon: 'images/v2/smallIcons/check-single.svg',
  PoliceOfficerLargeIcon: 'images/v2/largeIcons/police-officer.svg',
  RateUpLargeIcon: 'images/v2/largeIcons/rate-up.svg',
  CurrencyExchange2LargeIcon: 'images/v2/largeIcons/currency-exchange-2.svg',
  MoneyTimeLargeIcon: 'images/v2/largeIcons/money-time.svg',
  PersonalTrainerLargeIcon: 'images/v2/largeIcons/personal-trainer.svg',
  RestoreLargeIcon: 'images/v2/largeIcons/restore.svg',
  CheckInLargeIcon: 'images/v2/largeIcons/check-in.svg',
  NCheckLargeIcon: 'images/v2/largeIcons/n-check.svg',
  FacebookLargeLogo: 'images/v2/largeIcons/facebook.svg',
  InstagramLargeLogo: 'images/v2/largeIcons/instagram.svg',
  LinkedInLargeLogo: 'images/v2/largeIcons/linkedin.svg',
  CALENDAR_EVENT_2: 'images/v2/largeIcons/calendar-event-2.svg',
  TIME_MACHINE: 'images/v2/largeIcons/time-machine.svg',

  AttachmentRegularIcon: 'images/v2/regularIcons/attachment.svg',
  AwaitingReplyRegularIcon: 'images/v2/regularIcons/awaiting-reply.svg',
  BackArrowRegularIcon: 'images/v2/regularIcons/back-arrow.svg',
  BarChartRegularIcon: 'images/v2/regularIcons/bar-chart.svg',
  CAddRegularIcon: 'images/v2/regularIcons/c-add.svg',
  CalculatorRegularIcon: 'images/v2/regularIcons/calculator.svg',
  CalendarRegularIcon: 'images/v2/regularIcons/calendar.svg',
  CheckListRegularIcon: 'images/v2/regularIcons/check-list.svg',
  CloudUploadRegularIcon: 'images/v2/regularIcons/cloud-upload.svg',
  DataUploadRegularIcon: 'images/v2/regularIcons/data-upload.svg',
  DEditRegularIcon: 'images/v2/regularIcons/d-edit.svg',
  DeleteRegularIcon: 'images/v2/regularIcons/delete.svg',
  DragRegularIcon: 'images/v2/regularIcons/drag.svg',
  ECorrespondenceOutlineRegularIcon:
    'images/v2/regularIcons/e-correspondence-outline.svg',
  ECorrespondenceSolidRegularIcon:
    'images/v2/regularIcons/e-correspondence-solid.svg',
  EditRegularIcon: 'images/v2/regularIcons/edit.svg',
  EllipsisRegularIcon: 'images/v2/regularIcons/ellipsis.svg',
  FilterRegularIcon: 'images/v2/regularIcons/filter.svg',
  GearRegularIcon: 'images/v2/regularIcons/gear.svg',
  HomepageRequestChevronRightRegularIcon:
    'images/v2/regularIcons/homepage-request-chevron-right.svg',
  LeaveRegularIcon: 'images/v2/regularIcons/leave.svg',
  LocationRegularIcon: 'images/v2/regularIcons/location.svg',
  ILocationRegularIcon: 'images/v2/regularIcons/i-location.svg',
  MessageRegularIcon: 'images/v2/regularIcons/message.svg',
  MoreRegularIcon: 'images/v2/regularIcons/more.svg',
  PrinterRegularIcon: 'images/v2/regularIcons/printer.svg',
  ResetRegularIcon: 'images/v2/regularIcons/reset.svg',
  TvRegularIcon: 'images/v2/regularIcons/tv.svg',
  PresentationRegularIcon: 'images/v2/regularIcons/presentation.svg',
  FileArticleRegularIcon: 'images/v2/regularIcons/file-article.svg',
  MegaphoneRegularIcon: 'images/v2/regularIcons/megaphone.svg',
  OmersRegularLogo: 'images/v2/regularIcons/o-logo.svg',

  AwaitingStatusIcon: 'images/v2/statusIcons/clock.svg',
  CompletedStatusIcon: 'images/v2/statusIcons/completed.svg',
  DangerStatusIcon: 'images/v2/statusIcons/danger.svg',
  InfoStatusIcon: 'images/v2/statusIcons/info.svg',
  WarningStatusIcon: 'images/v2/statusIcons/warning.svg',
  Spinner: 'images/v2/statusIcons/spinner.svg',
  HalfCircleSpinnerIcon: 'images/v2/statusIcons/half-circle-spinner.svg',

  CsvFileFormatIcon: 'images/v2/fileFormatIcons/CSV.svg',
  DocFileFormatIcon: 'images/v2/fileFormatIcons/DOC.svg',
  JpgFileFormatIcon: 'images/v2/fileFormatIcons/JPG.svg',
  PdfMultiFileFormatIcon: 'images/v2/fileFormatIcons/PDF-multi.svg',
  PdfOutlineFileFormatIcon: 'images/v2/fileFormatIcons/PDF-outline.svg',
  PdfFileFormatIcon: 'images/v2/fileFormatIcons/PDF.svg',
  PngFileFormatIcon: 'images/v2/fileFormatIcons/PNG.svg',
  TxtFileFormatIcon: 'images/v2/fileFormatIcons/TXT.svg',
  XlsFileFormatIcon: 'images/v2/fileFormatIcons/XLS.svg',
  FailedFileFormatIcon: 'images/v2/fileFormatIcons/failed.svg',

  ActionRequiredSmallIcon: 'images/v2/smallIcons/action-require.svg',
  AddTaskSmallIcon: 'images/v2/smallIcons/add_task.svg',
  ArrowDownSmallIcon: 'images/v2/smallIcons/arrow-down.svg',
  ArrowUpSmallIcon: 'images/v2/smallIcons/arrow-up.svg',
  ArrowRightSmallIcon: 'images/v2/smallIcons/arrow-right.svg',
  BackArrowSmallIcon: 'images/v2/smallIcons/back-arrow.svg',
  BookSmallIcon: 'images/v2/smallIcons/book.svg',
  ButtonPowerSmallIcon: 'images/v2/smallIcons/button-power.svg',
  CalendarSmallIcon: 'images/v2/smallIcons/calendar.svg',
  CheckCircleOutlineSmallIcon: 'images/v2/smallIcons/check-circle-outlined.svg',
  CheckCircleSolidSmallIcon: 'images/v2/smallIcons/check-circle-solid.svg',
  ChevronRightSmallIcon: 'images/v2/smallIcons/chevron-right.svg',
  ClockSmallIcon: 'images/v2/smallIcons/clock.svg',
  CloseSmallIcon: 'images/v2/smallIcons/close.svg',
  DataUploadSmallIcon: 'images/v2/smallIcons/data-upload.svg',
  DeleteSmallIcon: 'images/v2/smallIcons/delete.svg',
  DocumentCopySmallIcon: 'images/v2/smallIcons/document-copy.svg',
  DownSmallIcon: 'images/v2/smallIcons/down.svg',
  ECorrespondenceOutlineSmallIcon:
    'images/v2/smallIcons/e-correspondence-outline.svg',
  FileDownloadSmallIcon: 'images/v2/smallIcons/file-download.svg',
  HideSmallIcon: 'images/v2/smallIcons/hide.svg',
  HandoutSmallIcon: 'images/v2/smallIcons/handout.svg',
  HomeSmallIcon: 'images/v2/smallIcons/home.svg',
  LinkSmallIcon: 'images/v2/smallIcons/link.svg',
  LockSmallIcon: 'images/v2/smallIcons/lock.svg',
  MenuSmallIcon: 'images/v2/smallIcons/menu.svg',
  PenSmallIcon: 'images/v2/smallIcons/pen.svg',
  PresentationSmallIcon: 'images/v2/smallIcons/presentation.svg',
  QuestionCircleOutlineSmallIcon:
    'images/v2/smallIcons/question-circle-outline.svg',
  RefreshCircleSmallIcon: 'images/v2/smallIcons/refresh-circle.svg',
  RefreshSmallIcon: 'images/v2/smallIcons/refresh.svg',
  RoadSignRightSmallIcon: 'images/v2/smallIcons/road-sign-right.svg',
  ShowSmallIcon: 'images/v2/smallIcons/show.svg',
  SearchSmallIcon: 'images/v2/smallIcons/search.svg',
  SpeakerSmallIcon: 'images/v2/smallIcons/speaker.svg',
  TodoSmallIcon: 'images/v2/smallIcons/todo.svg',
  UnlinkSmallIcon: 'images/v2/smallIcons/unlink.svg',
  UserCFrameSmallIcon: 'images/v2/smallIcons/user-c-frame.svg',
  XCircleOutlineSmallIcon: 'images/v2/smallIcons/x-circle-outline.svg',
  XCircleSolidSmallIcon: 'images/v2/smallIcons/x-circle-solid.svg',
  OpenInNewSmallIcon: 'images/v2/smallIcons/open-in-new.svg',
  InfoSmallIcon: 'images/v2/smallIcons/info.svg',
  AddXSmallIcon: 'images/v2/xsmallIcons/add.svg',
  ArrowDownXSmallIcon: 'images/v2/xsmallIcons/arrow-down.svg',
  ArrowLeftXSmallIcon: 'images/v2/xsmallIcons/arrow-left.svg',
  ArrowRightXSmallIcon: 'images/v2/xsmallIcons/arrow-right.svg',
  ArrowUpXSmallIcon: 'images/v2/xsmallIcons/arrow-up.svg',
  DoubleArrowLeftXSmallIcon: 'images/v2/xsmallIcons/double-arrow-left.svg',
  DoubleArrowRightXSmallIcon: 'images/v2/xsmallIcons/double-arrow-right.svg',
  DeleteMinusXSmallIcon: 'images/v2/xsmallIcons/delete-minus.svg',
  RemoveXSmallIcon: 'images/v2/xsmallIcons/remove.svg',
  FilterArrowDownXSmallIcon: 'images/v2/xsmallIcons/filter-arrow-down.svg',
  UsersMeetingSmallIcon: 'images/v2/smallIcons/users-meeting.svg',
  HourglassSmallIcon: 'images/v2/smallIcons/hourglass.svg',
  BookingsCalendarLargeIcon: 'images/v2/largeIcons/bookings-calendar.svg',
  TrophyRegularIcon: 'images/v2/regularIcons/trophy.svg',
  FileMagnifyingGlassLargeIcon:
    'images/v2/largeIcons/file-magnifying-glass.svg',
  PinRegularIcon: 'images/v2/regularIcons/pin.svg',
  SpacemanPuffinLargeIcon: 'images/v2/largeIcons/spaceman-puffin.svg',
  NewConstructionSmallIcon: 'images/v2/smallIcons/new-construction.svg',
  SwapVerticalSmallIcon: 'images/v2/smallIcons/swap-vertical.svg',
  LogOutSmallIcon: 'images/v2/smallIcons/log-out.svg',
  ResetSmallIcon: 'images/reset.svg',
  ChevronDownSmallIcon: 'images/chevron-down.svg',
  OmersCircleLogo: 'images/logos/omers-circle-logo.svg',
  RefreshOneArrowCircleSmallIcon:
    'images/v2/smallIcons/refresh-one-arrow-circle.svg'
};
export default iconMap;

export enum IconNamesNavigation {
  HOME = 'HomeNavigationIcon',
  STEWARDSHIP = 'StewardshipNavigationIcon',
  ArchivedMessages = 'ArchivedMessagesNavigationIcon',
  BatchProcessing = 'BatchProcessingNavigationIcon',
  DASHBOARD = 'DashboardNavigationIcon',
  DATA_EXTRACT = 'DataExtractNavigationIcon',
  ELECTION_FORM = 'ElectionFormNavigationIcon',
  MEMBERS = 'MembersNavigationIcon',
  MESSAGE = 'MessageNavigationIcon',
  NFT_MEMBERS = 'NFTMembersNavigationIcon',
  REQUESTS = 'RequestsNavigationIcon',
  RETURNED_REQUESTS = 'ReturnedRequestsNavigationIcon'
}

export enum IconNamesLarge {
  DOCUMENT = 'DocumentLargeIcon',
  MENU = 'MenuLargeIcon',
  MESSAGE_OUTLINE_PARTIAL = 'MessageOutlinePartialLargeIcon',
  MESSAGE_OUTLINE = 'MessageOutlineLargeIcon',
  USER_DEFAULT = 'UserDefaultLargeIcon',
  FILE_UPLOAD = 'FileUploadLargeIcon',
  TODO = 'TodoLargeIcon',
  LIST = 'ListLargeIcon',
  MONEY_BAG = 'MoneyBagLargeIcon',
  STAR = 'StarLargeIcon',
  OPENING_TIMES = 'OpeningTimesLargeIcon',
  POLICE_OFFICER = 'PoliceOfficerLargeIcon',
  RATE_UP = 'RateUpLargeIcon',
  CURRENCY_EXCHANGE_2 = 'CurrencyExchange2LargeIcon',
  MONEY_TIME = 'MoneyTimeLargeIcon',
  PERSONAL_TRAINER = 'PersonalTrainerLargeIcon',
  RESTORE = 'RestoreLargeIcon',
  CHECK_IN = 'CheckInLargeIcon',
  N_CHECK = 'NCheckLargeIcon',
  BOOKINGS_CALENDAR = 'BookingsCalendarLargeIcon',
  FACEBOOK = 'FacebookLargeLogo',
  INSTAGRAM = 'InstagramLargeLogo',
  LINKEDIN = 'LinkedInLargeLogo',
  FILE_MAGNIFYING_GLASS = 'FileMagnifyingGlassLargeIcon',
  SPACEMAN_PUFFIN = 'SpacemanPuffinLargeIcon',
  TIME_MACHINE = 'TIME_MACHINE',
  CALENDAR_EVENT_2 = 'CALENDAR_EVENT_2'
}

export enum IconNamesRegular {
  ATTACHMENT = 'AttachmentRegularIcon',
  AWAITING_REPLY = 'AwaitingReplyRegularIcon',
  BACK_ARROW = 'BackArrowRegularIcon',
  BAR_CHART = 'BarChartRegularIcon',
  C_ADD = 'CAddRegularIcon',
  CALCULATOR = 'CalculatorRegularIcon',
  CALENDAR = 'CalendarRegularIcon',
  CHECKLIST = 'CheckListRegularIcon',
  CLOUD_UPLOAD = 'CloudUploadRegularIcon',
  DATA_UPLOAD = 'DataUploadRegularIcon',
  D_EDIT = 'DEditRegularIcon',
  DELETE = 'DeleteRegularIcon',
  DRAG = 'DragRegularIcon',
  ECORRESPONDENCE_OUTLINE = 'ECorrespondenceOutlineRegularIcon',
  ECORRESPONDENCE_SOLID = 'ECorrespondenceSolidRegularIcon',
  ELLIPSIS = 'EllipsisRegularIcon',
  EDIT = 'EditRegularIcon',
  FILTER = 'FilterRegularIcon',
  GEAR = 'GearRegularIcon',
  HOMEPAGE_REQUEST_CHEVRON_RIGHT = 'HomepageRequestChevronRightRegularIcon',
  ILOCATION = 'ILocationRegularIcon',
  LEAVE = 'LeaveRegularIcon',
  LOCATION = 'LocationRegularIcon',
  MESSAGE_REGULAR = 'MessageRegularIcon',
  MORE = 'MoreRegularIcon',
  PRINTER = 'PrinterRegularIcon',
  RESET = 'ResetRegularIcon',
  TV = 'TvRegularIcon',
  PRESENTATION = 'PresentationRegularIcon',
  FILE_ARTICLE = 'FileArticleRegularIcon',
  MEGAPHONE = 'MegaphoneRegularIcon',
  OMERS_LOGO = 'OmersRegularLogo',
  TROPHY = 'TrophyRegularIcon',
  PIN = 'PinRegularIcon',
  OMERS_CIRCLE_LOGO = 'OmersCircleLogo'
}

export enum IconNamesSmall {
  ACTION_REQUIRED = 'ActionRequiredSmallIcon',
  ADD_TASK = 'AddTaskSmallIcon',
  ARROW_DOWN = 'ArrowDownSmallIcon',
  ARROW_UP = 'ArrowUpSmallIcon',
  ARROW_RIGHT = 'ArrowRightSmallIcon',
  BACK_ARROW = 'BackArrowSmallIcon',
  BOOK = 'BookSmallIcon',
  BUTTON_POWER = 'ButtonPowerSmallIcon',
  CALENDAR = 'CalendarSmallIcon',
  CHECK_CIRCLE_OUTLINE = 'CheckCircleOutlineSmallIcon',
  CHECK_CIRCLE_SOLID = 'CheckCircleSolidSmallIcon',
  CHECK_SINGLE = 'CheckSingleSmallIcon',
  CHEVRON_RIGHT = 'ChevronRightSmallIcon',
  CLOCK = 'ClockSmallIcon',
  CLOSE = 'CloseSmallIcon',
  DATA_UPLOAD = 'DataUploadSmallIcon',
  DELETE = 'DeleteSmallIcon',
  DOCUMENT_COPY = 'DocumentCopySmallIcon',
  DOWN = 'DownSmallIcon',
  E_CORRESPONDENCE_OUTLINE = 'ECorrespondenceOutlineSmallIcon',
  FILE_DOWNLOAD = 'FileDownloadSmallIcon',
  HIDE = 'HideSmallIcon',
  HANDOUT = 'HandoutSmallIcon',
  HOME = 'HomeSmallIcon',
  LINK = 'LinkSmallIcon',
  LOCK = 'LockSmallIcon',
  MENU = 'MenuSmallIcon',
  PEN = 'PenSmallIcon',
  PRESENTATION = 'PresentationSmallIcon',
  QUESTION_CIRCLE_OUTLINE = 'QuestionCircleOutlineSmallIcon',
  REFRESH_CIRCLE = 'RefreshCircleSmallIcon',
  REFRESH = 'RefreshSmallIcon',
  REFRESH_ONE_ARROW_CIRCLE = 'RefreshOneArrowCircleSmallIcon',
  ROAD_SIGN_RIGHT = 'RoadSignRightSmallIcon',
  SHOW = 'ShowSmallIcon',
  SEARCH = 'SearchSmallIcon',
  SPEAKER = 'SpeakerSmallIcon',
  TODO = 'TodoSmallIcon',
  UNLINK = 'UnlinkSmallIcon',
  USER_C_FRAME = 'UserCFrameSmallIcon',
  X_CIRCLE_OUTLINE = 'XCircleOutlineSmallIcon',
  X_CIRCLE_SOLID = 'XCircleSolidSmallIcon',
  OPEN_IN_NEW = 'OpenInNewSmallIcon',
  INFO_OUTLINE = 'InfoSmallIcon',
  USERS_MEETING = 'UsersMeetingSmallIcon',
  HOURGLASS = 'HourglassSmallIcon',
  NEW_CONSTRUCTION = 'NewConstructionSmallIcon',
  SWAP_VERTICAL = 'SwapVerticalSmallIcon',
  LOG_OUT = 'LogOutSmallIcon',
  RESET = 'ResetSmallIcon',
  CHEVRON_DOWN = 'ChevronDownSmallIcon'
}

export enum IconNamesXSmall {
  ADD = 'AddXSmallIcon',
  ARROW_DOWN = 'ArrowDownXSmallIcon',
  ARROW_LEFT = 'ArrowLeftXSmallIcon',
  ARROW_RIGHT = 'ArrowRightXSmallIcon',
  ARROW_UP = 'ArrowUpXSmallIcon',
  DOUBLE_ARROW_LEFT = 'DoubleArrowLeftXSmallIcon',
  DOUBLE_ARROW_RIGHT = 'DoubleArrowRightXSmallIcon',
  DELETE_MINUS = 'DeleteMinusXSmallIcon',
  REMOVE = 'RemoveXSmallIcon',
  FILTER_ARROW_DOWN = 'FilterArrowDownXSmallIcon'
}

export enum IconNamesStatus {
  AWAITING = 'AwaitingStatusIcon',
  COMPLETED = 'CompletedStatusIcon',
  DANGER = 'DangerStatusIcon',
  INFO = 'InfoStatusIcon',
  WARNING = 'WarningStatusIcon',
  SPINNER = 'Spinner',
  HALF_CIRCLE_SPINNER = 'HalfCircleSpinnerIcon'
}

export enum IconNamesFileFormat {
  CSV = 'CsvFileFormatIcon',
  DOC = 'DocFileFormatIcon',
  JPG = 'JpgFileFormatIcon',
  PDF_MULTI = 'PdfMultiFileFormatIcon',
  PDF_OUTLINE = 'PdfOutlineFileFormatIcon',
  PDF = 'PdfFileFormatIcon',
  PNG = 'PngFileFormatIcon',
  TXT = 'TxtFileFormatIcon',
  XLS = 'XlsFileFormatIcon',
  FAILED = 'FailedFileFormatIcon'
}
