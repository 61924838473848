import { FeatureInterface } from 'unleash-client/lib/feature';

export const currentFlags = {
  ENABLE_ESR: 'enable_esr',
  ESR_PDF: 'esr_pdf',
  ESR_LANGUAGE: 'esr_language',
  ESR_THRESHOLDS: 'esr_thresholds',
  FORM_105: 'form_105',
  FORM_119: 'form_119',
  FORM_143: 'form_143',
  FORM_143_DEATH: 'form_143_death',
  FORM_143_DISABILITY: 'form_143_disability',
  FORM_143_ADJUSTMENTS: 'form_143_adjustments',
  FORM_143_ADJUSTMENTS_RECALL: 'form_143_adjustments_recall',
  FORM_164: 'form_164',
  FORM_165A: 'form_165a',
  FORM_165B: 'form_165b',
  FORM_165C: 'form_165c',
  FORM_190: 'form_190',
  FORM_152: 'form_152',
  FORM_158_PRD: 'form_158_prd',
  BATCH_PROCESSING: 'batch_processing',
  BATCH_PROCESSING_102: 'batch_processing_102',
  ANNUAL_RECONCILIATION: 'annual_reconciliation',
  REPORTS: 'reports',
  RETURNED_REQUESTS: 'returned_requests',
  NEW_ECORR_OPTIONS: 'new_ecorr_options',
  LEGACY_ECORR_OPTIONS: 'legacy_ecorrespondance',
  BATCH_PRINT: 'batch_print',
  DATA_EXTRACT: 'data_extract',
  FORM_E_105_NEXT: 'form_e_105_next',
  BACKEND_SYS_DATE: 'backend_sys_date',
  NFT: 'nft',
  NFT_ATTESTATION: 'nft_attestation',
  NFT_BYPASS_HIREDATE_VALIDATION: 'nft_bypass_hiredate_validation',
  COBROWSE: 'cobrowse',
  SMO_ATTESTATION: 'smo_attestation',
  SESSION_TIMER: 'session_timer',
  STATIC_CONTENT: 'static_content',
  EMPLOYER_BOOKINGS: 'employer_bookings',
  EMPLOYER_BOOKINGS_ENHANCEMENTS: 'employer_bookings_enhancements',
  EMPLOYER_BOOKINGS_DEBUG: 'employer_bookings_debug',
  EMPLOYER_BOOKINGS_SPRIG_SURVEY: 'employer_bookings_sprig_survey',
  MEMBER_PROFILE_REQUESTS_TAB: 'member_profile_requests_tab',
  FORM_165A_165C_FRENCH: 'form_165a_165c_french',
  CONTENTFUL_PAGES: 'contentful_pages',
  NEW_NAV_HEADER: 'new_nav_header',
  CONTENTFUL_EMPLOYER_EDUCATION_WEEK: 'contentful_employer_education_week',
  CONTENTFUL_EMPLOYER_TOOLKIT: 'contentful_employer_toolkit',
  MEMBER_PROFILE_MESSAGES_TAB: 'member_profile_messages_tab',
  MFA: 'mfa',
  PING_BFF: 'ping_bff',
  REDIS_CACHE_USER_GROUPS: 'redis_cache_user_groups',
  ADHOC_REPORTS: 'adhoc_reports',
  NEW_EMAIL_SEND_ROUTE: 'new_email_send_route',
  CONTENTFUL_NAV_HEADER: 'contentful_nav_header',
  FORM_102_PROJECTED_PURCHASABLE_SERVICE:
    'form_102_projected_purchasable_service',
  FORM143_EMAIL_NOTIFICATION: 'form143_email_notification',
  LEAVE_PERIOD_REPORTING: 'leave_period_reporting'
} as const;

export type ConcreteFeatureFlags = typeof currentFlags[keyof typeof currentFlags];

export const isEnabled = (
  featureFlags: Array<FeatureInterface>,
  name: string
) => {
  const requestedFlag = featureFlags?.find(flag => flag.name === name);
  return requestedFlag?.enabled ?? false;
};
