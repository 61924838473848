import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormTextInput from 'components/v2/atomic/textInput/FormTextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useDispatch, useSelector } from 'react-redux';
import { shouldShowEmployer } from 'containers/auth/userSessionSaga';
import { RootState } from 'types';
import clientSide from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import { userSessionActionTypes } from 'containers/auth/userSessionActions';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import useLocalStorageState from 'use-local-storage-state';
import CalloutNotification from 'components/v2/atomic/calloutNotification/CalloutNotification';
import Cookies from 'universal-cookie';
import Button from 'components/v2/atomic/button/Button';
import Icon, { IconSize } from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import MFACardNavButtons, {
  MFASubmitButton
} from './components/MFACustomButtons';
import {
  AccountStatus,
  AccountStatusState,
  ACCOUNT_STATUS,
  MFACardTypes,
  LoginFormsInfo
} from './MFACards';
import { verificationCodeSchema } from './schemas';
import {
  getNotificationMessage,
  handleLoginSuccess,
  maskEmail
} from './utils/client-utils';
import { MFAErrorCodes, TwilioDonnaError } from './utils/types';

const MFACodeVerificationFormCard = ({
  setCurrentCard,
  mfaCardsSharedData,
  setMfaCardsSharedData
}: {
  setCurrentCard: (state: MFACardTypes) => void;
  mfaCardsSharedData: LoginFormsInfo;
  setMfaCardsSharedData: (state: LoginFormsInfo) => void;
}) => {
  const { t } = useTranslation('authentication');
  const [notification, setNotification] = useState<
    'codeResent' | 'instruction' | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<MFAErrorCodes | null>(null);
  const [secondsLeftToResendCode, setSecondsLeftToResendCode] = useState(30);
  const cookies = new Cookies();
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const dispatch = useDispatch();
  const [_, setAccountStatus] = useLocalStorageState<AccountStatusState>(
    ACCOUNT_STATUS
  );

  const schema = verificationCodeSchema(t);
  type verificationCodeType = z.infer<typeof schema>;
  const methods = useForm<verificationCodeType>({
    resolver: zodResolver(schema),
    mode: 'onBlur'
  });

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      if (secondsLeftToResendCode <= 30) {
        setSecondsLeftToResendCode(secondsLeftToResendCode - 1);
      }
    }, 1000);

    if (secondsLeftToResendCode === 0) {
      clearInterval(countdownTimer);
      setNotification('instruction');
    }

    return () => {
      clearInterval(countdownTimer);
    };
  }, [secondsLeftToResendCode]);

  const resendCode = async (
    email: string | null,
    username: string | null,
    verificationSid: string | null
  ) => {
    try {
      setErrorCode(null);
      const resendVerification = await fetch('/api/auth/resend-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          username,
          verificationSid
        })
      });

      const resendVerificationJson = await resendVerification.json();

      if (resendVerificationJson.result.data) {
        setMfaCardsSharedData({
          ...mfaCardsSharedData,
          verificationSid: resendVerificationJson.result.data.verificationSid
        });
        setNotification('codeResent');
        setSecondsLeftToResendCode(30);
        return;
      }

      dispatch({
        type: userSessionActionTypes.LOGIN_FAILED
      });

      if (
        resendVerificationJson.result.errors[0].code ===
        MFAErrorCodes.MAX_SEND_ATTEMPTS_REACHED
      ) {
        // Sets the local storage account status to 'blocked' and set the TTL to 10 min
        // Twilio will block the account for 10 minutes and we keep track of this time to show the account temporary blocked card
        setAccountStatus({
          status: AccountStatus.BLOCKED,
          TTL: (resendVerificationJson.result.errors[0] as TwilioDonnaError)
            .verificationTTL
        });
        setCurrentCard(MFACardTypes.ACCOUNT_TEMP_BLOCKED_CARD);
        return;
      }

      if (
        resendVerificationJson.result.errors[0].code ===
        MFAErrorCodes.VERIFICATION_NOT_FOUND
      ) {
        setCurrentCard(MFACardTypes.CODE_EXPIRED_CARD);
        return;
      }

      setErrorCode(
        resendVerificationJson.result.errors.length
          ? resendVerificationJson.result.errors[0].code
          : MFAErrorCodes.GENERIC_ERROR
      );
      return;
    } catch (e) {
      clientSide.error(
        'Failed to call /auth/api/resend-code during MFA; unknown error',
        toLoggerError(e)
      );
      setErrorCode(MFAErrorCodes.GENERIC_ERROR);
    }
  };

  const onValid = async (data: verificationCodeType) => {
    try {
      setLoading(true);

      const verificationCodeRes = await fetch('/api/auth/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${cookies.get('sri')}`
        },
        body: JSON.stringify({
          verificationCode: data.verificationCode,
          username: mfaCardsSharedData.username,
          verificationSid: mfaCardsSharedData.verificationSid,
          deviceProfile: mfaCardsSharedData.deviceProfile
        })
      });

      cookies.remove('sri');
      const verificationCodeResJson = await verificationCodeRes.json();
      setLoading(false);

      if (verificationCodeResJson.result.data) {
        await handleLoginSuccess({
          userProfile: verificationCodeResJson.result.data.userProfile,
          accessToken: verificationCodeResJson.result.data.accessToken,
          shouldShowEmployer: shouldShowEmployer(
            featureFlags,
            verificationCodeResJson.result.data.userProfile
          ),
          dispatch
        });
        return;
      }

      dispatch({
        type: userSessionActionTypes.LOGIN_FAILED
      });

      if (
        verificationCodeResJson.result.errors[0].code ===
        MFAErrorCodes.MAX_CHECK_ATTEMPTS_REACHED
      ) {
        // Sets the local storage account status to 'blocked' and set the TTL to 10 min
        // Twilio will block the account for 10 minutes and we keep track of this time to show the account temporary blocked card
        // When MAX_CHECK_ATTEMPTS_REACHED is true, Twilio makes MAX_SEND_ATTEMPTS_REACHED to be true.
        setAccountStatus({
          status: AccountStatus.BLOCKED,
          TTL: (verificationCodeResJson.result.errors[0] as TwilioDonnaError)
            .verificationTTL
        });
        setCurrentCard(MFACardTypes.ACCOUNT_TEMP_BLOCKED_CARD);
        return;
      }

      if (
        verificationCodeResJson.result.errors[0].code ===
        MFAErrorCodes.VERIFICATION_NOT_FOUND
      ) {
        setCurrentCard(MFACardTypes.CODE_EXPIRED_CARD);
      }

      setErrorCode(
        verificationCodeResJson.result.errors.length
          ? verificationCodeResJson.result.errors[0].code
          : MFAErrorCodes.GENERIC_ERROR
      );

      return;
    } catch (e) {
      clientSide.error(
        'Failed to call /auth/api/verify-code during MFA; unknown error',
        toLoggerError(e)
      );
      dispatch({
        type: userSessionActionTypes.LOGIN_FAILED
      });
      setErrorCode(MFAErrorCodes.GENERIC_ERROR);
    }
  };

  return (
    <MFACard
      id="mfa-verification-card"
      title={t('MFA_PLEASE_VERIFY_YOUR_IDENTITY')}
    >
      <div sx={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
        <Image
          src="/images/v2/smallIcons/email-with-code.svg"
          alt=""
          width={92}
          height={92}
          sx={{
            width: 'unset'
          }}
        />
        <p sx={{ mb: '0' }}>
          {t('MFA_WE_NEED_TO_VERIFY_SENT_CODE_TO_EMAIL')}{' '}
          <span>
            {mfaCardsSharedData.email
              ? maskEmail(mfaCardsSharedData.email.toLowerCase())
              : ''}
          </span>
          .&nbsp;<span>{t('MFA_PLEASE_ENTER_YOUR_CODE')}</span>
        </p>
      </div>

      {notification === 'instruction' && (
        <CalloutNotification
          id="verify-code"
          intent="primary"
          sx={{ mt: '2rem' }}
        >
          <p>
            {t('MFA_HAVE_NOT_RECEIVED_THE_CODE')}{' '}
            <span sx={{ fontWeight: '600', whiteSpace: 'nowrap' }}>
              {t('MFA_RESEND_CODE')}.
            </span>
          </p>
        </CalloutNotification>
      )}

      {notification === 'codeResent' && (
        <CalloutNotification
          id="verify-code"
          intent="success"
          sx={{ mt: '2rem' }}
        >
          <p>{t('MFA_YOUR_VERIFICATION_CODE_RESENT')}</p>
        </CalloutNotification>
      )}
      {errorCode && (
        <CalloutNotification
          id="verify-code"
          intent="danger"
          sx={{ mt: '2rem' }}
        >
          <p>{getNotificationMessage(errorCode)}</p>
        </CalloutNotification>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onValid)}>
          <div
            sx={{
              m: '2rem 0',
              display: 'flex',
              alignItems: methods.formState.errors.verificationCode?.message
                ? 'center'
                : 'end',
              gap: '1rem'
            }}
          >
            <FormTextInput
              name="verificationCode"
              id="verificationCode"
              label={t('MFA_ENTER_CODE')}
              sxFormGroup={{ mb: '0', width: '65%' }}
              fill
              error={
                (methods.formState.errors.verificationCode
                  ?.message as string) ||
                (errorCode === MFAErrorCodes.INVALID_VERIFICATION_CODE
                  ? errorCode
                  : '')
              }
              hideErrorMessage={
                errorCode === MFAErrorCodes.INVALID_VERIFICATION_CODE
              }
              mask="999999"
              onChange={() => methods.clearErrors()}
            />
            <Button
              id="mfa-resend-code"
              variant="simple"
              color="blue"
              sxOverride={{ height: '3rem', width: '35%' }}
              onClick={() => {
                resendCode(
                  mfaCardsSharedData.email,
                  mfaCardsSharedData.username,
                  mfaCardsSharedData.verificationSid
                );
              }}
              disabled={secondsLeftToResendCode !== 0}
            >
              <Icon
                icon={IconNamesSmall.REFRESH_ONE_ARROW_CIRCLE}
                sx={{
                  svg: {
                    width: IconSize.SMALL,
                    height: IconSize.SMALL
                  }
                }}
              />
              {t('MFA_RESEND_CODE')}
            </Button>
          </div>

          <MFASubmitButton id="mfa-verify-button" loading={loading}>
            {loading ? t('MFA_LOGGING_IN_TO_EACCESS_ACCOUNT') : t('MFA_VERIFY')}
          </MFASubmitButton>
        </form>
      </FormProvider>
      <MFACardNavButtons setCurrentCard={setCurrentCard} />
    </MFACard>
  );
};

export default MFACodeVerificationFormCard;
