/* eslint-disable no-param-reassign */
import { EapAction } from 'types';
import {
  Attachments,
  ContactModel,
  BankingInfoModel,
  MemberSearchResult,
  EmploymentPeriod,
  EmploymentModel,
  Form143Model,
  RetroPayModels,
  ChildModel,
  DonnaWarningObject,
  DonnaExceptionsObject
} from 'interfaces';
import { endOfYear, format, isAfter, parse, startOfYear } from 'date-fns';
import { cloneDeep } from 'lodash';
import { FormState } from 'containers/requestForm/common/interfaces';
import clientSide from 'utils/logger/client-side';
import { getSubmissionStatus, getYMDStringsFromDate } from 'utils';
import { mapForm143, mapEmploymentPeriods } from './mapForm143';
import { form143ActionTypes } from './form143Actions';
import { isSwap, swapYears } from './utils/143Utils';

export interface DirtyValues {
  [key: string]: any;
}
export type D = keyof DirtyValues;
export interface Form143State extends FormState {
  validMemberContext: boolean;
  validMemberProfile: any; // TODO
  memberInfo: MemberSearchResult | null;
  form143Data: Form143Data;
  form143Model: Form143Model | null;
  form119Models: Array<EmploymentPeriod>;
  employmentModel: EmploymentModel;
  columnYear: Array<string | null>;
  retroPay: Array<string | null>;
  attachments: Array<Attachments> | null;
  needsAdditionalData: boolean;
  showModal: boolean;
  showLastDateWorkedModal: boolean;
  showLastDateWorkedErrorModal: boolean;
  isDirtyLastDateWorked: boolean;
  bankingInfoModel: BankingInfoModel | null;
  noteToOmers: string;
  saveHasErrors: boolean;
  retroStartDateInStore: string;
  retroEndDateInStore: string;
  retroPayModels: RetroPayModels | null;
  retroTotal: number;
  dirtyValues: DirtyValues;
  swappedModels: boolean;
  prevTotalDisabilityDate: string | null;
  donnaOverrideWarnings: Array<DonnaWarningObject>;
  donnaExceptions: DonnaExceptionsObject | null;
}

export interface Form143Data {
  eventType: string;
  dateLastWorked: string;
  minimumRetirementDate: string;
  reasonForDifference: string;
  reasonForDifferenceOther: string;
  lastDateRegEarnings: string;
  memberFirstName: string;
  memberMiddleName: string;
  memberLastName: string;
  birthDateMonth: string;
  birthDateDay: string;
  birthDateYear: string;
  maritalStatus: string;
  advanceElectionInd: string;
  showType3Supp: 'Y' | 'N';
  type3ProvidedInd: string;
  employmentPeriodsCurrent: Array<EmploymentPeriod>;
  employmentPeriodsPrevious: Array<EmploymentPeriod>;
  carryOver: Array<EmploymentPeriod>;
  eligibleSpouse: 'Y' | 'N' | 'D' | '';
  eligibleChildren: 'Y' | 'N' | 'D' | '';
  noteToOmers: string;
  spouseModel: Array<ContactModel>;
  childModel: Array<ChildModel | ContactModel>;
  percentageTimeWorked: string;
  disabilityStartDate: string;
  disabilityEndDate: string;
  cppDisabilityBenefitInd: string;
  cppDisabilityBenefitStatus: string;
  ltdBenefitInd: string;
  showCPP: string;
  waiverAnnualEarningsAmt: string;
  wsibBenefitInd: string;
  wsibBenefitStatus: string;
  wsibMonthlyAmt: string;
  reasonForAdjustment: string;
  secondaryEmailAddress: string;
}

export const form143Data: Form143Data = {
  eventType: '',
  dateLastWorked: '',
  minimumRetirementDate: '',
  reasonForDifference: '',
  reasonForDifferenceOther: '',
  lastDateRegEarnings: '',
  memberFirstName: '',
  memberMiddleName: '',
  memberLastName: '',
  birthDateMonth: '',
  birthDateDay: '',
  birthDateYear: '',
  maritalStatus: '',
  advanceElectionInd: '',
  showType3Supp: 'N',
  type3ProvidedInd: 'N',
  employmentPeriodsCurrent: [],
  employmentPeriodsPrevious: [],
  carryOver: [],
  eligibleSpouse: '',
  eligibleChildren: '',
  noteToOmers: '',
  spouseModel: [],
  childModel: [],
  percentageTimeWorked: '',
  disabilityStartDate: '',
  disabilityEndDate: '',
  cppDisabilityBenefitInd: '',
  cppDisabilityBenefitStatus: '',
  ltdBenefitInd: '',
  showCPP: '',
  waiverAnnualEarningsAmt: '',
  wsibBenefitInd: '',
  wsibBenefitStatus: '',
  wsibMonthlyAmt: '',
  reasonForAdjustment: '',
  secondaryEmailAddress: ''
};

export const initialState = {
  validMemberContext: false,
  validMemberProfile: {},
  requestNumber: null,
  memberInfo: null,
  initLoading: false,
  formStep: 1,
  form143Data,
  form143Model: null,
  submissionStatus: '' as const,
  formStatus: '' as const,
  form119Models: [],
  employmentModel: {
    assumedDisBenefDate: '',
    attachmentFileName: '',
    dateLastWorked: '',
    dirty: false,
    eventType: '',
    hashMap: {},
    lastDateRegEarnings: '',
    membershipInfo: {
      memberBirthDate: '',
      memberDepartmentId: '',
      memberEmployeeId: '',
      memberEmployerNumber: '',
      memberEmploymentStatus: '',
      memberFirstName: '',
      memberGroupNumber: '',
      memberLastName: '',
      memberMembershipNumber: '',
      memberMiddleName: '',
      memberNRA: '',
      memberReasonForRequest: '',
      memberSalutation: '',
      memberSin: '',
      memberStatus: '',
      otcftNotWorkedFromDate: '',
      otcftNotWorkedToDate: '',
      otcftPct: ''
    },
    mximumNumberOfYears: '',
    otcftNotWorkedFromDate: '',
    otcftNotWorkedToDate: '',
    otcftPct: '',
    postEventInd: '',
    reasonForDifference: '',
    reasonForDifferenceOther: '',
    recurrenceInd: '',
    requestNumber: 0,
    showOTCFT: '',
    totalDisabilityDate: '',
    reasonForAdjustment: '',
    userId: ''
  },
  carryoverModel: {},
  employmentPeriodsCurrent: [],
  employmentPeriodsPrevious: [],
  submissionComplete: false,
  exceptions: [],
  donnaExceptions: null,
  overrideExceptions: [],
  donnaOverrideWarnings: [],
  columnYear: [],
  retroPay: [],
  noteToOmers: '',
  attachments: [],
  needsAdditionalData: false,
  showModal: true,
  showLastDateWorkedModal: false,
  showLastDateWorkedErrorModal: false,
  isDirtyLastDateWorked: false,
  earningsModel: { highestEarningsModels: [] },
  bankingInfoModel: {
    bankAccountNumber: '',
    bankAddress1: '',
    bankAddress2: '',
    bankAddress3: '',
    bankName: '',
    bankNumber: '',
    city: '',
    country: '',
    dirty: false,
    hashMap: {},
    phone: '',
    postal: '',
    province: '',
    transitNumber: ''
  },
  saveHasErrors: false,
  retroEndDateInStore: '',
  retroStartDateInStore: '',
  retroPayModels: {},
  retroTotal: 0,
  pdfs: [],
  dirtyValues: {},
  swappedModels: false,
  prevTotalDisabilityDate: null
};

const form143 = (
  state: Form143State = initialState,
  action: EapAction
): Form143State => {
  const { type, data } = action;
  switch (type) {
    case form143ActionTypes.CHECK_MEMBER_CONTEXT_SUCCEEDED:
      return {
        ...state,
        validMemberContext: data.validMemberContext,
        validMemberProfile: data.validMemberProfile,
        prevTotalDisabilityDate: data.prevTotalDisabilityDate
      };
    case form143ActionTypes.RESET_MEMBER_CONTEXT:
      return {
        ...state,
        validMemberContext: false,
        validMemberProfile: {}
      };
    case form143ActionTypes.SET_FORM_143_REQUEST_NUMBER:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form143ActionTypes.GET_FORM_143_SUCCEEDED:
      return {
        ...state,
        requestNumber: data.requestNumber,
        form143Model: {
          ...data.e143Model,
          financialModel: {
            ...data.e143Model.financialModel,
            employmentPeriodCurrYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodCurrYearModels,
            employmentPeriodPrevYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodPrevYearModels
          }
        },
        form143Data: mapForm143(data.e143Model),
        swappedModels: isSwap(data.e143Model.financialModel),
        columnYear: data.e143Model?.financialModel?.columnYear,
        retroPay: data.e143Model?.financialModel?.retroPay,
        form119Models: mapEmploymentPeriods(
          data.e143Model.financialModel?.form119Models
        ),
        employmentModel: data.e143Model?.employmentModel,
        memberInfo: data.memberInfo,
        initLoading: false,
        formStep: 1,
        bankingInfoModel: data.advanceElectionModel,
        formStatus: '' as const,
        submissionStatus: '' as const,
        submissionComplete: false,
        exceptions: data.e143Model?.exceptions ?? [],
        donnaExceptions: data.e143Model?.donnaExceptions ?? null,
        overrideExceptions: [],
        donnaOverrideWarnings: [],
        retroPayModels: {},
        attachments:
          data.e143Model?.supportingInfoModel?.retirementModel?.attachments
      };
    case form143ActionTypes.STASH_DIRTY_VALUES:
      return {
        ...state,
        dirtyValues: action.data
      };
    case form143ActionTypes.GET_LATEST_FORM_143_SUCCEEDED:
      return {
        ...state,
        form143Data: data.dirtyStateSave
          ? mapForm143({
              ...data.e143Model,
              supportingInfoModel: {
                ...data.e143Model.supportingInfoModel,
                retirementModel: {
                  ...data.e143Model.supportingInfoModel.retirementModel,
                  advanceElectionInd: 'Y'
                }
              }
            })
          : mapForm143(data.e143Model),
        swappedModels: isSwap(data.e143Model.financialModel),
        form143Model: {
          ...data.e143Model,
          financialModel: {
            ...data.e143Model.financialModel,
            employmentPeriodCurrYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodCurrYearModels,
            employmentPeriodPrevYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodPrevYearModels
          }
        },
        columnYear: data.e143Model?.financialModel?.columnYear,
        initLoading: false,
        bankingInfoModel: data.advanceElectionModel,
        noteToOmers: data.e143Model.notes,
        donnaExceptions: data.e143Model?.donnaExceptions ?? null
      };
    case form143ActionTypes.GET_FORM_143_FAILED:
      return {
        ...state,
        initLoading: true
      };
    case form143ActionTypes.SET_FORM_143_STEP:
      return {
        ...state,
        formStep: data
      };
    case form143ActionTypes.SET_FORM_143_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form143ActionTypes.SET_MINIMUM_RETIRMENT_DATE:
      return {
        ...state,
        form143Data: {
          ...state.form143Data,
          minimumRetirementDate: data
        }
      };
    case form143ActionTypes.SHOW_LAST_DATE_WORKED_ERROR_MODAL:
      return {
        ...state,
        showLastDateWorkedErrorModal: data
      };
    case form143ActionTypes.SET_IS_DIRTY_LAST_DATE_WORKED:
      return {
        ...state,
        isDirtyLastDateWorked: action.data
      };
    case form143ActionTypes.RESET_FINANCIAL_INFO:
      return {
        ...state,
        form143Data: {
          ...state.form143Data,
          employmentPeriodsCurrent: state.form143Data.employmentPeriodsCurrent.map(
            period => ({ ...period, pensionAdjustment: '' })
          ),
          employmentPeriodsPrevious: state.form143Data.employmentPeriodsPrevious.map(
            period => ({ ...period, pensionAdjustment: '' })
          )
        }
      };
    case form143ActionTypes.UPDATE_FORM_143_FINANCIAL_INFO:
      return {
        ...state,
        form143Data: {
          ...state.form143Data,
          employmentPeriodsCurrent: mapEmploymentPeriods(
            swapYears(data.e143Model.financialModel)
              .employmentPeriodCurrYearModels
          ),
          employmentPeriodsPrevious: mapEmploymentPeriods(
            swapYears(data.e143Model.financialModel)
              .employmentPeriodPrevYearModels
          ),
          carryOver: mapEmploymentPeriods([
            data.e143Model.financialModel.carryOverModel
          ])
        },
        form143Model: {
          ...data.e143Model,
          financialModel: {
            ...data.e143Model.financialModel,
            employmentPeriodCurrYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodCurrYearModels,
            employmentPeriodPrevYearModels: swapYears(
              data?.e143Model?.financialModel
            ).employmentPeriodPrevYearModels
          }
        },
        employmentModel: data.e143Model?.employmentModel,
        columnYear: data.e143Model?.financialModel?.columnYear,
        bankingInfoModel: data.advanceElectionModel
      };
    case form143ActionTypes.UPDATE_FORM_143_LAST_DATE_WORKED: {
      const form143Model = cloneDeep(state.form143Model);
      if (form143Model === null) {
        clientSide.warn(
          '143 Adjustment last date worked not updated - form143Model is null'
        );
        return { ...state };
      }
      const columnYear = cloneDeep(state.columnYear);
      const initialDateLastWorked =
        form143Model?.employmentModel.dateLastWorked;
      const parsedDateLastWorked = getYMDStringsFromDate(data.dateLastWorked);
      const parsedInitialDateLastWorked = getYMDStringsFromDate(
        initialDateLastWorked
      );

      enum mappingYears {
        carryOver = 0,
        employmentPeriodsCurrent = 1,
        employmentPeriodsPrevious = 2,
        form119Models = 3
      }

      const isOldLastDateWorkedInCurrentPeriod =
        parsedInitialDateLastWorked.year ===
        columnYear[mappingYears.employmentPeriodsCurrent];
      const isOldLastDateWorkedInPreviousPeriod =
        parsedInitialDateLastWorked.year ===
        columnYear[mappingYears.employmentPeriodsPrevious];
      const isNewLastDateWorkedInCurrentPeriod =
        parsedDateLastWorked.year ===
          columnYear[mappingYears.employmentPeriodsCurrent] ||
        parsedDateLastWorked.year ===
          (
            parseInt(
              columnYear[mappingYears.employmentPeriodsPrevious] as string,
              10
            ) + 1
          ).toString();
      const isNewLastDateWorkedInPreviousPeriod =
        parsedDateLastWorked.year ===
        columnYear[mappingYears.employmentPeriodsPrevious];

      const form119Year = columnYear[mappingYears.form119Models];
      const isLastDateWorkedAfterForm119Period = isAfter(
        parse(data.dateLastWorked, 'yyyy-MM-dd', new Date()),
        new Date(form119Year ? parseInt(form119Year, 10) : 0, 11, 31)
      );

      if (!isLastDateWorkedAfterForm119Period) {
        columnYear[mappingYears.employmentPeriodsCurrent] = null;
        columnYear[mappingYears.employmentPeriodsPrevious] = null;
        form143Model.financialModel.employmentPeriodCurrYearModels = [];
        form143Model.financialModel.employmentPeriodPrevYearModels = [];
      } else if (
        isNewLastDateWorkedInCurrentPeriod &&
        isOldLastDateWorkedInCurrentPeriod &&
        form143Model.financialModel.employmentPeriodCurrYearModels
      ) {
        form143Model.financialModel.employmentPeriodCurrYearModels[0].hashMap.end_date =
          data.dateLastWorked;
      } else if (
        isNewLastDateWorkedInPreviousPeriod &&
        isOldLastDateWorkedInPreviousPeriod &&
        form143Model.financialModel.employmentPeriodPrevYearModels
      ) {
        form143Model.financialModel.employmentPeriodPrevYearModels[0].hashMap.end_date =
          data.dateLastWorked;
      } else if (
        isNewLastDateWorkedInPreviousPeriod &&
        isOldLastDateWorkedInCurrentPeriod
      ) {
        form143Model.financialModel.employmentPeriodCurrYearModels = [];
        columnYear[mappingYears.employmentPeriodsCurrent] = null;
        if (form143Model.financialModel.employmentPeriodPrevYearModels) {
          form143Model.financialModel.employmentPeriodPrevYearModels[0].hashMap.end_date =
            data.dateLastWorked;
        }
        if (form143Model.financialModel.form119Models) {
          form143Model.financialModel.form119Models[0].hashMap.service_period_no = 3;
        }
      } else if (
        isOldLastDateWorkedInPreviousPeriod &&
        isNewLastDateWorkedInCurrentPeriod
      ) {
        columnYear[mappingYears.employmentPeriodsCurrent] =
          parsedDateLastWorked.year;
        if (form143Model.financialModel.employmentPeriodPrevYearModels) {
          form143Model.financialModel.employmentPeriodPrevYearModels[0].hashMap.end_date = format(
            endOfYear(parse(initialDateLastWorked, 'yyyy-MM-dd', new Date())),
            'yyyy-MM-dd'
          );
        }
        if (form143Model.financialModel.employmentPeriodCurrYearModels) {
          form143Model.financialModel.employmentPeriodCurrYearModels[0].hashMap.end_date =
            data.dateLastWorked;
        } else {
          form143Model.financialModel.employmentPeriodCurrYearModels = cloneDeep(
            form143Model.financialModel.employmentPeriodPrevYearModels
          );
          form143Model.financialModel.employmentPeriodCurrYearModels[0].hashMap = {
            ...form143Model.financialModel.employmentPeriodCurrYearModels[0]
              .hashMap,
            end_date: data.dateLastWorked,
            start_date: format(
              startOfYear(parse(data.dateLastWorked, 'yyyy-MM-dd', new Date())),
              'yyyy-MM-dd'
            ),
            earnings: 0,
            pension_adjustment_amt: 0,
            rpp_contribution_amt: 0,
            rca_contribution_amt: 0,
            service_period_no: 3,
            credited_service: 0,
            retro_total: 0,
            retro_start_date: '',
            retro_end_date: '',
            retro_type: ''
          };
          if (form143Model.financialModel.form119Models) {
            form143Model.financialModel.form119Models[0].hashMap.service_period_no = 4;
          }
        }
      }
      form143Model.financialModel.eventDate = data.dateLastWorked;
      form143Model.employmentModel = {
        ...state.form143Model?.employmentModel,
        ...data
      };
      form143Model.financialModel.columnYear = [...columnYear];

      return {
        ...state,
        employmentModel: { ...state.employmentModel, ...data },
        form143Data: {
          ...state.form143Data,
          employmentPeriodsCurrent: mapEmploymentPeriods(
            swapYears(form143Model.financialModel)
              .employmentPeriodCurrYearModels
          ),
          employmentPeriodsPrevious: mapEmploymentPeriods(
            swapYears(form143Model.financialModel)
              .employmentPeriodPrevYearModels
          ),
          ...data
        },
        columnYear,
        form143Model
      };
    }
    case form143ActionTypes.SET_BANKING_INFORMATION_SUCCEEDED:
      return {
        ...state,
        bankingInfoModel: data.advanceElectionModel
      };
    case form143ActionTypes.SET_BANKING_PDF:
      return {
        ...state,
        attachments: data
      };
    case form143ActionTypes.SET_BANKING_INFORMATION_NEEDED:
      return {
        ...state,
        needsAdditionalData: data
      };
    case form143ActionTypes.SET_BANKING_INFORMATION_SHOW_MODAL:
      return {
        ...state,
        showModal: data
      };
    case form143ActionTypes.DELETE_BANKING_INFORMATION_PDF:
      return {
        ...state,
        bankingInfoModel: initialState.bankingInfoModel,
        attachments: initialState.attachments
      };
    case form143ActionTypes.SHOW_LAST_DATE_WORKED_MODAL:
      return {
        ...state,
        showLastDateWorkedModal: data
      };
    case form143ActionTypes.SAVE_FORM_143_REQUESTED:
    case form143ActionTypes.SAVE_AND_REVIEW_FORM_143_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form143ActionTypes.SAVE_FORM_143_SUCCESS:
      return {
        ...state,
        dirtyValues: {},
        isDirtyLastDateWorked: false,
        formStatus: 'success' as const
      };
    case form143ActionTypes.SAVE_FORM_143_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form143ActionTypes.CLEAR_FORM_143_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form143ActionTypes.OVERRIDE_FORM_143_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form143ActionTypes.OVERRIDE_FORM_143_DONNA_WARNINGS:
      return {
        ...state,
        donnaOverrideWarnings: data
      };
    case form143ActionTypes.SET_FORM_143_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form143ActionTypes.SUBMIT_FORM_143_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        donnaExceptions: !data.donnaExceptions ? {} : data.donnaExceptions,
        overrideExceptions: [],
        donnaOverrideWarnings: [],
        submissionStatus: getSubmissionStatus(
          data,
          data.exceptions,
          data.donnaExceptions
        ),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions, data.donnaExceptions) ===
          'success',
        formStep:
          getSubmissionStatus(data, data.exceptions, data.donnaExceptions) ===
          'success'
            ? 3
            : 2,
        pdfs: data.attachments,
        initLoading: true
      };
    case form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_FAILED:
    case form143ActionTypes.VALID_MEMBER_RESET_143:
      return {
        ...state,
        validMemberProfile: {}
      };
    case form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_SUCCEEDED:
      return {
        ...state,
        validMemberProfile: data
      };
    case form143ActionTypes.DELETE_FORM_143:
      return {
        ...state,
        formStatus: 'deleted',
        requestNumber: null,
        memberInfo: null
      };
    case form143ActionTypes.GET_RETRO_PAY_143_SUCCESS: {
      return {
        ...state,
        retroPayModels: {
          ...state.retroPayModels,
          [data.servicePeriodNo]: data.retroPayModels
        }
      };
    }
    case form143ActionTypes.CLEAR_DONNA_EXCEPTIONS_143: {
      return {
        ...state,
        donnaExceptions: null
      };
    }
    default:
      return state;
  }
};

export default form143;
