import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import MFACard from 'containers/auth/LoginContainer/MFA/components/MFACard';
import MFACardNavButtons from 'containers/auth/LoginContainer/MFA/components/MFACustomButtons';
import {
  LoginFormsInfo,
  MFACardTypes
} from 'containers/auth/LoginContainer/MFA/MFACards';
import { maskEmail } from './utils/client-utils';

const MFACodeExpiredCard = ({
  setCurrentCard,
  mfaCardsSharedData
}: {
  setCurrentCard: (state: MFACardTypes) => void;
  mfaCardsSharedData: LoginFormsInfo;
}) => {
  const { t } = useTranslation('authentication');

  return (
    <MFACard id="mfa-code-expired" title={t('MFA_YOUR_CODE_HAS_EXPIRED')}>
      <div sx={{ display: 'flex', alignItems: 'start', gap: '1.5rem' }}>
        <Image
          src="/images/v2/smallIcons/hourglass-expired.svg"
          alt=""
          width={92}
          height={92}
          sx={{
            width: 'unset',
            marginBottom: '2rem'
          }}
        />
        <p sx={{ mb: '0' }}>
          {t('MFA_WE_NEED_TO_VERIFY_SENT_CODE_TO_EMAIL')}{' '}
          <span>
            {mfaCardsSharedData.email
              ? maskEmail(mfaCardsSharedData.email.toLowerCase())
              : ''}
          </span>{' '}
          <span>
            {t('MFA_EXPIRED_AFTER_TEN_MINUTES')}{' '}
            {t('MFA_PLEASE_CLICK_BACK_TO_LOGIN')}
          </span>
        </p>
      </div>

      <MFACardNavButtons setCurrentCard={setCurrentCard} />
    </MFACard>
  );
};

export default MFACodeExpiredCard;
