import { useRouter } from 'next/router';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import * as yup from 'yup';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import {
  IconNamesSmall,
  IconNamesStatus
} from 'components/v2/atomic/icon/Icons';
import { EXTERNAL_URLS, PUBLIC_SITE_URLS } from 'utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { EmployerGroup, RootState } from 'types';
import { useEmployerQuarterly } from 'containers/auth/LoginContainer/EmployerQuarterly';
import { find, groupBy, keyBy, lowerCase, map, startCase } from 'lodash';
import Icon, { IconSize } from 'components/v2/atomic/icon/Icon';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { tokenSelector } from 'utils';
import { showTerms } from 'utils/cobrowse';
import useRole, { RoleTypes } from 'utils/hooks/useRole';
import {
  selectEmployerDropdownAction,
  showAccountLockedModalAction
} from 'containers/selectEmployers/selectEmployersActions';
import {
  logoutAction,
  logoutForcedAction,
  eCorrespondenceOptOutAction
} from 'containers/auth/userSessionActions';
import { useTranslation } from 'react-i18next';
import { setIsOpen } from 'components/microsoftBookingsDrawer/microsoftBookingsDrawerReducer';
import Modal from 'components/v2/atomic/modal/Modal';
import { useEffect, useState } from 'react';
import ToggleSwitch from 'components/v2/atomic/toggleSwitch/ToggleSwitch';
import Tooltip from 'components/v2/atomic/tooltip/Tooltip';
import { MicrosoftBookingsDrawer } from 'components/microsoftBookingsDrawer/MicrosoftBookingsDrawer';
import { UserExpiryTimer } from 'containers/userExpiryTimer/UserExpiryTimer';
import Spinner, { SpinnerSize } from 'components/v2/atomic/spinner/Spinner';
import Drawer from 'components/v2/atomic/drawer/Drawer';
import Button from 'components/v2/atomic/button/Button';
import { BaseStyles } from 'theme-ui';
import { FormProvider, useForm } from 'react-hook-form';
import FormRadioButtonGroup from 'components/v2/atomic/radioButtonGroup/FormRadioButtonGroup';
import RadioButton from 'components/v2/atomic/radioButton/RadioButton';
import { Classes } from '@blueprintjs/core';
import MainNav, { NavMenuItem } from '../mainNav/MainNav';
import { BurgerMenuItemType } from '../mainNav/BurgerMenu';

const { yupResolver } = require('@hookform/resolvers/yup');

const SelectEmployerDrawer = ({
  displayGroupMap,
  isSelectEmployerDrawerOpen,
  setIsSelectEmployerDrawerOpen
}: {
  displayGroupMap: Record<string, EmployerGroup>;
  isSelectEmployerDrawerOpen: boolean;
  setIsSelectEmployerDrawerOpen: (value: boolean) => void;
}) => {
  const { t } = useTranslation('header');
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const { selectEmployerNumber } = useSelector(
    (state: RootState) => state.selectEmployers
  );

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        selectEmployerGroup: yup
          .string()
          .required(t('validations:VALIDATION_PLEASE_SELECT_EMPLOYER_GROUP'))
          .nullable()
      })
    ),
    defaultValues: { selectEmployerGroup: '' }
  });

  const selectEmployerGroup: string = methods.watch('selectEmployerGroup');

  return (
    <Drawer
      isOpen={isSelectEmployerDrawerOpen}
      onClose={() => setIsSelectEmployerDrawerOpen(false)}
      isActionButtonsEnabled={false}
      canEscapeKeyClose
      canOutsideClickClose
      sx={{
        zIndex: 1000
      }}
      childrenSxOverride={{
        p: [0, 0, null]
      }}
      footerAlign={breakpoint.isLessThanSmallMin ? 'fill' : 'right'}
      footer={
        <div
          sx={{
            paddingRight: '1rem',
            display: 'flex',
            columnGap: '1rem',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            id="select-employer-drawer-back-button"
            variant="outline"
            onClick={() => setIsSelectEmployerDrawerOpen(false)}
          >
            {t('BACK')}
          </Button>
          <Button
            id="select-employer-drawer-button"
            type="submit"
            onClick={async () => {
              const isFormValid = await methods.trigger();
              if (!isFormValid) {
                return;
              }
              dispatch(
                selectEmployerDropdownAction({
                  groupNumber: selectEmployerGroup
                })
              );
              setIsSelectEmployerDrawerOpen(false);
            }}
          >
            <Icon icon={IconNamesSmall.SWAP_VERTICAL} />
            {t('SWITCH_GROUP')}
          </Button>
        </div>
      }
      isFooterSticky
    >
      <BaseStyles>
        <FormProvider {...methods}>
          <FormRadioButtonGroup
            id="select-employer-radio-group"
            name="selectEmployerGroup"
            selectedValue={selectEmployerGroup ?? ''}
            onChange={(e: any) => {
              methods.clearErrors('selectEmployerGroup');
              methods.setValue('selectEmployerGroup', e.target.value);
            }}
            error={methods.formState.errors?.selectEmployerGroup?.message}
            sxFormGroup={{
              [`&.${Classes.FORM_GROUP} .${Classes.FORM_CONTENT}`]: {
                [`.${Classes.RADIO}.${Classes.CONTROL}`]: {
                  margin: 0,
                  paddingLeft: '3rem',
                  paddingY: '1.5rem',
                  borderBottom: '1px solid',
                  borderBottomColor: 'grey40'
                },
                [`.${Classes.RADIO}.${Classes.CONTROL}:first-of-type`]: {
                  borderTop: '1px solid',
                  borderTopColor: 'grey40'
                },
                [`.${Classes.FORM_HELPER_TEXT}`]: {
                  paddingLeft: '1.5rem'
                }
              }
            }}
          >
            {Object.values(displayGroupMap)
              .filter(group => group.employerNumber !== selectEmployerNumber)
              .map(group => (
                <RadioButton
                  id={group.groupNumber}
                  value={group.groupNumber}
                  sx={{
                    marginTop: 0,
                    '&:hover': {
                      backgroundColor: 'extendedBlue25'
                    }
                  }}
                >
                  <>
                    <div
                      sx={{
                        display: 'inline-block',
                        width: `calc(100% - 3rem )`,
                        fontWeight: 'semibold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {startCase(lowerCase(group.name))}
                    </div>
                    <div sx={{ paddingLeft: '0.5rem' }}>
                      {group.groupNumber}
                    </div>
                  </>
                </RadioButton>
              ))}
          </FormRadioButtonGroup>
        </FormProvider>
      </BaseStyles>
    </Drawer>
  );
};

const NavHeader = () => {
  const { t } = useTranslation('header');
  const { slice } = useEmployerQuarterly();
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const Router = useRouter();
  const { isFinancial, isSMO, isNonFinancial } = useRole();
  const {
    isAuthenticated,
    eCorrespondenceOptOut,
    showEmailNotificationSpinner
  } = useSelector((state: RootState) => state.userSession);
  const { omersUser, firstName, lastName } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const {
    selectEmployerName,
    selectEmployerNumber,
    selectGroupNumber,
    groups
  } = useSelector((state: RootState) => state.selectEmployers);
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const token = useSelector(tokenSelector);
  const smoOnlyView =
    isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
    isSMO &&
    !isFinancial &&
    !isNonFinancial;
  const isEmployerBookingsEnabled = isEnabled(
    featureFlags,
    currentFlags.EMPLOYER_BOOKINGS
  );
  const isEmployerEducationWeekEnabled = isEnabled(
    featureFlags,
    currentFlags.CONTENTFUL_EMPLOYER_EDUCATION_WEEK
  );

  const isOnPublicPage =
    Router.pathname.startsWith('/content/') || Router.pathname === '/';
  const isOnSelectEmployersPage = Router.pathname === '/select-employers';
  const isOnHelpPage = Router.pathname === '/help';
  const showSignInButton =
    !isAuthenticated && isOnPublicPage && Router.pathname !== '/';

  const [showContactModal, setShowContactModal] = useState(false);
  const [
    isEmailNotificationsEnabled,
    setIsEmailNotificationsEnabled
  ] = useState(!eCorrespondenceOptOut);
  const [isSelectEmployerDrawerOpen, setIsSelectEmployerDrawerOpen] = useState(
    false
  );

  useEffect(() => {
    setIsEmailNotificationsEnabled(!eCorrespondenceOptOut);
  }, [eCorrespondenceOptOut]);

  const newsNavItem: NavMenuItem = {
    id: 'news_menuitem',
    buttonText: t('NEWS'),
    isDropdown: false,
    url: EXTERNAL_URLS.OMERS_NEWS,
    openInNewTab: true,
    icon: IconNamesSmall.SPEAKER,
    burgerMenuItemType: BurgerMenuItemType.LINK,
    hideItem: isAuthenticated
  };

  const homeLink: NavMenuItem = {
    id: 'home-link-menuitem',
    buttonText: t('HOME'),
    isDropdown: false,
    burgerMenuItemType: BurgerMenuItemType.LINK,
    url: isOnPublicPage ? '/' : '/home',
    icon: IconNamesSmall.HOME
  };

  const employerQuarterlyNavItem: NavMenuItem = {
    id: 'employer-quarterly_menuitem',
    buttonText: t('EMPLOYER_QUARTERLY'),
    isDropdown: false,
    openInNewTab: true,
    url: slice?.fields.ctaLink ?? '',
    icon: IconNamesSmall.DOCUMENT_COPY,
    hideItem: !slice?.fields.ctaLink || isAuthenticated,
    burgerMenuItemType: BurgerMenuItemType.LINK
  };

  const helpLinkMenuItem: NavMenuItem = {
    id: 'help-link-menuitem',
    hideItem: isAuthenticated && !isOnHelpPage,
    buttonText: t('HEADER_LINK_HELP'),
    isDisabled: isOnHelpPage,
    isDropdown: false,
    onClick: () => {
      Router.push('/help');
    }
  };

  const publicTopLinks: NavMenuItem[] = [
    newsNavItem,
    employerQuarterlyNavItem,
    helpLinkMenuItem
  ];

  const filteredGroups = map(
    groupBy(groups, 'groupNumber'),
    group => find(group, { role: RoleTypes.SMO }) || group[0]
  );

  const displayGroupMap: Record<string, EmployerGroup> = keyBy(
    filteredGroups,
    'groupNumber'
  );

  const selectEmployerMenuItem: NavMenuItem = {
    id: 'select-employer-menuitem',
    rightDivider: !!selectEmployerNumber,
    buttonText: (
      <>
        <span sx={{ fontWeight: 'semibold', textAlign: 'left' }}>
          {startCase(lowerCase(selectEmployerName))}
        </span>{' '}
        {selectGroupNumber}
      </>
    ),
    isDropdown: !omersUser && groups.length > 1 && !!selectEmployerNumber,
    isTextOnly: groups.length <= 1 && !omersUser,
    url: omersUser ? '/select-employers' : undefined,
    hideItem:
      isOnSelectEmployersPage || groups.length === 0 || !selectGroupNumber,
    subMenuItems: Object.values(displayGroupMap)
      .filter(group => group.employerNumber !== selectEmployerNumber)
      .map(group => ({
        id: `group-${group.groupNumber}-menuitem`,
        name: (
          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              gap: '3rem'
            }}
          >
            <div>
              <div sx={{ display: 'flex', alignItems: 'center' }}>
                {isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
                  group.attestationRequired &&
                  group.role !== RoleTypes.SMO && (
                    <Icon
                      icon={IconNamesStatus.DANGER}
                      sx={{ marginRight: '1rem', marginTop: '0.1rem' }}
                      data-testid={`danger-icon-group-${group.groupNumber}`}
                    />
                  )}
                {startCase(lowerCase(group.name))}
              </div>
            </div>
            <div>{group.groupNumber}</div>
          </div>
        ),
        onClick: () => {
          if (
            isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
            group.role !== RoleTypes.SMO &&
            group.attestationRequired
          ) {
            dispatch(
              showAccountLockedModalAction({
                showModal: true,
                showModalFor: group.name
              })
            );
            return;
          }

          dispatch(
            selectEmployerDropdownAction({
              name: group.name,
              employerNumber: group.employerNumber,
              groupNumber: group.groupNumber
            })
          );
        }
      })),
    burgerMenuItemType:
      groups.length > 1
        ? BurgerMenuItemType.BUTTON
        : omersUser
        ? BurgerMenuItemType.LINK
        : BurgerMenuItemType.STYLED_TEXT,
    onClick: () => {
      if (groups.length > 1) {
        setIsSelectEmployerDrawerOpen(true);
      }
    },
    icon: IconNamesSmall.NEW_CONSTRUCTION,
    showIconInNav: false
  };

  const newsMenuItem: NavMenuItem = {
    id: 'news-menuitem',
    buttonText: t('NEWS'),
    isDropdown: true,
    hideItem: !isAuthenticated,
    icon: IconNamesSmall.SPEAKER,
    subMenuItems: [
      {
        id: 'omers-news-menuitem',
        name: t('NEWS'),
        url: EXTERNAL_URLS.OMERS_NEWS,
        openInNewTab: true
      },
      {
        id: 'employer-quarterly_menuitem',
        name: t('EMPLOYER_QUARTERLY'),
        openInNewTab: true,
        url: slice?.fields.ctaLink ?? '',
        hideItem: !slice?.fields.ctaLink
      }
    ]
  };

  const helpMenuItem: NavMenuItem = {
    id: 'help-menuitem',
    buttonText: t('HEADER_LINK_HELP'),
    hideItem: isOnSelectEmployersPage || isOnHelpPage || !isAuthenticated,
    icon: IconNamesSmall.QUESTION_CIRCLE_OUTLINE,
    isDropdown: true,
    subMenuItems: [
      {
        id: 'omers-learning-experience-menuitem',
        hideItem: smoOnlyView || omersUser,
        name: t('LEARNING_CENTER_LINK'),
        onClick: () => {
          if (!token) dispatch(logoutForcedAction());
          else
            window.open(
              `${env('NEXT_PUBLIC_PING_AUTH_BASE_URL')}${env(
                'NEXT_PUBLIC_PING_D2L_SSO_URL'
              )}`,
              '_blank'
            );
        }
      },
      {
        id: 'eam-sub-menuitem',
        name: t('HEADER_HELP_DROPDOWN_ADMIN_MANUAL'),
        url: EXTERNAL_URLS.OMERS_EMPLOYER_ADMINISTRATION_MANUAL,
        openInNewTab: true
      },
      {
        id: 'microsoft-bookings-menuitem',
        name: t('HEADER_MICROSOFT_BOOKINGS'),
        onClick: () => {
          dispatch(setIsOpen(true));
        },
        hideItem: !isEmployerBookingsEnabled
      },
      {
        id: 'employer-listing',
        hideItem: !omersUser,
        name: t('EMPLOYER_LISTING'),
        onClick: () => {
          Router.push('/ms-bookings/employer-listing');
        }
      },
      {
        id: 'cobrowse-menuitem',
        name: t('COBROWSE_BUTTON'),
        onClick: () => {
          showTerms(dispatch, t);
        },
        hideItem: !isEnabled(featureFlags, currentFlags.COBROWSE)
      },
      {
        id: 'contact-us-menuitem',
        name: t('HEADER_USER_DROPDOWN_CONTACT_US'),
        onClick: () => {
          setShowContactModal(true);
        }
      }
    ]
  };

  const usernameMenuItem: NavMenuItem = {
    id: 'username-menuitem',
    buttonText: (
      <span sx={{ textAlign: 'left' }}>
        {firstName} {lastName}
      </span>
    ),
    isDropdown: true,
    icon: IconNamesSmall.USER_C_FRAME,
    iconPosition: 'left',
    hideItem: !isAuthenticated,
    showIconInNav: true,
    subMenuItems: [
      {
        id: 'email-notifications-menuitem',
        name: (
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '2rem',
              marginY: '-0.45rem'
            }}
          >
            <div sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <span sx={{ marginTop: '-0.25rem' }}>
                {t('HEADER_USER_DROPDOWN_EMAIL_NOTIFICATIONS')}{' '}
              </span>
              <Tooltip
                enforceFocus={false}
                content={t('HEADER_USER_DROPDOWN_EMAIL_NOTIFICATIONS_MODAL')}
              />
            </div>

            {showEmailNotificationSpinner ? (
              <Spinner
                size={SpinnerSize.SMALL}
                isFullScreen={false}
                sxOverrides={{ width: 'fit-content', padding: '0.6rem' }}
                backgroundColor="transparent"
                id="email-notifications-spinner"
              />
            ) : (
              <ToggleSwitch
                id="email-notifications-toggle"
                sxFormGroup={{ marginBottom: 0 }}
                checked={isEmailNotificationsEnabled}
                onChange={() => {
                  dispatch(
                    eCorrespondenceOptOutAction(isEmailNotificationsEnabled)
                  );
                }}
              />
            )}
          </div>
        ),
        hideItem: omersUser || !(isFinancial || isSMO),
        sxOverride: {
          borderBottom: '1px solid',
          borderColor: 'grey60',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0
        },
        onClick: () => {
          dispatch(eCorrespondenceOptOutAction(isEmailNotificationsEnabled));
        }
      },
      {
        id: 'user-list-menuitem',
        name: t('HEADER_USER_DROPDOWN_USER_LIST'),
        hideItem: !(
          isSMO && isEnabled(featureFlags, currentFlags.SMO_ATTESTATION)
        ),

        onClick: () => {
          Router.push('/smo/review-user-list');
        }
      },
      {
        id: 'security-menuitem',
        name: t('HEADER_USER_DROPDOWN_SECURITY'),
        onClick: () => {
          Router.push('/security');
        }
      },
      {
        id: 'privacy-menuitem',
        name: t('HEADER_USER_DROPDOWN_PRIVACY'),
        url: EXTERNAL_URLS.OMERS_PRIVACY_STATEMENT,
        openInNewTab: true
      },
      {
        id: 'logout-menuitem',
        name: t('HEADER_USER_DROPDOWN_CHANGE_LOGOUT'),
        onClick: () => {
          dispatch(logoutAction({ logoutNotification: 'success' }));
        }
      }
    ]
  };

  const userExpiryTimerMenuItem: NavMenuItem = {
    id: 'user-expiry-timer-menuitem',
    buttonText: <UserExpiryTimer />,
    hideItem: !isEnabled(featureFlags, currentFlags.SESSION_TIMER),
    isDropdown: false
  };

  const securedTopLinks: NavMenuItem[] = [
    selectEmployerMenuItem,
    { ...newsMenuItem, hideItem: breakpoint.isLessThanSMediumMin },
    { ...helpMenuItem, hideItem: breakpoint.isLessThanSMediumMin },
    helpLinkMenuItem,
    usernameMenuItem,
    userExpiryTimerMenuItem
  ];

  const mainNavLinks: NavMenuItem[] = [
    {
      id: 'administering-the-plan_menuitem',
      buttonText: 'Administering the Plan',
      isDropdown: true,
      icon: IconNamesSmall.TODO,
      subMenuItems: [
        {
          id: 'offering-omers-membership_menuitem',
          name: 'Offering OMERS Membership',
          openInNewTab: !isOnPublicPage,
          url: PUBLIC_SITE_URLS.OFFERING_OMERS_MEMBERSHIP
        },
        {
          id: 'supplemental-plan_menuitem',
          name: 'Supplemental Plan',
          openInNewTab: !isOnPublicPage,
          url: PUBLIC_SITE_URLS.SUPPLEMENTAL_PLAN
        },
        {
          id: 'avc-payroll-deductions_menuitem',
          name: 'AVC Payroll Deductions',
          openInNewTab: !isOnPublicPage,
          url: PUBLIC_SITE_URLS.AVC_PAYROLL_DEDUCTIONS
        }
      ]
    },
    {
      id: 'get-training_menuitem',
      buttonText: 'Get Training',
      isDropdown: false,
      icon: IconNamesSmall.PRESENTATION,
      openInNewTab: !isOnPublicPage,
      burgerMenuItemType: BurgerMenuItemType.LINK,
      url: PUBLIC_SITE_URLS.GET_TRAINING
    },
    {
      id: 'resources_menuitem',
      buttonText: 'Resources',
      isDropdown: true,
      icon: IconNamesSmall.ROAD_SIGN_RIGHT,
      subMenuItems: [
        {
          id: 'employer-forms_menuitem',
          name: 'Employer Forms',
          url: PUBLIC_SITE_URLS.EMPLOYER_FORMS,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'template-spreadsheets_menuitem',
          name: 'Template Spreadsheets',
          url: PUBLIC_SITE_URLS.TEMPLATE_SPREADSHEETS,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'contributions-and-pa-calculators_menuitem',
          name: 'Contributions and PA Calculators',
          url: PUBLIC_SITE_URLS.CONTRIBUTIONS_AND_PA_CALCULATORS,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'list-of-unions-and-associations_menuitem',
          name: 'List of Unions and Associations',
          url: PUBLIC_SITE_URLS.LIST_OF_UNIONS_AND_ASSOCIATIONS,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'employer-administration-manual_menuitem',
          name: 'Employer Administration Manual',
          url: PUBLIC_SITE_URLS.EMPLOYER_ADMINISTRATION_MANUAL,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'employer-toolkit_menuitem',
          name: 'Employer Toolkit',
          url: PUBLIC_SITE_URLS.EMPLOYER_TOOLKIT,
          openInNewTab: !isOnPublicPage,
          hideItem: !isEnabled(
            featureFlags,
            currentFlags.CONTENTFUL_EMPLOYER_TOOLKIT
          )
        }
      ]
    },
    {
      id: 'request-forms_menuitem',
      buttonText: 'Request Forms',
      isDropdown: true,
      icon: IconNamesSmall.DOCUMENT_COPY,
      subMenuItems: [
        {
          id: 'employer-training_menuitem',
          name: 'Request Training',
          url: PUBLIC_SITE_URLS.REQUEST_TRAINING,
          openInNewTab: !isOnPublicPage
        },
        {
          id: 'print-materials_menuitem',
          name: 'Print Materials',
          url: PUBLIC_SITE_URLS.REQUEST_FOR_PRINT_MATERIALS,
          openInNewTab: !isOnPublicPage
        }
      ]
    },
    {
      id: 'employer-education-week_menuitem',
      buttonText: 'Employer Education Week',
      isDropdown: false,
      icon: IconNamesSmall.BOOK,
      url: PUBLIC_SITE_URLS.EMPLOYER_EDUCATION_WEEK,
      openInNewTab: !isOnPublicPage,
      hideItem: !isEmployerEducationWeekEnabled,
      burgerMenuItemType: BurgerMenuItemType.LINK
    }
  ];

  return (
    <>
      {isEmployerBookingsEnabled && <MicrosoftBookingsDrawer />}

      <MainNav
        showSignInButton={showSignInButton}
        showSignOutButton={isAuthenticated}
        topLinks={
          isAuthenticated
            ? securedTopLinks.filter(item => !item.hideItem)
            : publicTopLinks.filter(item => !item.hideItem)
        }
        menuItems={mainNavLinks.filter(item => !item.hideItem)}
        burgerMenuItems={[
          {
            id: 'burger-menu-logged-in-text',
            buttonText: (
              <BaseStyles>
                <p sx={{ paddingLeft: '2rem' }}>{t('YOU_HAVE_SIGNED_IN_TO')}</p>
              </BaseStyles>
            ),
            isDropdown: false,
            burgerMenuItemType: BurgerMenuItemType.PLAIN_TEXT,
            hideItem:
              !isAuthenticated || isOnSelectEmployersPage || isOnHelpPage
          },
          usernameMenuItem,
          {
            ...selectEmployerMenuItem,
            buttonText: (
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginRight: '-0.35rem'
                }}
              >
                <div sx={{ textAlign: 'left' }}>
                  <span sx={{ fontWeight: 'semibold' }}>
                    {startCase(lowerCase(selectEmployerName))}
                  </span>{' '}
                  {selectGroupNumber}
                </div>
                {groups.length > 1 && !omersUser && (
                  <Icon
                    icon={IconNamesSmall.ARROW_RIGHT}
                    size={IconSize.REGULAR}
                  />
                )}
              </div>
            ),
            hideItem: !selectGroupNumber
          },
          {
            id: 'burger-menu-divider',
            buttonText: (
              <div sx={{ backgroundColor: 'grey40', height: '1px' }} />
            ),
            isDropdown: false,
            hideItem:
              !isAuthenticated || isOnSelectEmployersPage || isOnHelpPage
          },
          homeLink,
          newsMenuItem,
          newsNavItem,
          employerQuarterlyNavItem,
          helpMenuItem
        ].filter(item => !item.hideItem)}
        includeWrapper={isOnPublicPage}
        stickyTopBar={!isOnPublicPage}
      />

      <Modal
        id="contact-us-modal"
        isOpen={showContactModal}
        titleText={t('HEADER_USER_DROPDOWN_CONTACT_US')}
        onClose={() => setShowContactModal(false)}
      >
        <p>
          {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_1')}
          <span className="semiBold">
            {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_2')}
          </span>
          {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_3')}
          <span className="semiBold">
            {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_4')}
          </span>
        </p>
      </Modal>

      <SelectEmployerDrawer
        displayGroupMap={displayGroupMap}
        isSelectEmployerDrawerOpen={isSelectEmployerDrawerOpen}
        setIsSelectEmployerDrawerOpen={setIsSelectEmployerDrawerOpen}
      />
    </>
  );
};

export default NavHeader;
