import {
  all,
  takeEvery,
  takeLatest,
  takeLeading,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import Cookies from 'universal-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { toLoggerError } from 'utils/api';
import { safeEffect, safeAuthEffect } from 'store/sagas/sagaUtils';
import {
  login,
  logout,
  setEmailOptoutStatus,
  getFlowId,
  getAuthorizationCode,
  getSysDate,
  refreshSession,
  SessionRefreshSuccess,
  SessionRefreshResponse
} from 'services';
import {
  setAccessToken,
  deleteCookies,
  isAuthenticated,
  featureFlagSelector,
  tokenSelector
} from 'utils';
import { EapAction, SysDateObj } from 'types';
import { propertiesActionTypes } from 'containers/properties/propertiesActions';
import {
  notificationActionTypes,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import { selectEmployerAction } from 'containers/selectEmployers/selectEmployersActions';
import { setIdleAction } from 'containers/routeConfirm/idleActionActions';
import { getFeatureFlagsAction } from 'features/featureFlags/featureFlagsActions';
import {
  getApiErrorMessage,
  getApiErrorMessageByCode,
  getTokenExpiry
} from 'utils/getters';
import { RootState } from 'store/reducers';
import { getEcorrTopicsAction } from 'containers/messages/messagesActions';
import { nanoid } from 'nanoid';
import clientSide from 'utils/logger/client-side';
import { addMinutes } from 'date-fns';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { getDonnaBusinessConfig } from 'containers/donnaBusinessConfig/donnaBusinessConfigReducer';
import { FeatureInterface } from 'unleash-client/lib/feature';
import { AuthLoginResponse } from 'pages/api/auth/login.api';
import { JWTUser, PingCredentialErrors } from 'utils/ping/types';
import { AuthExtendSessionResponse } from 'pages/api/auth/session-extend.api';
import { AuthLoginInternalResponse } from 'pages/api/auth/login-internal.api';
import {
  logoutAction,
  clearLoadingAction,
  userSessionActionTypes,
  setShowEmailNotificationSpinnerAction,
  setShowEmailNotificationRowAction,
  setPasswordExpiredFlagAction,
  setUsernameForResetExpiredPasswordAction,
  setSysDateAction,
  showSessionRefreshModal,
  logoutForcedAction,
  hideSessionRefreshModal,
  setSessionExpiry,
  setTokenExpiryTimerVisibility,
  loginAction,
  sessionRefreshedAction,
  internalUserLoginAction,
  loginFailedAction,
  loginSucceededAction
} from './userSessionActions';
import { tokenExpiryTimerSelector } from './userSessionSelectors';

const NEXT_PUBLIC_PING_AUTH_EXTERNAL_CLIENT_ID = env(
  'NEXT_PUBLIC_PING_AUTH_EXTERNAL_CLIENT_ID'
) as string;

export const cookies = new Cookies();

export const shouldShowEmployer = (
  featureFlags: FeatureInterface[],
  userData: JWTUser
) =>
  isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
  userData.groups.length === 1 &&
  !userData.omersUser &&
  userData.groups[0].attestationRequired &&
  userData.groups[0].role !== 3;

export function* loginNewSaga({ data }: ReturnType<typeof loginAction>) {
  const loginRes: Response = yield call(fetch, '/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  const loginJson: AuthLoginResponse = yield loginRes.json();

  // Credential errors (invalid username/password, locked account, disabled account)
  // loginJson.result.errors returns only one error
  if (loginJson.result.errors?.length) {
    if (
      loginJson.result.errors[0].code ===
      PingCredentialErrors.MUST_CHANGE_PASSWORD
    ) {
      yield put(setPasswordExpiredFlagAction(true));
      yield put(setUsernameForResetExpiredPasswordAction(data.username));
      Router.push('/update-password');
      yield put(clearLoadingAction());
      return;
    }

    yield put(loginFailedAction());

    yield put(
      notifyErrorAction(
        getApiErrorMessageByCode(loginJson.result.errors[0].code)
      )
    );

    return;
  }

  // successful login flow
  if (loginJson.result.data) {
    const { sri, accessToken, userProfile } = loginJson.result.data;

    datadogRum.setUser({
      id: userProfile.username,
      email: userProfile.email
    });

    yield put(loginSucceededAction({ sri, accessToken, userProfile }));
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });

    // retrieve e-access system date to sync with backend date
    const sysDate: { data: { sysDate: SysDateObj } } = yield call(getSysDate);

    if (sysDate?.data) {
      yield put(setSysDateAction(sysDate.data));
    }

    const {
      featureFlags
    }: ReturnType<typeof featureFlagSelector> = yield select(
      featureFlagSelector
    );

    // route user to home page and set employer if only one group option and attestation is complete
    // route user to select employer page if attestation is required and user is non-smo
    if (shouldShowEmployer(featureFlags, userProfile)) {
      Router.push('/select-employers');
      return;
    }

    if (!userProfile.omersUser && userProfile.training) {
      // route user to learning centre if required training flag is set
      Router.push('/learning-centre');
    } else if (userProfile.groups.length === 1 && !userProfile.omersUser) {
      // auto-set employer if only one group option
      yield put(
        selectEmployerAction({
          name: userProfile.groups[0].name,
          employerNumber: userProfile.groups[0].employerNumber,
          groupNumber: userProfile.groups[0].groupNumber
        })
      );
    } else {
      // hide email notifications for OMERS user
      if (userProfile.omersUser) {
        yield put(setShowEmailNotificationRowAction(false));
      }

      Router.push('/select-employers');
    }

    Sprig('setUserId', userProfile.username);
  } else {
    yield put(loginFailedAction());

    yield put(
      notifyErrorAction(
        getApiErrorMessageByCode(PingCredentialErrors.ACCESS_DENIED)
      )
    );
  }
}

export function* loginOldSaga(action: EapAction): any {
  cookies.remove('PF');
  cookies.remove('token');
  deleteCookies();
  const state = nanoid(12);
  const getFlowIdPayload = new URLSearchParams({
    client_id: NEXT_PUBLIC_PING_AUTH_EXTERNAL_CLIENT_ID,
    response_type: 'code',
    response_mode: 'pi.flow',
    state,
    redirect_uri: window.location.hostname
  });

  const resFlowId = yield getFlowId(getFlowIdPayload);

  if (resFlowId.id && resFlowId.status === 'USERNAME_PASSWORD_REQUIRED') {
    const resAuthorizationCode = yield getAuthorizationCode(
      action.data,
      resFlowId.id,
      {
        'X-XSRF-Header': 'omers',
        'Content-Type':
          'application/vnd.pingidentity.checkUsernamePassword+json'
      }
    );
    if (
      resAuthorizationCode.authorizeResponse?.code &&
      resAuthorizationCode.status === 'COMPLETED'
    ) {
      const res = yield login({
        code: resAuthorizationCode.authorizeResponse?.code
      });
      if (res.data) {
        yield call(datadogRum.setUser, {
          id: res.data.JwtUser.username,
          email: res.data.JwtUser.email
        });

        yield put(
          loginSucceededAction({
            accessToken: res.token,
            userProfile: res.data.JwtUser
          })
        );
        yield put({
          type: notificationActionTypes.CLEAR_NOTIFICATION
        });
        setAccessToken(res.token);

        const sysDate = yield call(getSysDate);
        if (sysDate?.data) {
          yield put(setSysDateAction(sysDate.data));
        }
        yield put(getFeatureFlagsAction());
        const { featureFlags } = yield select(featureFlagSelector);
        // route user to home page and set employer if only one group option and attestation is complete
        // route user to select employer page if attestation is required and user is non-smo
        const userData = res.data.JwtUser;
        if (shouldShowEmployer(featureFlags, userData)) {
          yield Router.push('/select-employers');
          return;
        }
        if (!userData.omersUser && userData.training) {
          yield Router.push('/learning-centre');
        } else if (userData.groups.length === 1 && !userData.omersUser) {
          yield put(
            selectEmployerAction({
              name: userData.groups[0].name,
              employerNumber: userData.groups[0].employerNumber,
              groupNumber: userData.groups[0].groupNumber
            })
          );
        } else {
          // hide email notifications for OMERS user
          if (userData.omersUser) {
            yield put(setShowEmailNotificationRowAction(false));
          }
          yield Router.push('/select-employers');
        }
        Sprig('setUserId', userData.username);
      } else {
        yield put(loginFailedAction());
        yield put({
          type: notificationActionTypes.NOTIFY_ERROR,
          data: getApiErrorMessage(res.errors)
        });
        yield put(getFeatureFlagsAction());
      }
    } else if (
      resAuthorizationCode?.details &&
      resAuthorizationCode?.details[0]?.userMessage ===
        "We didn't recognize the username or password you entered. Please try again."
    ) {
      yield sendLoginFailed('1', 'Invalid Username of password');
    } else if (
      resAuthorizationCode?.details &&
      (resAuthorizationCode?.details[0]?.userMessage ===
        'Your account is locked. It will be unlocked automatically after 6 hours.' ||
        resAuthorizationCode?.details[0]?.userMessage ===
          'Your account is locked. Please contact your system administrator.')
    ) {
      yield sendLoginFailed('5', 'Account locked');
    } else if (
      resAuthorizationCode?.details &&
      resAuthorizationCode?.details[0]?.userMessage ===
        'Your account is disabled. Please contact your system administrator.'
    ) {
      yield sendLoginFailed('7', 'Account disabled');
    } else if (
      resAuthorizationCode?.status === 'MUST_CHANGE_PASSWORD' &&
      resAuthorizationCode?.userMessage ===
        'Your password is expired and must be changed.'
    ) {
      yield put(setPasswordExpiredFlagAction(true));
      yield put(setUsernameForResetExpiredPasswordAction(action.data.username));
      yield Router.push('/update-password');
      yield put(clearLoadingAction());
    } else {
      yield sendLoginFailed('4', '');
    }
  } else {
    yield sendLoginFailed('-1', '');
  }
}

function* loginSaga(action: ReturnType<typeof loginAction>) {
  const { featureFlags }: ReturnType<typeof featureFlagSelector> = yield select(
    featureFlagSelector
  );

  if (isEnabled(featureFlags, currentFlags.PING_BFF)) {
    yield call(loginNewSaga, action);
  } else {
    yield call(loginOldSaga, action);
  }
}

export function* internalUserLoginNewSaga(
  action: ReturnType<typeof internalUserLoginAction>
) {
  const loginRes: Response = yield call(fetch, '/api/auth/login-internal', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      authCode: action.data.authCode,
      redirectUri: window.location.origin
    })
  });

  const loginJson: AuthLoginInternalResponse = yield loginRes.json();

  // successful internal user login flow
  if (loginJson.result.data) {
    const { sri, accessToken, userProfile } = loginJson.result.data;

    datadogRum.setUser({
      id: userProfile.username,
      email: userProfile.email
    });

    yield put(
      loginSucceededAction({
        sri,
        accessToken,
        userProfile
      })
    );
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });

    // retrieve e-access system date to sync with backend date
    const sysDate: { data: { sysDate: SysDateObj } } = yield call(getSysDate);

    if (sysDate?.data) {
      yield put(setSysDateAction(sysDate.data));
    }

    const { featureFlags } = yield select(featureFlagSelector);

    // route user to home page and set employer if only one group option and attestation is complete
    // route user to select employer page if attestation is required and user is non-smo
    if (shouldShowEmployer(featureFlags, userProfile)) {
      yield Router.push('/select-employers');
      return;
    }

    if (userProfile.groups.length === 1 && !userProfile.omersUser) {
      yield put(
        selectEmployerAction({
          name: userProfile.groups[0].name,
          employerNumber: userProfile.groups[0].employerNumber,
          groupNumber: userProfile.groups[0].groupNumber
        })
      );
    } else {
      // hide email notifications for OMERS user
      if (userProfile.omersUser) {
        yield put(setShowEmailNotificationRowAction(false));
      }
      yield Router.push('/select-employers');
    }
  } else {
    yield put(loginFailedAction());

    yield put(
      notifyErrorAction(
        getApiErrorMessageByCode(PingCredentialErrors.ACCESS_DENIED)
      )
    );
  }
}

export function* internalUserLoginOldSaga(action: EapAction): any {
  const res = yield call(login, {
    code: action.data.authCode,
    redirectUri: window.location.origin
  });

  if (res.data) {
    yield call(datadogRum.setUser, {
      id: res.data.JwtUser.username
    });

    yield put(
      loginSucceededAction({
        accessToken: res.token,
        userProfile: res.data.JwtUser
      })
    );
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
    setAccessToken(res.token);

    const sysDate = yield call(getSysDate);
    if (sysDate?.data) {
      yield put(setSysDateAction(sysDate.data));
    }

    window.history.replaceState({}, '', '/');
    // route user to home page and set employer if only one group option and attestation is complete
    // route user to select employer page if attestation is required and user is non-smo
    const userData = res.data.JwtUser;
    yield put(getFeatureFlagsAction());
    const { featureFlags } = yield select(featureFlagSelector);
    if (shouldShowEmployer(featureFlags, userData)) {
      yield Router.push('/select-employers');
      return;
    }
    if (userData.groups.length === 1 && !userData.omersUser) {
      yield put(
        selectEmployerAction({
          name: userData.groups[0].name,
          employerNumber: userData.groups[0].employerNumber,
          groupNumber: userData.groups[0].groupNumber
        })
      );
    } else {
      // hide email notifications for OMERS user
      if (userData.omersUser) {
        yield put(setShowEmailNotificationRowAction(false));
      }
      yield Router.push('/select-employers');
    }
  } else {
    yield put(loginFailedAction());
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res.errors)
    });
    yield put(getFeatureFlagsAction());
  }
}

function* internalUserLogin(
  action: ReturnType<typeof internalUserLoginAction>
) {
  const { featureFlags }: ReturnType<typeof featureFlagSelector> = yield select(
    featureFlagSelector
  );

  if (isEnabled(featureFlags, currentFlags.PING_BFF)) {
    yield call(internalUserLoginNewSaga, action);
  } else {
    yield call(internalUserLoginOldSaga, action);
  }
}

export function* sendLoginFailed(errorCode: string, errorMessage: string) {
  yield put(loginFailedAction());
  yield put({
    type: notificationActionTypes.NOTIFY_ERROR,
    data: getApiErrorMessage([
      { code: errorCode, msg: errorMessage, parameters: null }
    ])
  });
  yield put(getFeatureFlagsAction());
}

export function* postLoginSaga() {
  yield all([
    put({
      type: propertiesActionTypes.PROPERTIES_REQUESTED
    }),
    put(getDonnaBusinessConfig()),
    put(getEcorrTopicsAction())
  ]);
}

export function* logoutNewSaga(action: ReturnType<typeof logoutAction>) {
  const token: string = yield select(tokenSelector);

  try {
    yield call(fetch, '/api/auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    datadogRum.clearUser();
    Router.push('/');

    yield put(setTokenExpiryTimerVisibility(false));
    yield put(hideSessionRefreshModal());
    yield put(setSessionExpiry(null));

    yield put({
      type: userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED,
      data: {
        logoutNotification: action?.data?.logoutNotification ?? null
      }
    });

    yield put(clearLoadingAction());
    yield delay(3000);
    yield put({
      type: userSessionActionTypes.LOGOUT_NOTIFICATION_HIDE
    });

    Sprig('logoutUser');
  } catch (e) {
    // catch cancelled saga from RouteConfirm navigation hault and re-queue
    if (action.type !== userSessionActionTypes.LOGOUT_FORCED_REQUESTED) {
      yield put(setIdleAction(action));
    }
    yield put(clearLoadingAction());
  }
}

export function* logoutOldSaga(action: EapAction): any {
  try {
    yield call(logout);
    cookies.remove('PF');
    deleteCookies();
    yield call(datadogRum.clearUser);
    yield Router.push('/');
    yield put(setTokenExpiryTimerVisibility(false));
    yield put(hideSessionRefreshModal());
    yield put(setSessionExpiry(null));

    // use same action for success/failed to ensure user is logged out on frontend
    yield put({
      type: userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED,
      data: {
        logoutNotification: action?.data?.logoutNotification ?? null
      }
    });
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put({
      type: userSessionActionTypes.LOGOUT_NOTIFICATION_HIDE
    });
    Sprig('logoutUser');
  } catch (e) {
    // catch cancelled saga from RouteConfirm navigation hault and re-queue
    if (action.type !== userSessionActionTypes.LOGOUT_FORCED_REQUESTED) {
      yield put(setIdleAction(action));
    }
    yield put(clearLoadingAction());
  }
}

function* logoutSaga(action: ReturnType<typeof logoutAction>) {
  const { featureFlags }: ReturnType<typeof featureFlagSelector> = yield select(
    featureFlagSelector
  );

  if (isEnabled(featureFlags, currentFlags.PING_BFF)) {
    yield call(logoutNewSaga, action);
  } else {
    yield call(logoutOldSaga, action);
  }
}

export function* eCorrespondenceOptOutSaga(action: EapAction): any {
  yield put(setShowEmailNotificationSpinnerAction(true));
  const flag = action.data;
  const employerGroupNumber = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );
  const res = yield call(
    safeAuthEffect,
    setEmailOptoutStatus,
    employerGroupNumber,
    flag
  );
  if (res && res.compositeResponse) {
    yield put({
      type: userSessionActionTypes.E_CORRESPONDENCE_OPT_OUT_SUCCEEDED
    });
    yield put({
      type: userSessionActionTypes.SET_E_CORRESPONDENCE_OPT_OUT,
      data: flag
    });
    yield put(setShowEmailNotificationSpinnerAction(false));
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
  } else {
    yield put({
      type: userSessionActionTypes.E_CORRESPONDENCE_OPT_OUT_FAILED
    });
    // reset to original on failure
    yield put({
      type: userSessionActionTypes.SET_E_CORRESPONDENCE_OPT_OUT,
      data: !flag
    });
    yield put(setShowEmailNotificationSpinnerAction(false));
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res.errors)
    });
  }
}

const isAfterTokenExpiry = (time: number, token: string) => {
  const tokenExpiry = getTokenExpiry(token) * 1000;

  if (tokenExpiry < 0) {
    clientSide.error('There was an error parsing the token expiry');

    return false;
  }

  return time > tokenExpiry;
};

/**
 * This saga is responsible for refreshing user sessions. It starts a countdown timer to show the refresh session modal
 * 2 minutes before the session expires. If the user the user fails to dismiss the modal within 2 minutes, the user is
 * logged out.
 *
 * It also compares the timeout of the session with the expiry of the token. If the session timeout is greater than the
 * token expiry, the token countdown timer is shown instead.
 *
 * If the action param has the data property false then it doesn't try to manually refresh the session
 */
export function* oldExtendSessionSaga({
  data: shouldMakeRequest
}: EapAction<boolean>) {
  const TWO_MINUTES_IN_MILLIS = 120_000;
  const DEFAULT_TIMEOUT_IN_MINS = 30;
  const { featureFlags } = yield select(featureFlagSelector);
  let isUserAuthenticated: boolean = yield select(isAuthenticated);
  const token: string = yield select(tokenSelector);
  const isTokenExpiryTimerVisible: boolean = yield select(
    tokenExpiryTimerSelector
  );
  clientSide.debug('Session countdown started');

  if (
    !isUserAuthenticated ||
    !isEnabled(featureFlags, currentFlags.SESSION_TIMER) ||
    isAfterTokenExpiry(Date.now(), token)
  )
    return;

  try {
    const isRefreshSuccess = (
      response: SessionRefreshResponse
    ): response is SessionRefreshSuccess =>
      Object.prototype.hasOwnProperty.call(response, 'data');

    const res: SessionRefreshResponse = shouldMakeRequest
      ? yield call(safeAuthEffect, refreshSession)
      : {
          data: {
            idleTimeout: addMinutes(new Date(), DEFAULT_TIMEOUT_IN_MINS)
          }
        };

    if (isRefreshSuccess(res)) {
      clientSide.debug('Session refreshed');
      const sessionTimeout = new Date(res.data.idleTimeout).getTime();
      const timeToShowModal =
        sessionTimeout - Date.now() - TWO_MINUTES_IN_MILLIS;

      yield put(setSessionExpiry(sessionTimeout));

      yield delay(timeToShowModal);

      isUserAuthenticated = yield select(isAuthenticated);
      if (isUserAuthenticated && !isTokenExpiryTimerVisible) {
        clientSide.debug('Session refresh modal shown');
        yield put(showSessionRefreshModal());
      }
      yield delay(TWO_MINUTES_IN_MILLIS);

      isUserAuthenticated = yield select(isAuthenticated);
      if (isUserAuthenticated) {
        clientSide.debug('User session expired... logging user out');
        yield put(hideSessionRefreshModal());
        yield put(logoutForcedAction({ logoutNotification: 'expired' }));
      }
    } else {
      clientSide.error(
        'There was an error refreshing the session',
        res?.errors
      );
    }
  } catch (e) {
    clientSide.error(
      'There was an unexpected error refreshing the session',
      {},
      e as Error
    );
  }
}

function* handleSessionExtension(idleTimeout: string) {
  const TWO_MINUTES_IN_MILLIS = 120_000;
  const isTokenExpiryTimerVisible: boolean = yield select(
    tokenExpiryTimerSelector
  );

  clientSide.debug('Session refreshed');
  const sessionTimeout = new Date(idleTimeout).getTime();
  const timeToShowModal = sessionTimeout - Date.now() - TWO_MINUTES_IN_MILLIS;
  yield put(setSessionExpiry(sessionTimeout));

  yield delay(timeToShowModal);

  const isUserAuthenticated: boolean = yield select(isAuthenticated);
  if (isUserAuthenticated && !isTokenExpiryTimerVisible) {
    clientSide.debug('Session refresh modal shown');
    yield put(showSessionRefreshModal());
  }
  yield delay(TWO_MINUTES_IN_MILLIS);

  const newIsUserAuthenticated: boolean = yield select(isAuthenticated);
  if (newIsUserAuthenticated) {
    clientSide.debug('User session expired... logging user out');
    yield put(hideSessionRefreshModal());
    yield put(logoutForcedAction({ logoutNotification: 'expired' }));
  }
}

export function* newExtendSessionSaga({
  data: shouldRefreshSession
}: ReturnType<typeof sessionRefreshedAction>) {
  const DEFAULT_TIMEOUT_IN_MINS = 30;
  const { featureFlags } = yield select(featureFlagSelector);
  const isUserAuthenticated: boolean = yield select(isAuthenticated);
  const token: ReturnType<typeof tokenSelector> = yield select(tokenSelector);

  clientSide.debug('Session countdown started');

  if (
    !isUserAuthenticated ||
    !isEnabled(featureFlags, currentFlags.SESSION_TIMER) ||
    isAfterTokenExpiry(Date.now(), token)
  ) {
    return;
  }

  try {
    const defaultIdleTimeout = addMinutes(
      new Date(),
      DEFAULT_TIMEOUT_IN_MINS
    ).toISOString();

    if (shouldRefreshSession) {
      const extendedPingSession: Response = yield call(
        fetch,
        '/api/auth/session-extend',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
          }
        }
      );

      const extendedPingSessionJson: AuthExtendSessionResponse = yield extendedPingSession.json();

      if (
        extendedPingSessionJson.result.success &&
        extendedPingSessionJson.result.data?.idleTimeout
      ) {
        const idleTimeout = extendedPingSessionJson.result.data.idleTimeout;
        yield call(handleSessionExtension, idleTimeout);
      } else {
        clientSide.error(
          'There was an error getting idleTime from Ping',
          toLoggerError(extendedPingSessionJson.result.errors[0])
        );
        return;
      }
    } else {
      yield call(handleSessionExtension, defaultIdleTimeout);
    }
  } catch (e) {
    clientSide.error(
      'There was an error refreshing the session',
      toLoggerError(e)
    );
  }
}

function* extendSession(action: ReturnType<typeof sessionRefreshedAction>) {
  const { featureFlags }: ReturnType<typeof featureFlagSelector> = yield select(
    featureFlagSelector
  );

  if (isEnabled(featureFlags, currentFlags.PING_BFF)) {
    yield call(newExtendSessionSaga, action);
  } else {
    yield call(oldExtendSessionSaga, action);
  }
}

export function* userSessionSaga(): any {
  yield all([
    yield takeEvery(
      userSessionActionTypes.LOGIN_REQUESTED,
      safeEffect,
      loginSaga
    ),
    yield takeLeading(
      [
        userSessionActionTypes.LOGOUT_REQUESTED,
        userSessionActionTypes.LOGOUT_FORCED_REQUESTED
      ],
      safeEffect,
      logoutSaga
    ),
    yield takeLatest(userSessionActionTypes.LOGIN_SUCCEEDED, postLoginSaga),
    yield takeLatest(
      userSessionActionTypes.E_CORRESPONDENCE_OPT_OUT_REQUESTED,
      eCorrespondenceOptOutSaga
    ),
    yield takeLatest(
      userSessionActionTypes.INTERNAL_LOGIN_REQUESTED,
      internalUserLogin
    ),
    yield takeLatest(userSessionActionTypes.SESSION_REFRESHED, extendSession)
  ]);
}
