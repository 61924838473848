import winston, { format } from 'winston';
import { winstonFormatMask } from '@omers-packages/common-pii-mask';
import { env } from '@omers-packages/next-isomorphic-runtime-env';

const { combine, timestamp, prettyPrint, json } = format;
const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');

/**
 * https://github.com/winstonjs/winston#usage
 * https://docs.datadoghq.com/tracing/other_telemetry/connect_logs_and_traces/nodejs/#automatic-injection
 */
const logger = winston.createLogger({
  format: combine(
    winstonFormatMask(),
    timestamp(),
    NEXT_PUBLIC_APP_ENV === 'local' ? prettyPrint() : json()
  ),
  transports: [
    new winston.transports.Console({
      level: NEXT_PUBLIC_APP_ENV === 'prod' ? 'info' : 'debug'
    })
  ]
});

export default logger;
