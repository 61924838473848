import Cookies from 'js-cookie';
import { env } from '@omers-packages/next-isomorphic-runtime-env';

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');

/**
 * @deprecated
 * Use token from the store instead
 */

export const deleteCookies = () => {
  Cookies.remove('PF', { domain: '.omers.com' });
  Cookies.remove('token', { domain: '.omers.com' });
};

/**
 * @deprecated
 * Use token from the store instead
 */

export const setAccessToken = (token: string) =>
  Cookies.set('token', token, {
    path: '/',
    secure: NEXT_PUBLIC_APP_ENV !== 'local',
    sameSite: 'strict'
  });
