import { Collapse } from '@blueprintjs/core';
import Button from 'components/v2/atomic/button/Button';
import Icon, { IconName } from 'components/v2/atomic/icon/Icon';
import { IconNamesXSmall } from 'components/v2/atomic/icon/Icons';
import { isEmpty, kebabCase } from 'lodash';
import Link from 'next/link';
import { useState } from 'react';
import { get, ThemeUIStyleObject } from 'theme-ui';
import { isValidDropdown } from '../dynamicNav/utils/utils';
import { SubMenuItem } from '../mainNavButton/MainNavButton';

export type AccordionMenuItemProps = {
  title: string;
  links: SubMenuItem[];
  activeLink: string;
  id: string;
  icon?: IconName;
  onChange?: (isOpen: boolean) => void;
  sxOverride?: ThemeUIStyleObject;
  isNewNavLinks?: boolean;
  newNavLinks?: Record<string, any>;
  sortedNewNavLinkKeys?: string[];
};

export default function AccordionMenuItem({
  title,
  links,
  activeLink,
  id,
  icon,
  sxOverride,
  isNewNavLinks = false,
  newNavLinks,
  sortedNewNavLinkKeys
}: AccordionMenuItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setIsCollapsed(!isCollapsed);
    }
  };

  if (
    isEmpty(links) &&
    newNavLinks &&
    isNewNavLinks &&
    !isValidDropdown(newNavLinks)
  )
    return null;

  return (
    <div
      sx={{
        backgroundColor: isCollapsed ? 'grey10' : 'white',
        [`&:first-of-type`]: {
          borderTop: t => `1px solid ${get(t, 'colors.grey40')}`
        },
        borderBottom: t => `1px solid ${get(t, 'colors.grey40')}`,
        ...sxOverride
      }}
    >
      <div
        data-testid={`${id}_accordion-menu-item`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '1rem 2rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          '&:focus': {
            outline: 'none'
          },
          '&:focus-visible': {
            outline: '2px solid',
            outlineColor: 'extendedBlue100',
            outlineOffset: '-2px'
          },
          '&:hover': {
            backgroundColor: 'extendedBlue25'
          },
          '@media screen and (max-width: 768px)': {
            p: '1rem 2rem'
          }
        }}
        tabIndex={0}
        role="menu"
        onClick={() => setIsCollapsed(!isCollapsed)}
        onKeyDown={keyDownHandler}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.75rem',
            color: 'brandNavy100',
            fontFamily: 'body',
            fontSize: 'small',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'base'
          }}
        >
          {icon && <Icon icon={icon} />}
          {title}
        </div>
        <div
          sx={{
            transition: 'all 0.3s ease-in-out',
            transform: isCollapsed ? 'rotate(0.5turn)' : 'rotate(0)'
          }}
        >
          <Icon icon={IconNamesXSmall.ARROW_DOWN} />
        </div>
      </div>
      <Collapse isOpen={isCollapsed}>
        <ul
          sx={{
            color: 'brandNavy100',
            fontFamily: 'body',
            fontSize: 'small',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'base'
          }}
        >
          {/* We want to check here if the array links is empty meaning
          which is used for the old implementation of nav links,
          and to also check for if the newNavLinks which is of type
          Record<string, NavPageItems> is not empty, then we can render
          out the new set nav links with the new structure */}
          {isEmpty(links) &&
            isNewNavLinks &&
            !isEmpty(newNavLinks) &&
            sortedNewNavLinkKeys &&
            !isEmpty(sortedNewNavLinkKeys) &&
            sortedNewNavLinkKeys.map(nav => (
              <li
                key={`accordion-menu-item-${kebabCase(nav)}`}
                sx={{
                  width: '100%',
                  backgroundColor: 'none',
                  '& a, & .accordionMenuItem': {
                    p: '1rem 2rem',
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    textDecoration: 'none',
                    color: 'brandNavy100'
                  },
                  '&:hover': {
                    backgroundColor: 'extendedBlue25'
                  },
                  '& a:active, & .accordionMenuItem:active': {
                    color: 'extendedBlue100',
                    textDecoration: 'none'
                  },
                  '& a:focus, & .accordionMenuItem:focus': {
                    outline: 'none'
                  },
                  '& a:focus-visible, & .accordionMenuItem:focus-visible': {
                    outline: '2px solid',
                    outlineColor: 'extendedBlue100',
                    outlineOffset: '-2px'
                  }
                }}
              >
                {newNavLinks[nav].title &&
                  newNavLinks[nav].slug &&
                  !newNavLinks[nav].hideItem && (
                    <Link
                      onClick={newNavLinks[nav].onClick}
                      href={new URL(newNavLinks[nav].slug, window.origin)}
                      rel="noreferrer noopener"
                    >
                      {newNavLinks[nav].title}
                    </Link>
                  )}
              </li>
            ))}
          {links.map(link => (
            <li
              sx={{
                width: '100%',
                borderLeft: t =>
                  link.name === activeLink
                    ? `4px solid ${get(t, 'colors.extendedBlue100')}`
                    : 'none',
                backgroundColor:
                  link.name === activeLink ? 'extendedBlue25' : 'none',
                '& a, & .accordionMenuItem': {
                  p: '1rem 2rem',
                  display: 'block',
                  height: '100%',
                  width: '100%',
                  textDecoration: 'none',
                  color:
                    link.name === activeLink
                      ? 'extendedBlue100'
                      : 'brandNavy100',
                  pl:
                    link.name === activeLink
                      ? ['3.75rem', '3.25rem', undefined]
                      : ['4rem', '3.5rem', undefined]
                },
                '&:hover': {
                  backgroundColor: 'extendedBlue25'
                },
                '& a:active, & .accordionMenuItem:active': {
                  color: 'extendedBlue100',
                  textDecoration: 'none'
                },
                '& a:focus, & .accordionMenuItem:focus': {
                  outline: 'none'
                },
                '& a:focus-visible, & .accordionMenuItem:focus-visible': {
                  outline: '2px solid',
                  outlineColor: 'extendedBlue100',
                  outlineOffset: '-2px'
                }
              }}
              key={`accordion-menu-item-${kebabCase(link.name as string)}`}
            >
              {link.url && !link.onClick && (
                <Link
                  href={link.url}
                  target={link.openInNewTab ? '_blank' : undefined}
                  rel="noreferrer noopener"
                >
                  {link.name}
                </Link>
              )}

              {link.onClick && (
                <Button
                  id={`user-accordion-${link.id}`}
                  variant="simple"
                  className="accordionMenuItem"
                  sx={{
                    textAlign: 'left',
                    '&:active:not(:disabled)': {
                      textDecoration: 'none'
                    }
                  }}
                  onClick={link.onClick}
                >
                  {link.name}
                </Button>
              )}

              {!link.url && !link.onClick && (
                <span className="accordionMenuItem">{link.name}</span>
              )}
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
}
