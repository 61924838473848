/* eslint-disable no-restricted-globals */
import {
  PropertiesItem,
  PropertiesObjectItem,
  FormSubmitResponse,
  ExceptionsObject,
  ApiErrorObject,
  DonnaExceptionsObject
} from 'interfaces';
import { TFunction } from 'i18next';
import i18n from 'modules/i18n';
import { FormManagerError } from 'components/v2/molecular/formManager/FormManager';
import { EaccessAPIError } from 'services';
import errorCodes from './errorCodes';
import { warningCodes } from './warningCodes';
import { phonePattern } from './regexPatterns';
import { getFileExtension } from './validators';

export const getApiErrorMessage = (errors: ApiErrorObject[]) => {
  const genericMsg =
    errorCodes.ErrorCodeEntry.find(err => err.Code === 'GENERIC_ERROR')
      ?.DefaultMsg ?? '';
  if (errors?.length) {
    if (errors[0].msgUI) {
      return errors[0].msgUI;
    }
    if (errors[0].code === '-1' && errors[0].msg === 'Unauthorized') {
      return 'Invalid username or password, please try again. Keep in mind that after 5 failed attempts, you account will be locked.';
    }
    if (errors[0].code === '-1') {
      return `${
        errorCodes.ErrorCodeEntry.find(err => err.Code === '-1')?.DefaultMsg
      } (${errors[0].errorId})`;
    }
    let message =
      errorCodes.ErrorCodeEntry.find(err => err.Code === errors[0].code)
        ?.DefaultMsg ?? genericMsg;
    if (Array.isArray(errors[0].parameters)) {
      errors[0].parameters.forEach((item, index) => {
        message = message?.replace(`{${index}}`, item);
      });
    }
    return message;
  }
  return genericMsg;
};

/**
 * Take a JWT token and return the expiry time as a Unix Timestamp
 * @param string JWT token
 * @returns number Unix Timestamp in seconds of token expiry or -1 if token is not a string
 */
export const getTokenExpiry = (token: unknown): number => {
  if (typeof token !== 'string') {
    return -1;
  }

  const base64Urlpayload = token.split('.')[1];
  const base64Payload = base64Urlpayload.replace(/-/g, '+').replace(/_/g, '/');
  const tokenPayload = JSON.parse(atob(base64Payload));
  return tokenPayload.exp;
};

/**
 * Take a JWT token and return whether it is expired or not
 * @param string JWT token
 * @returns boolean true if token is expired, false if not
 */
export const isTokenExpired = (token: unknown): boolean => {
  const expiry = getTokenExpiry(token);
  if (expiry === -1) {
    return true;
  }

  return Date.now() > expiry * 1000;
};

/**
 * Retrieve API error message from errorCodes map based on given code;
 * falls back to generic system error
 */
export const getApiErrorMessageByCode = (code: string | null) =>
  errorCodes.ErrorCodeEntry.find(err => err.Code === code)?.DefaultMsg ??
  errorCodes.GenericErrorCode.DefaultMsg;

export const getErrorMessageWithParams = (
  error: ApiErrorObject | FormManagerError | EaccessAPIError
) => {
  let errorMessage: string = getApiErrorMessageByCode(error.code);

  if (error.parameters) {
    error.parameters.forEach((param, i) => {
      errorMessage = errorMessage.replace(`%${i}`, param);
      errorMessage = errorMessage.replace(`{${i}}`, param);
    });
  }

  if (error.msg) {
    errorMessage = error.msg;
  }

  return errorMessage;
};

/**
 * Retrieve API warning message from warningCodes map based on given code;
 * falls back to generic system warning
 */
export const getApiWarningMessageByCode = (code: string | null) =>
  warningCodes.WarningCodeMap.find(warning => warning.Code === code)
    ?.DefaultMsg ??
  warningCodes.WarningCodeMap.find(warning => warning.Code === 'GENERIC_ERROR')
    ?.DefaultMsg;

/**
 * Retrieve YYYY-MM-DD formatted date from given year, month, day strings
 */
export const getDateFromYMDStrings = (
  year: string,
  month: string,
  day: string
): string | null =>
  year && month && day
    ? `${year}-${
        parseInt(month, 10) < 10 && month.length === 1 ? `0${month}` : month
      }-${parseInt(day, 10) < 10 && day.length === 1 ? '0' : ''}${day}`
    : null;

/**
 * Retrieve year, month, day as object from date string 'YYYY-MM-DD'
 */
export const getYMDStringsFromDate = (
  date: string | null
): { year: string; month: string; day: string } => {
  const matches =
    date?.match(/([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/) ?? [];
  return {
    year: matches.length >= 4 ? matches[1] : '',
    month: matches.length >= 4 ? matches[2] : '',
    day: matches.length >= 4 ? matches[3] : ''
  };
};

/**
 * Retrieve year, month, day as object from date string 'MM/DD/YYYY'
 */
export const getYMDStringsFromModifiedDate = (
  date: string | null
): { year: string; month: string; day: string } => {
  const matches =
    date?.match(/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})/) ?? [];
  return {
    month: matches.length >= 4 ? matches[1] : '',
    day: matches.length >= 4 ? matches[2] : '',
    year: matches.length >= 4 ? matches[3] : ''
  };
};

/**
 * Get date object from date string of form "MM/DD/YYYY"
 */
export const getDateFromString = (date: string): Date => {
  const { day, month, year } = getYMDStringsFromModifiedDate(date);
  return new Date(Number(year), Number(month) - 1, Number(day));
};

/**
 * Get phone number and area code as object from full phone number string
 */
export const getPhoneAndAreaCodeFromString = (
  phone: any,
  includeDash?: boolean
): { number: string | null; areaCode: string | null } => {
  if (phone) {
    // eslint-disable-next-line no-param-reassign
    phone = phone.replace(/ /g, '');
    const matches = phone?.match(phonePattern) ?? [];
    const numberValue =
      matches.length >= 4
        ? includeDash
          ? `${matches[2]}-${matches[3]}`
          : `${matches[2]}${matches[3]}`
        : phone || null;

    return {
      number: numberValue,
      areaCode: matches.length >= 4 ? matches[1] : null
    };
  }
  return {
    number: null,
    areaCode: null
  };
};

export const getEmploymentStatus = (status: string) => {
  switch (status) {
    case 'C':
      return 'Continuous Full-Time';
    case 'O':
      return 'Other than Continuous Full-Time';
    default:
      return 'Other than Continuous Full-Time';
  }
};

/**
 * Get the display value for the member status based on the status code.
 * @param status - The status code of the member.
 * @returns The localized string representing the member status.
 */
export const getMemberStatus = (status: string) => {
  switch (status) {
    case 'I':
      return i18n.t('members:MEMBER_SEARCH_RESULTS_INACTIVE');
    case 'A':
      return i18n.t('members:MEMBER_SEARCH_RESULTS_ACTIVE');
    case 'W':
      return i18n.t('members:MEMBER_SEARCH_RESULTS_WAIVER');
    default:
      return i18n.t('members:MEMBER_SEARCH_RESULTS_INACTIVE');
  }
};

export const getOccupation = (status: string) => {
  switch (status) {
    case 'C':
      return 'Councillor';
    case 'F':
      return 'Firefighter';
    case 'O':
      return 'Other';
    case 'P':
      return 'Police';
    case 'R':
      return 'Paramedic';
    default:
      return 'Other';
  }
};

export const getPropertiesList = (key: string, properties: PropertiesItem[]) =>
  properties?.find((item: PropertiesItem) => item.id === key)?.items ?? [];

export const getPropertiesItem = (
  key: string,
  propertyType: PropertiesObjectItem[]
) => {
  const match = propertyType.filter(item => item.key === key)[0];
  return match?.val ?? null;
};

export const getAffiliationsByOccupation = (
  occupation: string,
  properties: PropertiesItem[]
) =>
  getPropertiesList('PSS Affiliation Type', properties).filter(
    property => property.key[0] === occupation
  );

export const getSubmissionStatus = (
  submitResponse: FormSubmitResponse,
  exceptions: ExceptionsObject[],
  donnaExceptions?: DonnaExceptionsObject
) => {
  if (exceptions?.length > 0) {
    const errors = exceptions.filter(item => item.exception === 'Error');
    return errors.length > 0 ? 'error' : 'warning';
  }

  if (donnaExceptions && donnaExceptions?.errors?.length > 0) {
    return 'error';
  }

  if (donnaExceptions && donnaExceptions?.warnings?.length > 0) {
    return 'warning';
  }

  if (submitResponse.mssCallSuccessful && submitResponse.pssResponses) {
    const failedResponses = submitResponse.pssResponses.filter(
      item => item.pssCallSuccessful === false
    );
    return failedResponses.length > 0 ? 'warning' : 'success';
  }
  if (
    submitResponse.mssCallSuccessful &&
    submitResponse.pssResponses === null
  ) {
    return 'success';
  }
  return 'warning';
};

/**
 * returns a date with timezone offset
 * @param dateStr a valid date string like 12/20/2020 or 2020-12-20
 * @returns {Date} Date without the javascript timezone issue
 */
export const getTimeZoneSafeDate = (dateStr: string) => {
  let date = new Date(dateStr);
  // https://stackoverflow.com/a/52352512/220671
  date = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};

/**
 * get category from topic
 * @param topic {string}
 * @returns category {string}
 */
export const getCategoryFromTopic = (topic: string) => {
  let category = '';
  switch (topic) {
    case 'Annual Reporting (119)':
      category = 'Annual Reporting';
      break;
    case 'Contribution Remittance (105)':
      category = 'Contribution Remittance';
      break;
    case 'Disability Reporting (143)':
      category = 'Disability Reporting';
      break;
    case 'General Inquiry':
    case 'e-Access Support':
      category = 'Other';
      break;
    case 'Employer Contact Information (109)':
      category = 'Employer Information Update';
      break;
    case 'Eligible Service':
      category = 'Eligible Service';
      break;
    case 'Leave Period Reporting (165)':
      category = 'Leave Period';
      break;
    case 'Member Enrolment (102)':
      category = 'Member Enrolment';
      break;
    case 'Member Info Change (106)':
      category = 'Member Info Change';
      break;
    case 'NRA conversion':
      category = 'NRA Conversion';
      break;
    case 'Omission Period (167)':
      category = 'Omission Period';
      break;
    case 'Pension Estimate (190)':
      category = 'Estimates';
      break;
    case 'Pre-Retirement Death (143)':
      category = 'Pre-Retirement Death';
      break;
    case 'Proof of Eligible Service (168)':
      category = 'Eligible Service';
      break;
    case 'Retirement (143)':
      category = 'Retirement';
      break;
    case 'Supplemental Plans (300/301)':
      category = 'Supplemental Plans';
      break;
    case 'Employment Change (158)':
      category = 'Disability Reporting';
      break;
    case 'Termination (143)':
      category = 'Termination';
  }
  return category;
};

export const getCurrentEmploymentPeriodIndex106 = (
  employmentPeriods: any[]
): number => {
  let currentPeriodIndex = 0;

  for (let i = 0; i < employmentPeriods.length; i += 1) {
    if (employmentPeriods[i].actionType !== 'C') {
      // if actionType is not C, meaning this is not a newly "Created" employment period, then this value must be the latest submitted employmentPeriodIndex
      currentPeriodIndex = i;
    }
  }

  return currentPeriodIndex;
};

export const getPeriodHeader106 = (
  index: number,
  currentPeriodIndex: number,
  t: TFunction
) => {
  if (index < currentPeriodIndex) {
    return t('REQUEST_FORM_MEMBER_UPDATE_PREVIOUS_PERIOD_HEADER');
  }
  if (index > currentPeriodIndex) {
    return t('REQUEST_FORM_MEMBER_UPDATE_NEW_PERIOD_HEADER');
  }
  return t('REQUEST_FORM_MEMBER_UPDATE_CURRENT_PERIOD_HEADER');
};

export const getFileExtensionIconSrc = (fileName: string) => {
  let svgSrc = '';
  const fileType = getFileExtension(fileName);

  switch (fileType) {
    case 'pdf':
      svgSrc = '/images/pdf.svg';
      break;
    case 'jpeg':
    case 'jpg':
      svgSrc = '/images/jpg.svg';
      break;
    case 'png':
      svgSrc = '/images/png.svg';
      break;
    case 'csv':
      svgSrc = '/images/csv.svg';
      break;
    case 'xls':
    case 'xlsx':
      svgSrc = '/images/xls.svg';
      break;
    case 'doc':
    case 'docx':
      svgSrc = '/images/doc.svg';
      break;
  }

  return svgSrc;
};

export const getSex = (sex: string, t: TFunction) => {
  switch (sex) {
    case 'M':
      return t('common:SEX_MALE');
    case 'F':
      return t('common:SEX_FEMALE');
    case 'X':
      return t('common:SEX_OTHER');
    default:
      return t('common:NOT_AVAILABLE');
  }
};

/**
 * get number back if input is a Number
 * else get 0
 */
export const getNumberOrZero = (input: any) => {
  if (!isNaN(input) && input !== null && input !== undefined) {
    return Number(input);
  }
  return 0;
};

/**
 * get number back if input is a Number
 * else get ''
 */
export const getNumberOrEmptyString = (input: any) => {
  if (!isNaN(input) && input !== null && input !== undefined) {
    return Number(input);
  }
  return '';
};

export const getDirtyValues = (
  formValues: { [key: string]: any },
  formState: { [key: string]: any }
) =>
  Object.keys(formValues)
    .filter(key => Object.keys(formState?.dirtyFields).includes(key))
    .reduce((obj: { [key: string]: any }, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = formValues[key];
      return obj;
    }, {});

export const getEventTypeFromFormType = (form: string) => {
  switch (form) {
    case 'lastDateWorked':
      return 'T';
    case 'disability':
      return 'D';
    case 'death':
      return 'V';
    default:
      return 'R';
  }
};
