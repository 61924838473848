import { getInboxCountAction } from 'containers/messages/messagesActions';
import { getPaginationInfoAction } from 'containers/nft/sliceReducer';
import { getReturnedCountAction } from 'containers/requests/requestsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useIdle, useInterval, useMount } from 'react-use';
import { isAuthenticated } from 'utils';
import useRole from 'utils/hooks/useRole';

const MessageCountFetcher: React.FC = () => {
  const FIFTEEN_SECONDS = 15_000;
  const TWO_MINUTES = 120_000;
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthenticated);
  const isIdle = useIdle(FIFTEEN_SECONDS, false);
  const { isNonFinancial } = useRole();

  const fetchActions = () => {
    dispatch(getReturnedCountAction());
    dispatch(getInboxCountAction());
    dispatch(getPaginationInfoAction());
  };

  useMount(fetchActions);

  useInterval(
    fetchActions,
    isAuth && !isNonFinancial && !isIdle ? TWO_MINUTES : null
  );

  return null;
};

export default MessageCountFetcher;
