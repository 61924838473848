/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual, map } from 'lodash';
import { useForm } from 'react-hook-form-legacy';
import * as yup from 'yup';

import { RootState } from 'types';
import { maskMembershipNumber, maskName, maskEmployeeId } from 'utils';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import TextInput from 'components/forms/inputs/textInput/TextInput';
import Button from 'components/button/Button';
import { FILTER_STATUS } from 'utils/constants';
import ResetButton from 'components/resetButton/ResetButton';
import useMessageOptions from '../hooks/useMessageOptions';
import {
  getInboxAction,
  setInboxFilterIsDirtyAction
} from '../messagesActions';

import styles from './MessagesFilter.module.scss';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

export const SEARCH_BY_MEMBER = [
  {
    val: 'emp-id',
    translation: 'MESSAGES_FILTER_DROPDOWN_EMP_ID'
  },
  {
    val: 'membership-no',
    translation: 'MESSAGES_FILTER_DROPDOWN_MEMBER_NO'
  },
  {
    val: 'last-name',
    translation: 'MESSAGES_FILTER_DROPDOWN_LAST_NAME'
  }
];

const MessagesFilter = ({ membershipNo }: { membershipNo?: number }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages', 'common']);
  const { filter } = useSelector(
    (state: RootState) => state.messages.inboxFilter
  );
  const [isApplied, setIsApplied] = useState(false);

  const { messageAndBruceTopics } = useMessageOptions();

  const { ecorrContacts } = useSelector((state: RootState) => state.messages);

  const {
    register,
    control,
    setValue,
    watch,
    reset,
    formState,
    getValues
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        status: yup.string().notRequired(),
        topic: yup.string().notRequired(),
        searchByMemberSelect: yup.string().notRequired(),
        searchByMemberText: yup.string().notRequired(),
        contact: yup.string().notRequired()
      })
    ),
    defaultValues: {
      status: filter.status,
      topic: filter.topic,
      searchByMemberSelect: filter.searchByMemberSelect,
      searchByMemberText: filter.searchByMemberText,
      contact: filter.contact
    }
  });

  const watchedFields = watch([
    'status',
    'topic',
    'searchByMemberSelect',
    'searchByMemberText',
    'contact'
  ]);

  const applyFilterHandler = () => {
    const {
      status,
      topic,
      searchByMemberSelect,
      searchByMemberText,
      contact
    } = getValues();

    dispatch(
      getInboxAction({
        filter: {
          status,
          topic,
          searchByMemberSelect,
          searchByMemberText,
          contact
        },
        query: {
          status:
            FILTER_STATUS.find(statusOption => statusOption.val === status)
              ?.bruceStatuses ?? null,
          showUnreadOnly: status === 'all-unread',
          topic: topic || null,
          employeeId:
            searchByMemberSelect === SEARCH_BY_MEMBER[0].val
              ? searchByMemberText
              : null,
          membershipNo:
            membershipNo ??
            (searchByMemberSelect === SEARCH_BY_MEMBER[1].val
              ? searchByMemberText.replace(/-/g, '')
              : null),
          lastName:
            searchByMemberSelect === SEARCH_BY_MEMBER[2].val
              ? searchByMemberText
              : null,
          contact: contact || null
        }
      })
    );

    // update form default values with reset
    reset({
      status,
      topic,
      searchByMemberSelect,
      searchByMemberText,
      contact
    });
  };

  useEffect(() => {
    applyFilterHandler();
  }, []);

  // watch for apply button filter dirty state
  useEffect(() => {
    setIsApplied(
      !isEqual(
        {
          status: watchedFields.status,
          topic: watchedFields.topic,
          searchByMemberSelect: watchedFields.searchByMemberSelect,
          searchByMemberText: watchedFields.searchByMemberText,
          contact: watchedFields.contact
        },
        {
          status: filter.status,
          topic: filter.topic,
          searchByMemberSelect: filter.searchByMemberSelect,
          searchByMemberText: filter.searchByMemberText,
          contact: filter.contact
        }
      )
    );
  }, [
    filter.status,
    filter.topic,
    filter.searchByMemberSelect,
    filter.searchByMemberText,
    filter.contact,
    ...map(watchedFields)
  ]);

  // update store isDirty state to ensure MessagesTable is disabled when dirty
  useEffect(() => {
    if (formState.isDirty !== filter.isDirty) {
      dispatch(setInboxFilterIsDirtyAction(formState.isDirty));
    }
  }, [formState.isDirty, filter.isDirty]);

  const defaultMemberOptionsSelected =
    !!membershipNo ||
    (watchedFields.searchByMemberSelect === SEARCH_BY_MEMBER[0].val &&
      watchedFields.searchByMemberText === '');

  return (
    <form data-testid="member-filter-form">
      <div className={styles.messagesFilter}>
        <div className={styles.inputs}>
          <SelectInput
            name="status"
            title={t('common:FILTER')}
            ref={register()}
            value={watchedFields.status}
            testingTag="messages-filter-status"
          >
            {FILTER_STATUS.map(opt => (
              <option key={`filter-${opt.val}`} value={opt.val}>
                {t(opt.translation)}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            name="topic"
            title={t('common:TOPIC')}
            ref={register()}
            value={watchedFields.topic}
            testingTag="messages-filter-topic"
          >
            <option key="all" value="">
              {t('common:FILTER_DROPDOWN_ALL')}
            </option>
            {messageAndBruceTopics.map(topic => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </SelectInput>

          {!membershipNo && (
            <TextInput
              name="searchByMemberText"
              labelText={t('MESSAGES_FILTER_FIELD_SEARCH_BY_MEMBER')}
              type="text"
              control={control}
              mask={(() => {
                switch (watchedFields.searchByMemberSelect) {
                  case SEARCH_BY_MEMBER[0].val:
                    return maskEmployeeId;
                  case SEARCH_BY_MEMBER[1].val:
                    return maskMembershipNumber;
                  case SEARCH_BY_MEMBER[2].val:
                    return maskName(15);
                }
                return undefined;
              })()}
              testingTag="messages-filter-member-text"
              placeholder={(() => {
                switch (watchedFields.searchByMemberSelect) {
                  case SEARCH_BY_MEMBER[0].val:
                    return t(
                      'MESSAGES_FILTER_SEARCH_BY_MEMBER_EMP_ID_PLACEHOLDER'
                    );
                  case SEARCH_BY_MEMBER[1].val:
                    return t(
                      'MESSAGES_FILTER_SEARCH_BY_MEMBER_NUM_PLACEHOLDER'
                    );
                  case SEARCH_BY_MEMBER[2].val:
                    return t(
                      'MESSAGES_FILTER_SEARCH_BY_MEMBER_LAST_NAME_PLACEHOLDER'
                    );
                }
                return '';
              })()}
              selectMinWidth={178}
            >
              <SelectInput
                name="searchByMemberSelect"
                ref={register()}
                value={watchedFields.searchByMemberSelect}
                onChange={() => setValue('searchByMemberText', '')}
                testingTag="messages-filter-member-select"
              >
                {SEARCH_BY_MEMBER.map(opt => (
                  <option key={opt.val} value={opt.val}>
                    {t(opt.translation)}
                  </option>
                ))}
              </SelectInput>
            </TextInput>
          )}

          <SelectInput
            name="contact"
            title={t('common:CONTACT')}
            ref={register()}
            value={watchedFields.contact}
            testingTag="messages-filter-contact"
          >
            <option key="all" value="">
              {t('common:FILTER_DROPDOWN_ALL')}
            </option>
            {ecorrContacts?.map(contact => (
              <option key={contact.Id} value={contact.Id}>
                {contact.LastName}, {contact.FirstName}
              </option>
            ))}
          </SelectInput>
        </div>

        <div className={styles.reset}>
          <ResetButton
            onClick={() => {
              reset({
                status: FILTER_STATUS[0].val,
                topic: '',
                searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
                searchByMemberText: '',
                contact: ''
              });
              dispatch(
                getInboxAction({
                  filter: {
                    status: FILTER_STATUS[0].val,
                    topic: '',
                    searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
                    searchByMemberText: '',
                    contact: ''
                  },
                  query: {
                    status: null,
                    topic: null,
                    employeeId: null,
                    membershipNo: membershipNo ?? null,
                    lastName: null,
                    contact: null
                  }
                })
              );
            }}
            testingTag="messages-reset-button"
            isDisabled={
              watchedFields.status === FILTER_STATUS[0].val &&
              watchedFields.topic === '' &&
              defaultMemberOptionsSelected &&
              watchedFields.contact === ''
            }
          />
        </div>

        <div className={styles.apply}>
          <Button
            isDisabled={!isApplied}
            testingTag="messages-apply-button"
            type="button"
            onClick={applyFilterHandler}
          >
            {t('common:APPLY')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default MessagesFilter;
