import cache from 'memory-cache';
import { FeatureInterface } from 'unleash-client/lib/feature';

export type CachedFeatureFlags = {
  featureFlags: Array<FeatureInterface>;
  unleashError: Error | null;
};

/**
 * Validates if `window` exists to determine if code execution is in the context of the browser
 */
export const isClientSide = () => typeof window !== 'undefined';

/**
 * Validates if `window` does not exist to determine if code execution is in the context of server-side
 */
export const isServerSide = () => typeof window === 'undefined';

/**
 * Retrieve cached feature flags state; this is intended for server-side use only as the unleash client polls latest
 * feature flags server-side and then caches them server-side exclusively -- frontend client should communicate to
 * next.js server through `/api/feature-flags` to retrieve latest flags
 * @throws `ApiError`
 */
export const getFeatureFlagsState = (filterEnabled = true) => {
  if (isClientSide()) {
    throw new Error(
      'getFeatureFlagsState() not supported within browser -- feature flags are cached server-side with unleash-client; retrieve them from `/api/feature-flags`'
    );
  }

  const cachedFeatureFlags: CachedFeatureFlags = cache.get(
    'cachedFeatureFlags'
  ) ?? {
    featureFlags: [],
    unleashError: null
  };

  return {
    featureFlags: filterEnabled
      ? cachedFeatureFlags.featureFlags.filter(ff => ff.enabled)
      : cachedFeatureFlags.featureFlags,
    unleashError: cachedFeatureFlags.unleashError
  } as CachedFeatureFlags;
};
