import Button from 'components/v2/atomic/button/Button';
import Icon, { IconSize } from 'components/v2/atomic/icon/Icon';
import { IconNamesStatus } from 'components/v2/atomic/icon/Icons';
import Modal from 'components/v2/atomic/modal/Modal';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { MFACardTypes } from '../MFACards';

const MFACardNavButtons = ({
  setCurrentCard,
  showBackToLogIn = true
}: {
  setCurrentCard: (state: MFACardTypes) => void;
  showBackToLogIn?: boolean;
}) => {
  const { t } = useTranslation('authentication');
  const [showNeedHelp, setShowNeedHelp] = useState(false);

  return (
    <>
      {showNeedHelp ? (
        <Modal
          id="mfa-need-help"
          titleText="Need Help?"
          isOpen={showNeedHelp}
          onClose={() => {
            setShowNeedHelp(false);
          }}
          footer={
            <Button
              id="mfa-need-help-close"
              onClick={() => {
                setShowNeedHelp(false);
              }}
              sxOverride={{
                margin: '0 auto 1rem',
                height: '3rem',
                width: '11.25rem'
              }}
            >
              {t('CLOSE', { ns: 'common' })}
            </Button>
          }
          modalSxOverride={{
            '>div >div >button': { paddingBottom: '0' }
          }}
          childrenSxOverride={{
            display: 'inline-block',
            flexDirection: undefined,
            alignItems: undefined
          }}
        >
          <Trans
            t={t}
            i18nKey="authentication:MFA_FOR_ASSISTANCE_PLEASE_CALL_AT"
            components={{
              span: <span sx={{ fontWeight: 600 }} />
            }}
          />
        </Modal>
      ) : null}
      <div
        sx={{
          mt: '1.5rem',
          display: 'flex',
          justifyContent: showBackToLogIn ? 'space-between' : 'end',
          alignItems: 'center'
        }}
      >
        {showBackToLogIn ? (
          <Button
            id="mfa-back-to-log-in"
            variant="simple"
            sxOverride={{ p: '0', textDecoration: 'underline' }}
            onClick={() => {
              setCurrentCard(MFACardTypes.LOGIN_FORM_CARD);
            }}
          >
            {t('MFA_BACK_TO_LOG_IN')}
          </Button>
        ) : null}
        <Button
          id="mfa-need-help"
          variant="simple"
          sxOverride={{ p: '0', textDecoration: 'underline' }}
          onClick={() => {
            setShowNeedHelp(true);
          }}
        >
          {t('MFA_NEED_HELP')}
        </Button>
      </div>
    </>
  );
};

const MFASubmitButton = ({
  id,
  loading,
  children,
  disabled
}: {
  id: string;
  loading: boolean;
  children: React.ReactNode;
  disabled?: boolean;
}) => (
  <Button
    type="submit"
    id={id}
    fill
    disabled={loading || disabled}
    sxOverride={{
      '&:disabled': loading
        ? {
            backgroundColor: theme.colors.brandOrangeWeb,
            border: theme.colors.brandOrangeWeb,
            color: theme.colors.white
          }
        : undefined
    }}
  >
    <div sx={{ display: 'flex', gap: '0.6rem', alignItems: 'center' }}>
      {loading && (
        <div
          sx={{
            '@keyframes spin': {
              from: {
                transform: 'rotate(0)'
              },
              to: {
                transform: 'rotate(360deg)'
              }
            }
          }}
        >
          <Icon
            icon={IconNamesStatus.HALF_CIRCLE_SPINNER}
            sx={{
              svg: {
                animation: `spin 1400ms linear 0s infinite running`,
                width: IconSize.SMALL,
                height: IconSize.SMALL
              }
            }}
          />
        </div>
      )}
      <span sx={{ textWrap: 'nowrap' }}>{children}</span>
    </div>
  </Button>
);

export default MFACardNavButtons;

export { MFASubmitButton };
