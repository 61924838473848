import { EapAction } from 'types';
import { getSubmissionStatus } from 'utils';
import { FormState } from 'containers/requestForm/common/interfaces';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { form102ActionTypes } from './form102Actions';

export interface LeavePeriod {
  leaveStartDate: string;
  leaveEndDate: string;
  typeOfLeave: string;
  leaveTypeDetails: string;
}

export interface Form102Data {
  address1: string;
  address2: string;
  apartmentUnit: string;
  birthDateDay: string;
  birthDateMonth: string;
  birthDateYear: string;
  city: string;
  country: string;
  departmentID: string;
  email: string;
  employeeId: string;
  employmentStatus: string;
  enrolSameAsHire: boolean;
  enrolDateMonth: string;
  enrolDateDay: string;
  enrolDateYear: string;
  enrolDate: string;
  enrolReason: string;
  firstName: string;
  sex: string; // 'M' | 'F' | 'X';
  hasDivestment: string;
  hasPriorPension: string;
  hireDate: string;
  hireDateDay: string;
  hireDateMonth: string;
  hireDateYear: string;
  electionDate: string;
  lastName: string;
  memberAffiliation: string;
  middleName: string;
  normalRetirementAge: string;
  notes: string;
  occupation: string;
  paperlessConsent: string;
  postal: string;
  mobilePhone: string;
  priorPensionDateDay: string;
  priorPensionDateMonth: string;
  priorPensionDateYear: string;
  priorPensionDate: string;
  priorPensionName: string;
  province: string;
  salary: string;
  salaryRate: string;
  homePhone: string;
  signedForm132: string;
  sin: string;
  noteToOmers: string;
  percentageTimeWorked: string;
  periodNotWorked: { from: string | null; to: string | null };
  leavePeriods: Array<LeavePeriod>;
  leavePeriodIndex: number;
  purchasableService: string;
}

export interface Form102State extends FormState {
  form102Data: Form102Data;
  employerNo?: string;
  validAddressState: 'valid' | 'invalid' | 'not-validated';
  validAddress: {
    address1: string;
    country: string;
    province: string;
    city: string;
    postal: string;
  };
  validEmailAddressState: QASEmailResponseTypes | string;
  validEmailAddress: {
    email: string;
  };
  homePhoneValidityStatus: string;
  mobilePhoneValidityStatus: string;
}

export const initialState = {
  initLoading: false,
  form102Data: {
    address1: '',
    address2: '',
    apartmentUnit: '',
    birthDateDay: '',
    birthDateMonth: '',
    birthDateYear: '',
    city: '',
    country: 'CAN',
    departmentID: '',
    email: '',
    employeeId: '',
    employmentStatus: 'C',
    enrolSameAsHire: true,
    enrolDateMonth: '',
    enrolDateDay: '',
    enrolDateYear: '',
    enrolDate: '',
    enrolReason: '',
    firstName: '',
    sex: '',
    hasDivestment: 'N',
    hasPriorPension: 'N',
    hireDateDay: '',
    hireDateMonth: '',
    hireDateYear: '',
    hireDate: '',
    electionDate: '',
    lastName: '',
    memberAffiliation: '',
    middleName: '',
    normalRetirementAge: '',
    notes: '',
    occupation: '',
    paperlessConsent: 'N',
    postal: '',
    mobilePhone: '',
    priorPensionDateDay: '',
    priorPensionDateMonth: '',
    priorPensionDateYear: '',
    priorPensionDate: '',
    priorPensionName: '',
    province: '',
    salary: 'A',
    salaryRate: '',
    homePhone: '',
    signedForm132: 'N',
    sin: '',
    noteToOmers: '',
    percentageTimeWorked: '',
    periodNotWorked: { from: null, to: null },
    leavePeriods: [
      {
        leaveStartDate: '',
        leaveEndDate: '',
        typeOfLeave: '',
        leaveTypeDetails: ''
      }
    ],
    leavePeriodIndex: 0,
    purchasableService: ''
  },
  formStep: 1,
  requestNumber: null,
  formStatus: '' as const,
  submissionStatus: '' as const,
  isDirty: false,
  exceptions: [],
  submissionComplete: false,
  overrideExceptions: [],
  validAddressState: 'not-validated' as const,
  validAddress: {
    address1: '',
    country: 'CAN',
    province: '',
    city: '',
    postal: ''
  },
  validEmailAddressState: QASEmailResponseTypes.NOT_VERIFIED as const,
  validEmailAddress: {
    email: ''
  },
  homePhoneValidityStatus: '',
  mobilePhoneValidityStatus: ''
};

const form102 = (
  state: Form102State = initialState,
  action: EapAction
): Form102State => {
  const { type, data } = action;

  switch (type) {
    case form102ActionTypes.SET_FORM_102_REQUEST_NUMBER:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: !!data
      };
    case form102ActionTypes.CREATE_FORM_102_SUCCESS:
      return {
        ...state,
        requestNumber: data.requestNumber,
        employerNo: data.employerNo,
        form102Data: data.formData,
        formStatus: 'success' as const
      };
    case form102ActionTypes.CREATE_FORM_102_FAILED:
    case form102ActionTypes.SAVE_FORM_102_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form102ActionTypes.CREATE_FORM_102:
    case form102ActionTypes.SAVE_FORM_102:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form102ActionTypes.SAVE_FORM_102_SUCCESS:
      return {
        ...state,
        form102Data: data,
        formStatus: 'success' as const
      };
    case form102ActionTypes.GET_FORM_102_SUCCESS:
      return {
        ...state,
        employerNo: data.employerNo,
        initLoading: false,
        formStatus: '' as const,
        submissionComplete: false,
        submissionStatus: '' as const,
        formStep: 1,
        requestNumber: data.requestNumber,
        form102Data: data.form102Data,
        exceptions: data.exceptions,
        overrideExceptions: [],
        validAddressState: data.validAddressState,
        validAddress: data.validAddress,
        validEmailAddressState: data.validEmailAddressState,
        validEmailAddress: data.validEmailAddress
      };
    case form102ActionTypes.GET_FORM_102:
    case form102ActionTypes.GET_FORM_102_FAILED:
      return {
        ...state,
        initLoading: true
      };
    case form102ActionTypes.SET_FORM_102_STEP:
      return {
        ...state,
        formStep: data
      };
    case form102ActionTypes.SET_FORM_102_SAVING_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form102ActionTypes.SET_FORM_102_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form102ActionTypes.OVERRIDE_FORM_102_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form102ActionTypes.SUBMIT_FORM_102_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        initLoading: true,
        pdfs: data.attachments
      };
    case form102ActionTypes.DELETE_FORM_102_SUCCESS:
      return {
        ...state,
        formStatus: 'deleted' as const
      };
    case form102ActionTypes.SET_VALID_ADDRESS_FORM_102:
      return {
        ...state,
        validAddressState: 'valid' as const,
        validAddress: data
      };
    case form102ActionTypes.SET_VALID_ADDRESS_STATE_FORM_102:
      return {
        ...state,
        validAddressState: data
      };
    case form102ActionTypes.SET_VALID_EMAIL_ADDRESS_FORM_102:
      return {
        ...state,
        validEmailAddressState: QASEmailResponseTypes.VERIFIED as const,
        validEmailAddress: data
      };
    case form102ActionTypes.SET_VALID_EMAIL_ADDRESS_STATE_FORM_102:
      return {
        ...state,
        validEmailAddressState: data
      };
    case form102ActionTypes.SET_PHONE_NUMBER_VALIDITY_STATUS:
      return {
        ...state,
        homePhoneValidityStatus:
          data.phoneNumberType === 'H'
            ? data.phoneNumberValidity
            : state.homePhoneValidityStatus,
        mobilePhoneValidityStatus:
          data.phoneNumberType === 'M'
            ? data.phoneNumberValidity
            : state.mobilePhoneValidityStatus
      };
    default:
      return state;
  }
};

export default form102;
