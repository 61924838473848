import { all, takeLatest, put, call } from 'redux-saga/effects';

import { EapAction } from 'types';

import { getApiErrorMessage, getApiErrorMessageByCode } from 'utils';

import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';

import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';

import { updatePassword } from 'services';
import {
  updatePasswordActionTypes,
  submitUpdatePasswordSuccessAction,
  submitUpdatePasswordFailedAction
} from './updatePasswordActions';

export function* submitUpdatePasswordSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(updatePassword, action.data);

  if (res.data) {
    yield put(clearNotifications());
    yield put(submitUpdatePasswordSuccessAction());
  } else if (res.errors) {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: res.errors[0].code,
            msg: getApiErrorMessageByCode(res.errors[0].code),
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  } else {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: 'GENERIC_ERROR',
            msg: getApiErrorMessageByCode('GENERIC_ERROR'),
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  }

  yield put(clearLoadingAction());
}

export function* updatePasswordSaga(): any {
  yield all([
    yield takeLatest(
      updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_REQUESTED,
      submitUpdatePasswordSaga
    )
  ]);
}

export default updatePasswordSaga;
