import { Classes } from '@blueprintjs/core';
import Button from 'components/v2/atomic/button/Button';
import Drawer from 'components/v2/atomic/drawer/Drawer';
import Icon from 'components/v2/atomic/icon/Icon';
import { isEmpty, kebabCase } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { get } from 'theme-ui';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import AccordionMenuItem from '../accordionMenuItem/AccordionMenuItem';
import {
  BurgerMenuButton,
  BurgerMenuItemType,
  BurgerMenuLink,
  BurgerMenuText
} from '../mainNav/BurgerMenu';
import { NavMenuItem } from '../mainNav/MainNav';
import {
  convertHyphenatedIDToTitle,
  getIconFromSubItem,
  NavPageItems,
  sortObjectStructure
} from './utils/utils';

const DynamicBurgerNav = ({
  burgerMenuItems,
  isBurgerMenuOpen,
  setIsBurgerMenuOpen,
  footerButton,
  navStructure,
  sortedNavKeys
}: {
  burgerMenuItems: NavMenuItem[];
  isBurgerMenuOpen: boolean;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
  footerButton: React.ReactNode;
  navStructure: Record<string, NavPageItems>;
  sortedNavKeys: string[];
}) => {
  const breakpoint = useBreakpoint();
  const Router = useRouter();

  const getMenuItemType = (menuItem: NavMenuItem) => {
    if (menuItem.burgerMenuItemType) {
      return menuItem.burgerMenuItemType;
    }
    if (menuItem.isDropdown) {
      return BurgerMenuItemType.DROPDOWN;
    }
    return BurgerMenuItemType.PLAIN_TEXT;
  };
  return (
    <>
      {!isEmpty(navStructure) && (
        <Drawer
          isOpen={isBurgerMenuOpen}
          onClose={() => {
            setIsBurgerMenuOpen(false);
          }}
          isActionButtonsEnabled={false}
          canEscapeKeyClose
          canOutsideClickClose
          sx={{
            [`& .${Classes.DRAWER_BODY} > div`]: {
              padding: '0'
            }
          }}
          footerAlign={breakpoint.isLessThanSmallMin ? 'fill' : 'center'}
          footer={footerButton}
          isFooterSticky
        >
          <ul
            sx={{
              '>li': {
                borderBottom: t => `1px solid ${get(t, 'colors.grey40')}`,
                width: '100%',
                backgroundColor: 'none',
                '& a, & .accordionMenuItem': {
                  p: '1rem 2rem',
                  display: 'block',
                  height: '100%',
                  width: '100%',
                  textDecoration: 'none',
                  color: 'brandNavy100'
                },
                '&:hover': {
                  backgroundColor: 'extendedBlue25'
                },
                '& a:active, & .accordionMenuItem:active': {
                  color: 'extendedBlue100',
                  textDecoration: 'none'
                },
                '& a:focus, & .accordionMenuItem:focus': {
                  outline: 'none'
                },
                '& a:focus-visible, & .accordionMenuItem:focus-visible': {
                  outline: '2px solid',
                  outlineColor: 'extendedBlue100',
                  outlineOffset: '-2px'
                }
              }
            }}
          >
            {burgerMenuItems?.map((menuItem: NavMenuItem) => (
              <li sx={{ border: 'none !important' }} key={menuItem.id}>
                {getMenuItemType(menuItem) === BurgerMenuItemType.DROPDOWN && (
                  <AccordionMenuItem
                    id={menuItem.id}
                    icon={menuItem.icon}
                    links={
                      menuItem?.subMenuItems
                        ?.filter(item => !item.hideItem)
                        .map(item => ({
                          ...item,
                          onClick: () => {
                            if (item.onClick) {
                              item.onClick();
                              setIsBurgerMenuOpen(false);
                              return;
                            }
                            if (item.url && item.openInNewTab) {
                              window.open(
                                item.url,
                                '_blank',
                                'noopener, noreferrer'
                              );
                              setIsBurgerMenuOpen(false);
                            } else if (item.url && !item.openInNewTab) {
                              Router.push(item.url);
                              setIsBurgerMenuOpen(false);
                            }
                          }
                        })) ?? []
                    }
                    title={menuItem.buttonText as string}
                    sxOverride={{
                      borderTop: 'none !important',
                      borderBottom: 'none !important'
                    }}
                    activeLink={menuItem.activeLink ?? ''}
                  />
                )}

                {getMenuItemType(menuItem) === BurgerMenuItemType.LINK && (
                  <BurgerMenuLink
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                    url={menuItem.url ?? '/'}
                    openInNewTab={menuItem.openInNewTab}
                    id={menuItem.id}
                    setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                  />
                )}

                {getMenuItemType(menuItem) === BurgerMenuItemType.BUTTON && (
                  <BurgerMenuButton
                    id={menuItem.id}
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                    onClick={menuItem.onClick as () => void}
                    setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                  />
                )}

                {getMenuItemType(menuItem) ===
                  BurgerMenuItemType.STYLED_TEXT && (
                  <BurgerMenuText
                    id={menuItem.id}
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                  />
                )}

                {(menuItem.burgerMenuItemType ===
                  BurgerMenuItemType.PLAIN_TEXT ||
                  (!menuItem.burgerMenuItemType && !menuItem.isDropdown)) && (
                  <>{menuItem.buttonText}</>
                )}
              </li>
            ))}
            {sortedNavKeys.map(key => {
              if (
                navStructure[key].title &&
                navStructure[key].slug &&
                navStructure[key].hideItem
              ) {
                return null;
              }
              if (!navStructure[key].title && !navStructure[key].slug) {
                const iconName = getIconFromSubItem(navStructure, key);
                return (
                  <AccordionMenuItem
                    sxOverride={{
                      borderTop: 'none !important'
                    }}
                    links={[]}
                    title={convertHyphenatedIDToTitle(key)}
                    isNewNavLinks
                    newNavLinks={
                      (navStructure[key] as unknown) as Record<
                        string,
                        NavPageItems
                      >
                    }
                    activeLink=""
                    id={key}
                    icon={iconName}
                    sortedNewNavLinkKeys={sortObjectStructure(
                      (navStructure[key] as unknown) as Record<
                        string,
                        NavPageItems
                      >
                    )}
                  />
                );
              }
              return (
                <li key={`dynamic-burger-menu-item-${kebabCase(key)}`}>
                  <Link
                    className="accordionMenuItem"
                    href={new URL(navStructure[key].slug, window.origin)}
                    sx={{
                      textAlign: 'left',
                      '&:active:not(:disabled)': {
                        textDecoration: 'none'
                      }
                    }}
                  >
                    <Button
                      id={`user-accordion-${navStructure[key].title}`}
                      variant="simple"
                      className="accordionMenuItem"
                      onClick={() => {
                        setIsBurgerMenuOpen(false);
                      }}
                      sx={{
                        textAlign: 'left',
                        '&:active:not(:disabled)': {
                          textDecoration: 'none'
                        },
                        padding: '0 !important'
                      }}
                    >
                      <div
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '0.75rem',
                          color: 'brandNavy100'
                        }}
                      >
                        {navStructure[key].icon && (
                          <Icon icon={navStructure[key].icon} />
                        )}
                        {navStructure[key].title}
                      </div>
                    </Button>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Drawer>
      )}
    </>
  );
};
export default DynamicBurgerNav;
