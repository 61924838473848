import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames/bind';

import { EXTERNAL_URLS } from 'utils/constants';
import useRole, { RoleTypes } from 'utils/hooks/useRole';
import { RootState, EmployerGroup } from 'types';
import Modal from 'components/modal/Modal';
import NavDropdown from 'components/navDropdown/NavDropdown';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';
import Spinner from 'components/spinner/Spinner';
import {
  logoutAction,
  setShowEmailNotificationModalAction,
  eCorrespondenceOptOutAction,
  logoutForcedAction
} from 'containers/auth/userSessionActions';
import {
  selectEmployerDropdownAction,
  showAccountLockedModalAction
} from 'containers/selectEmployers/selectEmployersActions';
import { featureFlagSelector, tokenSelector } from 'utils';

import { MicrosoftBookingsDrawer } from 'components/microsoftBookingsDrawer/MicrosoftBookingsDrawer';
import Button from 'components/button/Button';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { UserExpiryTimer } from 'containers/userExpiryTimer/UserExpiryTimer';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesStatus } from 'components/v2/atomic/icon/Icons';
import { showTerms } from 'utils/cobrowse';
import { setIsOpen } from 'components/microsoftBookingsDrawer/microsoftBookingsDrawerReducer';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import styles from './NavHeader.module.scss';

const cx = classNames.bind(styles);

interface SelectMemberDisplay {
  text: string;
  value: string;
  id: number;
  callback: Function;
  attestationRequired: boolean;
  smoRole: boolean;
}

const NavHeader = ({ withNav = false }) => {
  const dispatch = useDispatch();
  const Router = useRouter();
  const { t } = useTranslation('header');
  const { isFinancial, isSMO, isNonFinancial } = useRole();
  const {
    isAuthenticated,
    userData,
    showEmailNotificationModal,
    showEmailNotificationRow,
    showEmailNotificationSpinner,
    eCorrespondenceOptOut
  } = useSelector((state: RootState) => state.userSession);
  const {
    selectEmployerName,
    selectEmployerNumber,
    selectGroupNumber,
    groups
  } = useSelector((state: RootState) => state.selectEmployers);
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const token = useSelector(tokenSelector);

  const { featureFlags } = useSelector(featureFlagSelector);

  const [emailNotificationOptin, setEmailNotificationOptin] = useState(
    !eCorrespondenceOptOut
  );

  const [showContactModal, setShowContactModal] = useState(false);

  const smoOnlyView =
    isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
    isSMO &&
    !isFinancial &&
    !isNonFinancial;

  const isEmployerBookingsEnabled = isEnabled(
    featureFlags,
    currentFlags.EMPLOYER_BOOKINGS
  );

  const ContactInfo = () => (
    <div className={styles.contactRow}>
      <button
        type="button"
        onClick={() => {
          setShowContactModal(true);
        }}
      >
        {t('HEADER_USER_DROPDOWN_CONTACT_US')}
      </button>
    </div>
  );

  const CobrowseLauncher = () => (
    <div className={styles.contactRow}>
      <button type="button" onClick={() => showTerms(dispatch, t)}>
        {t('COBROWSE_BUTTON')}
      </button>
    </div>
  );

  const helpDropdown = [
    {
      text: t('HEADER_HELP_DROPDOWN_ADMIN_MANUAL'),
      id: 0,
      link: EXTERNAL_URLS.OMERS_EAM_HOMEPAGE
    },
    {
      text: t('HEADER_MICROSOFT_BOOKINGS'),
      id: 10,
      callback: () => dispatch(setIsOpen(true))
    },
    {
      text: <CobrowseLauncher />,
      id: 1
    },
    {
      text: <ContactInfo />,
      id: 2
    }
  ].filter(
    item =>
      (item.id !== 1 && item.id !== 10) ||
      (item.id === 1 && isEnabled(featureFlags, currentFlags.COBROWSE)) ||
      (item.id === 10 && isEmployerBookingsEnabled)
  );

  const EmailNotification = () => (
    <div className={styles.emailNotificationRow}>
      <span>{t('HEADER_USER_DROPDOWN_EMAIL_NOTIFICATIONS')}</span>
      <button
        type="button"
        onClick={() => {
          dispatch(setShowEmailNotificationModalAction(true));
        }}
      >
        <ReactSVG src="/images/question-circle.svg" />
      </button>
      {showEmailNotificationSpinner ? (
        <div className={styles.spinner}>
          <Spinner size="small" isFullScreen={false} />
        </div>
      ) : (
        <ToggleSwitch
          name="email notification optout"
          checked={emailNotificationOptin}
          onChange={() => {
            dispatch(eCorrespondenceOptOutAction(emailNotificationOptin));
          }}
        />
      )}
    </div>
  );

  const userReviewLink =
    isSMO && isEnabled(featureFlags, currentFlags.SMO_ATTESTATION)
      ? [
          {
            text:
              isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) && isSMO
                ? t('HEADER_USER_DROPDOWN_USER_LIST')
                : null,
            id: 2,
            link: '/smo/review-user-list',
            sameTab: true
          }
        ]
      : [];

  const userDropdown =
    showEmailNotificationRow && (isFinancial || isSMO)
      ? [
          { text: <EmailNotification />, id: 0 },
          { text: null, divider: true, id: 1 },
          ...userReviewLink,
          {
            text: t('HEADER_USER_DROPDOWN_SECURITY'),
            id: 3,
            callback: () => Router.push('/security'),
            sameTab: true
          },
          {
            text: t('HEADER_USER_DROPDOWN_PRIVACY'),
            id: 4,
            link: EXTERNAL_URLS.OMERS_PRIVACY_STATEMENT
          },
          {
            text: t('HEADER_USER_DROPDOWN_CHANGE_LOGOUT'),
            id: 5,
            callback: () =>
              dispatch(logoutAction({ logoutNotification: 'success' }))
          }
        ]
      : [
          {
            text: t('HEADER_USER_DROPDOWN_SECURITY'),
            id: 7,
            link: '/security',
            sameTab: true
          },
          {
            text: t('HEADER_USER_DROPDOWN_PRIVACY'),
            id: 8,
            link: EXTERNAL_URLS.OMERS_PRIVACY_STATEMENT
          },
          {
            text: t('HEADER_USER_DROPDOWN_CHANGE_LOGOUT'),
            id: 9,
            callback: () =>
              dispatch(logoutAction({ logoutNotification: 'success' }))
          }
        ];

  const groupOptions: Array<SelectMemberDisplay> = groups.map(
    (item: EmployerGroup) => ({
      text: item.name,
      value: item.groupNumber,
      id: Number(item.employerNumber),
      attestationRequired: item.attestationRequired ?? false,
      smoRole: item.role === RoleTypes.SMO,
      callback: () => {
        if (
          isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
          item.role === RoleTypes.SMO
        ) {
          dispatch(
            selectEmployerDropdownAction({
              name: item.name,
              employerNumber: item.employerNumber,
              groupNumber: item.groupNumber
            })
          );
          return;
        }

        if (
          isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
          item.role !== RoleTypes.SMO &&
          item.attestationRequired
        ) {
          dispatch(
            showAccountLockedModalAction({
              showModal: true,
              showModalFor: item.name
            })
          );
          return;
        }

        dispatch(
          selectEmployerDropdownAction({
            name: item.name,
            employerNumber: item.employerNumber,
            groupNumber: item.groupNumber
          })
        );
      }
    })
  );

  const displayGroupMap: Record<string, SelectMemberDisplay> = {};
  groupOptions.forEach(group => {
    if (
      !Object.prototype.hasOwnProperty.call(
        displayGroupMap,
        `${group.value}`
      ) ||
      group.smoRole
    ) {
      displayGroupMap[`${group.value}`] = group;
    }
  });

  useEffect(() => {
    setEmailNotificationOptin(!eCorrespondenceOptOut);
  }, [eCorrespondenceOptOut]);

  const navHeaderClassNames = cx({
    navHeader: true,
    withNav
  });

  return (
    <header className={navHeaderClassNames}>
      {isEmployerBookingsEnabled && <MicrosoftBookingsDrawer />}

      <div className={styles.linksRow}>
        <Link href={isAuthenticated ? '/home' : '/'} className={styles.logo}>
          <ReactSVG src="/images/logos/logo-e-access.svg" />
          <span className={styles.logoText}>{t('HEADER_TITLE')}</span>
        </Link>
        <span className={styles.divider} />
        <span className={styles.logo}>
          <ReactSVG src="/images/logos/omers.svg" />
        </span>
      </div>

      {!isAuthenticated && Router.pathname !== '/help' && (
        <div className={styles.linksRow}>
          <Link href="/help" className={styles.link}>
            {t('HEADER_LINK_HELP')}
          </Link>
        </div>
      )}

      {!isAuthenticated && Router.pathname === '/help' && (
        <div className={styles.linksRow}>
          <div
            className={cx({
              link: true,
              linkDisabled: true
            })}
          >
            {t('HEADER_LINK_HELP')}
          </div>
        </div>
      )}

      {isAuthenticated && (
        <div className={styles.linksRow}>
          {selectEmployerName !== '' && selectEmployerNumber !== 0 && (
            <>
              {userData.omersUser && (
                <Link href="/select-employers" className={styles.member}>
                  <div className={cx({ semiBold: true, employerName: true })}>
                    {selectEmployerName}
                  </div>{' '}
                  <span className={styles.memberNum}>{selectGroupNumber}</span>
                </Link>
              )}
              {!userData.omersUser && groupOptions.length <= 1 && (
                <div className={styles.member}>
                  <div className={cx({ semiBold: true, employerName: true })}>
                    {selectEmployerName}
                  </div>{' '}
                  <span className={styles.memberNum}>{selectGroupNumber}</span>
                </div>
              )}
              {!userData.omersUser && groupOptions.length > 1 && (
                <NavDropdown
                  positionRightPerc={15}
                  data-testid="select-employer-dropdown"
                  displayText={
                    <span className={styles.member}>
                      <div
                        className={cx({ semiBold: true, employerName: true })}
                      >
                        {selectEmployerName}
                      </div>{' '}
                      <span className={styles.memberNum}>
                        {selectGroupNumber}
                      </span>
                    </span>
                  }
                  displayList={Object.values(displayGroupMap)
                    .filter(item => item.id !== Number(selectEmployerNumber))
                    .map(item => ({
                      ...item,
                      text: (
                        <span className={styles.member}>
                          <div
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {isEnabled(
                              featureFlags,
                              currentFlags.SMO_ATTESTATION
                            ) &&
                              item.attestationRequired &&
                              !item.smoRole && (
                                <Icon
                                  icon={IconNamesStatus.DANGER}
                                  sx={{ mr: '1rem' }}
                                  data-testid={`danger-icon-${item.value}`}
                                />
                              )}
                            {item.text}{' '}
                          </div>
                          <span className={styles.memberNum}>{item.value}</span>
                        </span>
                      )
                    }))}
                />
              )}
              <span className={styles.divider} />
            </>
          )}

          {(selectEmployerNumber || selectGroupNumber) && (
            <>
              {!smoOnlyView && (
                <>
                  <div
                    className={cx({
                      link: true,
                      learningCenterLink: true
                    })}
                  >
                    <Button
                      baseStyle="link"
                      disableSvgStroke
                      onClick={() => {
                        if (!token) dispatch(logoutForcedAction());
                        else
                          window.open(
                            `${env('NEXT_PUBLIC_PING_AUTH_BASE_URL')}${env(
                              'NEXT_PUBLIC_PING_D2L_SSO_URL'
                            )}`,
                            '_blank'
                          );
                      }}
                      isDisabled={omersUser}
                    >
                      {t('LEARNING_CENTER_LINK')}
                    </Button>
                  </div>

                  <span className={styles.divider} />
                </>
              )}

              <NavDropdown
                displayText={t('HEADER_LINK_HELP')}
                displayList={helpDropdown}
                positionRightPerc={10}
              />

              <span className={styles.divider} />

              <NavDropdown
                displayText={`${userData.firstName} ${userData?.lastName}`}
                displayList={userDropdown}
              />

              {isEnabled(featureFlags, currentFlags.SESSION_TIMER) && (
                <UserExpiryTimer />
              )}
            </>
          )}

          {!(selectEmployerNumber || selectGroupNumber) && (
            <button
              type="button"
              className={`${styles.link}`}
              onClick={() =>
                dispatch(logoutAction({ logoutNotification: 'success' }))
              }
            >
              {t('HEADER_LINK_LOGOUT')}
            </button>
          )}
          {showEmailNotificationModal && (
            <Modal
              titleText={t('HEADER_USER_DROPDOWN_EMAIL_NOTIFICATIONS')}
              visible
              width={532}
              isCancel={false}
              isOk={false}
              onClose={() =>
                dispatch(setShowEmailNotificationModalAction(false))
              }
            >
              <p>{t('HEADER_USER_DROPDOWN_EMAIL_NOTIFICATIONS_MODAL')}</p>
            </Modal>
          )}
          {showContactModal && (
            <Modal
              titleText={t('HEADER_USER_DROPDOWN_CONTACT_US')}
              visible
              width={532}
              isCancel={false}
              isOk={false}
              onClose={() => setShowContactModal(false)}
            >
              <p>
                {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_1')}
                <span className="semiBold">
                  {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_2')}
                </span>
                {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_3')}
                <span className="semiBold">
                  {t('HEADER_USER_DROPDOWN_CONTACT_US_MODAL_4')}
                </span>
              </p>
            </Modal>
          )}
        </div>
      )}
    </header>
  );
};

export default NavHeader;
