import { DonnaApiExceptionErrorInfo } from '@omers-packages/next-donna-gateway-handler';
import { JWTUser } from 'utils/ping/types';

export enum MFAErrorCodes {
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  ACCESS_DENIED = 'ACCESS_DENIED',
  MAX_SEND_ATTEMPTS_REACHED = 'MAX_SEND_ATTEMPTS_REACHED',
  MAX_CHECK_ATTEMPTS_REACHED = 'MAX_CHECK_ATTEMPTS_REACHED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  UNKNOWN_ERROR_CODE = 'UNKNOWN_ERROR_CODE',
  VERIFICATION_NOT_FOUND = 'VERIFICATION_NOT_FOUND' // when a verification expires before calling a check verification api call
}

export interface MFACache {
  accessToken: string;
  userIp: string;
  userProfile: JWTUser;
  createdAt: string;
  updatedAt: string | null;
  mfaCompletedAt: string | null;
}

export type TwilioDonnaError = DonnaApiExceptionErrorInfo & {
  verificationTTL: number;
};
