import Button from 'components/v2/atomic/button/Button';
import Modal from 'components/v2/atomic/modal/Modal';
import {
  hideSessionRefreshModal,
  logoutAction,
  logoutForcedAction,
  sessionRefreshedAction,
  setTokenExpiryTimerVisibility
} from 'containers/auth/userSessionActions';
import {
  sessionRefreshModalSelector,
  tokenExpiryTimerSelector
} from 'containers/auth/userSessionSelectors';
import { format } from 'date-fns';
import { showWarningToast } from 'features/v2/toaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { getTokenExpiry, isAuthenticated, tokenSelector } from 'utils';

const FIVE_MINUTES = 300_000;
const THIRTY_MINUTES = 1_800_000;

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('header');

  return (
    <>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'center',
          px: '40px',
          gap: '20px'
        }}
      >
        <Button
          fill
          variant="outline"
          id="expiry_timer_refresh_button"
          onClick={() => {
            dispatch(hideSessionRefreshModal());
            dispatch(logoutAction({ logoutNotification: 'success' }));
          }}
        >
          {t('HEADER_LINK_LOGOUT')}
        </Button>
        <Button
          fill
          id="expiry_timer_refresh_button"
          onClick={() => {
            dispatch(hideSessionRefreshModal());
            dispatch(sessionRefreshedAction(true));
          }}
        >
          {t('SESSION_TIMER_MODAL_REFRESH_BUTTON')}
        </Button>
      </div>
    </>
  );
};

const TimerWidget = ({
  timerDuration = THIRTY_MINUTES,
  remainingTime
}: {
  timerDuration?: number;
  remainingTime: number | null;
}) => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthenticated);
  const [thirtyMinuteToastTriggered, setThirtyMinuteToastTriggered] = useState(
    false
  );
  const [fiveMinuteToastTriggered, setFiveMinuteToastTriggered] = useState(
    false
  );

  if (
    remainingTime &&
    remainingTime <= THIRTY_MINUTES &&
    remainingTime > THIRTY_MINUTES - 2000 &&
    !thirtyMinuteToastTriggered
  ) {
    dispatch(
      showWarningToast({
        message: t('TOKEN_EXPIRY_THIRTY_MINUTE_WARNING')
      })
    );
    setThirtyMinuteToastTriggered(true);
  }
  if (
    remainingTime &&
    remainingTime <= FIVE_MINUTES &&
    remainingTime > FIVE_MINUTES - 2000 &&
    !fiveMinuteToastTriggered
  ) {
    dispatch(
      showWarningToast({
        message: t('TOKEN_EXPIRY_FIVE_MINUTE_WARNING')
      })
    );
    setFiveMinuteToastTriggered(true);
  }

  if (isAuth && remainingTime !== null && remainingTime <= 1) {
    dispatch(setTokenExpiryTimerVisibility(false));
    dispatch(logoutForcedAction({ logoutNotification: 'expired' }));
  }

  return (
    <div
      className="nav-timer"
      data-testid="countdown-timer"
      sx={{
        width: 'fit-content',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px'
      }}
    >
      <div
        sx={{
          fontSize: '0.75rem',
          color: 'grey100',
          lineHeight: '1',
          verticalAlign: 'bottom'
        }}
      >
        {t('EXPIRE_IN')}
      </div>
      <div
        sx={{
          fontSize: 'small',
          fontWeight: 'semibold',
          lineHeight: '1',
          color:
            remainingTime && remainingTime <= FIVE_MINUTES
              ? 'extendedRed100'
              : 'extendedBlue100'
        }}
      >
        {remainingTime && format(remainingTime, 'mm:ss')}
      </div>
      <progress
        value={remainingTime ? Math.floor(remainingTime / 1000) : 0}
        max={timerDuration / 1000}
        sx={{
          width: '48px',
          height: '6px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: 'grey60',
          '&::-webkit-progress-bar': {
            borderRadius: '10px',
            backgroundColor: 'grey60'
          },
          '&::-webkit-progress-value': {
            borderRadius: '10px',
            backgroundColor:
              remainingTime && remainingTime <= FIVE_MINUTES
                ? 'extendedRed100'
                : 'extendedBlue100'
          },
          '&::-moz-progress-bar': {
            borderRadius: '10px',
            backgroundColor:
              remainingTime && remainingTime <= FIVE_MINUTES
                ? 'extendedRed100'
                : 'extendedBlue100'
          }
        }}
      />
    </div>
  );
};

export const UserExpiryTimer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const showSessionRefreshModal = useSelector(sessionRefreshModalSelector);
  const showTokenExpiryTimer = useSelector(tokenExpiryTimerSelector);
  const accessToken = useSelector(tokenSelector);
  const tokenExpiry = getTokenExpiry(accessToken) * 1000;
  const [timer, setTimer] = useState<null | number>(null);

  useEffect(() => {
    const getTimer = () => {
      const remainingTimeInMillis = tokenExpiry - Date.now();
      if (remainingTimeInMillis <= THIRTY_MINUTES) {
        setTimer(remainingTimeInMillis);
        if (!showTokenExpiryTimer) {
          dispatch(setTokenExpiryTimerVisibility(true));
          dispatch(sessionRefreshedAction(true));
        }
      }
    };
    const interval = setInterval(() => getTimer(), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, showTokenExpiryTimer, tokenExpiry]);

  return (
    <>
      {showTokenExpiryTimer && (
        <>
          <span
            sx={{
              borderLeft: '1px solid',
              borderColor: 'grey60',
              height: '55%'
            }}
          />
          <div
            sx={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              padding: '0 24px',

              '@media (max-width: $xlarge)': {
                padding: '0 15px'
              },

              animationName: 'slideInFromRight',
              animationDuration: '0.3s',
              animationFillMode: 'forwards',

              '@keyframes slideInFromRight': {
                from: {
                  transform: 'translateX(100%)'
                },
                to: {
                  transform: 'translateX(0)'
                }
              }
            }}
          >
            <TimerWidget remainingTime={timer} />
          </div>
        </>
      )}
      <Modal
        id="session_refresh_modal"
        titleText={t('SESSION_TIMER_MODAL_TITLE')}
        isOpen={showSessionRefreshModal}
        hideCloseButton
        preventCloseOnBackdropClick
        preventCloseOnEscape
        footer={<Footer />}
      >
        <div>
          <ReactSVG src="/images/session-timeout.svg" sx={{ mb: '30px' }} />
          <p sx={{ fontSize: 'base' }}>{t('SESSION_TIMER_MODAL_BODY')}</p>
        </div>
      </Modal>
    </>
  );
};
