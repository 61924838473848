import useBreakpoint from 'utils/hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import Button from 'components/v2/atomic/button/Button';
import { useRouter } from 'next/router';
import { logoutAction } from 'containers/auth/userSessionActions';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contentful } from 'modules/contentful';
import { isEmpty } from 'lodash';
import logger from 'utils/logger/server-side';
import { RootState } from 'types';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { MainNavButtonProps } from '../mainNavButton/MainNavButton';
import Header from './Header';
import BurgerMenu, { BurgerMenuItemType } from './BurgerMenu';
import { buildNavTreeV3, NavPageItems } from '../dynamicNav/utils/utils';
import DynamicNav from '../dynamicNav/DynamicNav';
import DynamicBurgerNav from '../dynamicNav/DynamicBurgerNav';
import BottomNavigation from './BottomNavigation';

export type NavMenuItem = Omit<
  MainNavButtonProps,
  'buttonColor' | 'buttonText'
> & {
  activeLink?: string;
  buttonText: string | JSX.Element;
  showIconInNav?: boolean;
  hideItem?: boolean;
  burgerMenuItemType?: BurgerMenuItemType;
};

interface MainNavProps {
  topLinks?: NavMenuItem[];
  showSignInButton?: boolean;
  showSignOutButton?: boolean;
  burgerMenuItems?: NavMenuItem[];
  includeWrapper?: boolean;
  stickyTopBar?: boolean;
  bottomNavBGColor?: string;
  menuItems?: NavMenuItem[];
}

const MainNav = ({
  topLinks,
  burgerMenuItems,
  showSignInButton = true,
  showSignOutButton = false,
  includeWrapper = true,
  stickyTopBar = false,
  bottomNavBGColor,
  menuItems
}: MainNavProps) => {
  const breakpoint = useBreakpoint();
  const Router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contentfulPageData, setContentfulPageData] = useState<
    NavPageItems[] | null
  >(null);

  const fetchContentfulPages = async () => {
    try {
      const data = await contentful.entries.get({
        content_type: 'page',
        select: 'fields.title,fields.slug,fields.hideItem,fields.icon',
        include: '0'
      });

      if (isEmpty(data.items)) {
        logger.warn('Could not find Pages from contentful');
        return null;
      }
      return data.items.map(page => ({
        title: page.fields.title,
        slug: page.fields.slug,
        hideItem: page.fields.hideItem ?? false,
        icon: page.fields.icon ?? null,
        onClick: () => {
          setIsBurgerMenuOpen(false);
        }
      }));
    } catch (error) {
      logger.error('Could not fetch Pages from Contentful');
      return null;
    }
  };

  const navStructure: Record<string, any> = {
    resources: {
      'employer-forms': {
        title: 'Employer Forms',
        slug: '/content/resources/employer-forms',
        hideItem: false,
        onClick: () => setIsBurgerMenuOpen(false)
      }
    },
    'request-forms': {
      'request-training': {
        title: 'Request Training',
        slug: '/content/employer-request-forms/employer-training-request',
        hideItem: false,
        icon: 'DocumentCopySmallIcon',
        onClick: () => setIsBurgerMenuOpen(false)
      },
      'print-materials': {
        title: 'Print Materials',
        slug: '/content/employer-request-forms/print-materials',
        icon: 'DocumentCopySmallIcon',
        hideItem: false,
        onClick: () => setIsBurgerMenuOpen(false)
      }
    }
  };

  useEffect(() => {
    fetchContentfulPages()
      .then(page => {
        setContentfulPageData(page as NavPageItems[]);
        setLoading(true);
      })
      .catch(err => {
        logger.error(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedNavKeys = buildNavTreeV3(contentfulPageData ?? [], navStructure);

  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const isContentfulDynamicNavbarEnabled = isEnabled(
    featureFlags,
    currentFlags.CONTENTFUL_NAV_HEADER
  );

  return (
    <>
      <Header
        isSticky={stickyTopBar}
        includeWrapper={includeWrapper}
        headerItems={topLinks}
        showSignInButton={showSignInButton}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
      />

      {isContentfulDynamicNavbarEnabled && loading ? (
        <>
          {!breakpoint.isLessThanMediumMin && (
            <DynamicNav
              navStructure={navStructure}
              sortedNavKeys={sortedNavKeys}
            />
          )}

          <DynamicBurgerNav
            burgerMenuItems={burgerMenuItems ?? []}
            isBurgerMenuOpen={isBurgerMenuOpen}
            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
            navStructure={navStructure}
            sortedNavKeys={sortedNavKeys}
            footerButton={
              <>
                {showSignInButton && (
                  <Button
                    id="main-nav-signin"
                    sx={{ width: ['fit-content', '100%', null] }}
                    onClick={() => {
                      Router.push('/');
                      setIsBurgerMenuOpen(false);
                    }}
                  >
                    {t('SIGN_IN_TO_EACCESS')}
                  </Button>
                )}

                {showSignOutButton && (
                  <Button
                    id="main-nav-signout"
                    sx={{ width: '100%' }}
                    onClick={() => {
                      dispatch(logoutAction());
                      setIsBurgerMenuOpen(false);
                    }}
                  >
                    <Icon icon={IconNamesSmall.LOG_OUT} />
                    {t('SIGN_OUT_OF_EACCESS')}
                  </Button>
                )}
              </>
            }
          />
        </>
      ) : (
        <>
          {!breakpoint.isLessThanMediumMin && (
            <BottomNavigation
              backgroundColor={bottomNavBGColor}
              includeWrapper={includeWrapper}
              navigationItems={menuItems}
            />
          )}

          <BurgerMenu
            burgerMenuItems={
              burgerMenuItems && menuItems
                ? burgerMenuItems.concat(menuItems)
                : []
            }
            isBurgerMenuOpen={isBurgerMenuOpen}
            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
            footerButton={
              <>
                {showSignInButton && (
                  <Button
                    id="main-nav-signin"
                    sx={{ width: ['fit-content', '100%', null] }}
                    onClick={() => {
                      Router.push('/');
                      setIsBurgerMenuOpen(false);
                    }}
                  >
                    {t('SIGN_IN_TO_EACCESS')}
                  </Button>
                )}

                {showSignOutButton && (
                  <Button
                    id="main-nav-signout"
                    sx={{ width: '100%' }}
                    onClick={() => {
                      dispatch(logoutAction());
                      setIsBurgerMenuOpen(false);
                    }}
                  >
                    <Icon icon={IconNamesSmall.LOG_OUT} />
                    {t('SIGN_OUT_OF_EACCESS')}
                  </Button>
                )}
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default MainNav;
