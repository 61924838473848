import { IconName } from 'components/v2/atomic/icon/Icon';
import { isEmpty, set } from 'lodash';

export interface NavItem {
  id: string;
  buttonText?: string | JSX.Element;
  isDropdown: boolean;
  url?: string;
  subMenuItems?: NavItem[];
  showIconInNav?: boolean;
  hideItem?: boolean;
  name?: string;
  openInNewTab?: boolean;
  icon?: IconName;
}
export type NavPageItems = {
  title: string;
  slug: string;
  hideItem: boolean;
  icon: IconName;
  onClick?: () => void;
};

// Converts string delimited with hyphens to strings with spaces and Capital of first letter
export const convertHyphenatedIDToTitle = (slug: string) =>
  slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// Returns an array of keys in a sorted order
export const sortObjectStructure = (items: Record<string, NavPageItems>) =>
  Object.keys(items).sort((a, b) => a.localeCompare(b));

export const buildNavTreeV3 = (
  pages: NavPageItems[],
  navStructure: Record<string, NavPageItems>
) => {
  pages.forEach(({ title, slug, hideItem, icon, onClick }) => {
    const slugWithoutContentPrefix = slug.startsWith('/content')
      ? slug.replace('/content', '')
      : slug;
    const pathSegments = slugWithoutContentPrefix.split('/').filter(Boolean);
    const lastSegment = pathSegments.pop();

    set(navStructure, [...pathSegments, lastSegment].join('.'), {
      title,
      slug,
      hideItem,
      icon,
      onClick
    });
  });
  return sortObjectStructure(navStructure);
};

// Checks to see if the dropdown has all items that has hideItem === true,
// if all items are hideItem === true we hide the parent button as well
export const isValidDropdown = (items: Record<string, NavPageItems>) => {
  const validSubItems: NavPageItems[] = [];
  Object.keys(items).forEach(key => {
    if (!items[key].hideItem) validSubItems.push(items[key]);
  });
  if (isEmpty(validSubItems)) return false;
  return true;
};

// Leave as Record<string, any> since it requires a really specific nested dict to get icon value
// Gets icon from sub item and returns string of icon name
export const getIconFromSubItem = (
  navStructure: Record<string, any>,
  parentNavItemKey: string
) => {
  let iconName;
  if (navStructure[parentNavItemKey]) {
    Object.keys(navStructure[parentNavItemKey]).forEach(key => {
      if (navStructure[parentNavItemKey][key]) {
        if (navStructure[parentNavItemKey][key].icon) {
          iconName = navStructure[parentNavItemKey][key].icon;
        }
      }
    });
  }
  return iconName;
};
