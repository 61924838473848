import { EapAction, RootState } from 'types';
import {
  all,
  takeEvery,
  put,
  select,
  call,
  delay,
  race,
  takeLatest
} from 'redux-saga/effects';
import Router from 'next/router';

import {
  requestsSearch,
  getPDF,
  deleteForm,
  deleteMultipleForms,
  getReturnedMessagesCount,
  getAdjustmentRequestNumber,
  getRecallRequestNumber,
  form143ChangeValidation,
  Form143ChangeValidationResponse,
  Form143ChangeRequestReponse,
  Form143ChangeError
} from 'services';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { tokenSelector, viewPdf, getApiErrorMessage } from 'utils';
import {
  clearNotifications,
  notifyErrorAction,
  clearLockedFormNotificationAction
} from 'containers/notifications/notificationsActions';
import { responseTypes } from 'types/response-type';
import { showWarningToast } from 'features/v2/toaster';
import clientSide from 'utils/logger/client-side';
import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import { checkMemberContextAction } from 'containers/requestForm/memberEvent/form143Actions';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { FeatureInterface } from 'unleash-client/lib/feature';
import { isEmpty } from 'lodash';
import {
  requestsActionTypes,
  searchSucceeded,
  searchFailed,
  applyFilter,
  clearDeleteCallResponse,
  getReturnedCountActionSucceededFailed,
  validateForm143ChangeFailed,
  validateForm143ChangeSucceeded,
  validateForm143ChangeTimeout,
  showForm143ChangeConfirmation,
  hideForm143ChangeConfirmation,
  showForm143ErrorModal,
  setForm143Context
} from './requestsActions';

export enum form143ChangeTypes {
  ADJUST = 'ADJUST',
  RECALL = 'RECALL',
  CREATE = 'CREATE'
}

export function* validateForm143ChangeSaga({ data }: EapAction) {
  const { userData } = yield select((state: RootState) => state.userSession);
  const featureFlags: FeatureInterface[] = yield select(
    state => state.featureFlags.featureFlags
  );
  try {
    const eventType = data.eventType
      ? data.eventType
      : data.membershipForm143Info.memberReasonForRequest;
    yield put(startLoadingAction());
    const {
      validationRes,
      timeout
    }: {
      validationRes: Form143ChangeValidationResponse;
      timeout: boolean;
    } = yield race({
      validationRes: call(
        form143ChangeValidation,
        data.membershipForm143Info.memberMembershipNumber,
        eventType,
        userData.username
      ),
      timeout: delay(10000, true)
    });

    if (timeout) {
      yield put(validateForm143ChangeTimeout());
      yield put(clearLoadingAction());
      yield put(
        showWarningToast({
          message: 'Sorry, something went wrong. Please try again'
        })
      );

      clientSide.warn(
        `Validating form 143 change timed out with member number ${data.membershipForm143Info.memberMembershipNumber}`
      );
      return;
    }

    if (
      validationRes.result.data.additionalData.errorMessages &&
      validationRes.result.data.additionalData.errorMessages.length > 0
    ) {
      yield put(validateForm143ChangeFailed());
      yield put(clearLoadingAction());
      clientSide.warn(
        `Validating form 143 errored out with member number ${data.membershipForm143Info.memberMembershipNumber}`,
        validationRes
      );

      const {
        mssMessageId,
        errorMessages
      } = validationRes.result.data.additionalData;

      if (mssMessageId) {
        yield put(
          showForm143ErrorModal({
            error: {
              code: mssMessageId
            }
          })
        );
      } else if (errorMessages && !isEmpty(errorMessages)) {
        yield put(
          showForm143ErrorModal({
            error: {
              msgUI: errorMessages[0]
            }
          })
        );
      } else {
        yield put(
          showForm143ErrorModal({
            error: { code: 'GENERIC_ERROR' }
          })
        );
      }
      return;
    }

    if (
      !isEnabled(featureFlags, currentFlags.FORM_143_ADJUSTMENTS_RECALL) &&
      (validationRes.result.data.additionalData.e143ActionDecision ===
        form143ChangeTypes.ADJUST ||
        validationRes.result.data.additionalData.e143ActionDecision ===
          form143ChangeTypes.RECALL)
    ) {
      yield put(validateForm143ChangeSucceeded());
      yield put(clearLoadingAction());
      // This is placebolder as there is no error coming from the backend ... we're simply preventing
      // access on the front end.
      yield put(showForm143ErrorModal({ error: '1014' }));
      return;
    }

    switch (validationRes.result.data.additionalData.e143ActionDecision) {
      case form143ChangeTypes.ADJUST:
      case form143ChangeTypes.RECALL:
        yield put(validateForm143ChangeSucceeded());
        yield put(clearLoadingAction());
        yield put(
          setForm143Context({
            changeType:
              validationRes.result.data.additionalData.e143ActionDecision,
            formType: validationRes.result.data.additionalData.eventType
          })
        );
        yield put(
          showForm143ChangeConfirmation({
            ...data,
            requestNo: validationRes.result.data.additionalData.activeRequestNo
          })
        );
        break;
      case form143ChangeTypes.CREATE:
        yield put(validateForm143ChangeSucceeded());
        yield put(clearLoadingAction());

        if (Router.pathname === '/requests') {
          yield put(showForm143ErrorModal({ error: { code: '11' } }));
          return;
        }

        yield put(
          setForm143Context({
            changeType:
              validationRes.result.data.additionalData.e143ActionDecision,
            formType: validationRes.result.data.additionalData.eventType
          })
        );
        yield put(
          checkMemberContextAction({
            membershipNo: data.membershipForm143Info.memberMembershipNumber,
            employerNo: data.membershipForm143Info.employerNumber,
            validMemberProfile: data?.validMemberProfile
          })
        );
        break;
      default:
        yield put(validateForm143ChangeFailed());
        yield put(clearLoadingAction());
        yield put(showForm143ErrorModal({ error: { code: 'GENERIC_ERROR' } }));
        clientSide.error(
          `143 request error for request #${data.requestNumber}`
        );
        break;
    }
  } catch (e) {
    yield put(validateForm143ChangeFailed());
    yield put(clearLoadingAction());
    yield put(showForm143ErrorModal({ error: { code: 'GENERIC_ERROR' } }));
    clientSide.error(
      `There was an error validating the 143 change request for request #${data.requestNo}`,
      {},
      e as Error
    );
  }
}

export function* completeForm143ChangeSaga({ data }: EapAction) {
  try {
    const isError = (
      res: Form143ChangeRequestReponse
    ): res is Form143ChangeError =>
      Object.prototype.hasOwnProperty.call(res, 'errors');

    if (data.form143ChangeType === form143ChangeTypes.ADJUST) {
      const adjustmentResponse: Form143ChangeRequestReponse = yield call(
        getAdjustmentRequestNumber,
        data.requestNumber
      );
      yield put(hideForm143ChangeConfirmation());

      if (isError(adjustmentResponse)) {
        clientSide.error(
          `There was an error completing the adjustment request for request #${data.requestNumber}`
        );
        yield put(
          showForm143ErrorModal({ error: adjustmentResponse.errors[0] })
        );
        return;
      }

      Router.push(`/form/143/${adjustmentResponse.data.requestNo}`);
    } else if (data.form143ChangeType === form143ChangeTypes.RECALL) {
      const recallResponse: Form143ChangeRequestReponse = yield call(
        getRecallRequestNumber,
        data.requestNumber
      );
      yield put(hideForm143ChangeConfirmation());

      if (isError(recallResponse)) {
        clientSide.error(
          `There was an error completing the recall request for request #${data.requestNumber}`
        );
        yield put(showForm143ErrorModal({ error: recallResponse.errors[0] }));
        return;
      }
      Router.push(`/form/143/${recallResponse.data.requestNo}`);
    }
  } catch (e) {
    yield put(hideForm143ChangeConfirmation());
    yield put(showForm143ErrorModal({ error: { code: 'GENERIC_ERROR' } }));
    clientSide.error(
      `There was an error completing the ${
        data.form143ChangeType === form143ChangeTypes.ADJUST
          ? 'adjustment'
          : data.form143ChangeType === form143ChangeTypes.RECALL
          ? 'recall'
          : '143 change'
      } request for request #${data.requestNumber}`,
      {},
      e as Error
    );
  }
}

export function* applyFilterSaga(action: EapAction): any {
  const employerNumber = yield select(
    (state: RootState) => state.selectEmployers.selectEmployerNumber
  );

  const res = yield call(safeAuthEffect, requestsSearch, {
    ...action.data.query,
    // If SET_SORT spawns this saga, we should use those values instead
    searchAscending:
      action.data.searchAscending ?? action.data.query.searchAscending,
    sortByLastName:
      action.data.sortByLastName ?? action.data.query.sortByLastName,
    employerNumber
  });
  const tab = action.data.tab;

  if (res.data) {
    yield put(searchSucceeded({ tab, tableData: res }));
    yield put(clearNotifications());

    // prevent locked message clear on home page only, handles edge case of
    // home page running applyFilter on error -- no other requests table
    // should reapply filter on error
    if (!['/home', '/requests'].includes(Router.pathname)) {
      yield put(clearLockedFormNotificationAction());
    }
  } else {
    yield put(searchFailed({ tab }));

    // prevent no results found error from updating notifications
    if (res?.errors && res.errors.length > 0 && res.errors[0].code !== '318') {
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }
  }
}

export function* deleteFormSaga(action: EapAction): any {
  let { requestsPendingDeleteOrOverride } = yield select(
    (state: RootState) => state.requests
  );
  let deleteCallResponse = { type: 'success', deleted: 1, notDeleted: 0 };

  const res = yield deleteForm(action.data.request);

  if (res.status === 200 || res.status === 204) {
    requestsPendingDeleteOrOverride = requestsPendingDeleteOrOverride.filter(
      (request: string) => request !== action.data.request
    );
    yield put({
      type: requestsActionTypes.DELETE_FORM_SUCCEEDED,
      data: {
        requestsPendingDeleteOrOverride,
        deleteCallResponse,
        preventRefresh: action.data.preventRefresh
      }
    });
  } else {
    deleteCallResponse = { type: 'error', deleted: 0, notDeleted: 1 };
    yield put({
      type: requestsActionTypes.DELETE_FORM_FAILED,
      data: {
        requestsPendingDeleteOrOverride,
        deleteCallResponse
      }
    });
  }
}

export function* deleteMultipleFormsSaga(action: EapAction): any {
  let requestsPendingDeleteOrOverride = action.data.requests;
  let deleteCallResponse = {
    type: 'success',
    deleted: requestsPendingDeleteOrOverride.length,
    notDeleted: 0
  };

  const res = yield deleteMultipleForms(requestsPendingDeleteOrOverride);

  if (res.status === 200 || res.status === 204 || res.data) {
    requestsPendingDeleteOrOverride = [];
    if (res.data && res.data.result) {
      requestsPendingDeleteOrOverride = res.data.result.failed;
      deleteCallResponse = {
        type: 'warning',
        deleted: res.data.result.successCount,
        notDeleted: res.data.result.failureCount
      };
    }
    yield put({
      type: requestsActionTypes.DELETE_MULTIPLE_FORMS_SUCCEEDED,
      data: {
        requestsPendingDeleteOrOverride,
        deleteCallResponse,
        preventRefresh: action.data.preventRefresh
      }
    });
  } else {
    deleteCallResponse = {
      type: 'error',
      deleted: 0,
      notDeleted: requestsPendingDeleteOrOverride.length
    };
    yield put({
      type: requestsActionTypes.DELETE_MULTIPLE_FORMS_FAILED,
      data: { requestsPendingDeleteOrOverride, deleteCallResponse }
    });
  }
}

function* refreshAfterDeleteSaga(action: EapAction) {
  if (!action.data.preventRefresh) {
    const { filter, query } = yield select(
      (state: RootState) => state.requests.filterNotSubmitted
    );
    yield put(
      applyFilter({
        tab: 'notSubmitted',
        filter,
        query
      })
    );
  }
}

export function* getPDFSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(getPDF, action.data);
  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    yield put(clearLoadingAction());
    yield call(viewPdf, res, 'e-Form 102: Member Enrolment');
    yield put({
      type: requestsActionTypes.GET_REQUEST_PDF_SUCCEEDED,
      data: res.data
    });
  } else {
    yield put(clearLoadingAction());
    yield put({
      type: requestsActionTypes.GET_REQUEST_PDF_FAILED
    });
  }
}

function* clearDeleteCallResponseSaga() {
  yield delay(5000);
  yield put(clearDeleteCallResponse());
}

export function* getReturnedMessagesCountSaga(): any {
  const token = yield select(tokenSelector);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { isAuthenticated } = yield select(
    (state: RootState) => state.userSession
  );

  if (isAuthenticated) {
    const res = yield call(
      safeAuthEffect,
      getReturnedMessagesCount,
      selectGroupNumber,
      token
    );
    yield put(getReturnedCountActionSucceededFailed(res?.data?.count));
  }
}

export function* requestsSaga(): any {
  yield all([
    yield takeEvery(
      [requestsActionTypes.APPLY_FILTER, requestsActionTypes.SET_SORT],
      applyFilterSaga
    ),
    yield takeEvery(requestsActionTypes.GET_REQUEST_PDF_REQUESTED, getPDFSaga),
    yield takeEvery(requestsActionTypes.DELETE_FORM_REQUESTED, deleteFormSaga),
    yield takeEvery(
      requestsActionTypes.GET_RETURNED_ACTIONS_COUNT_REQUESTED,
      getReturnedMessagesCountSaga
    ),
    yield takeEvery(
      requestsActionTypes.DELETE_MULTIPLE_FORMS_REQUESTED,
      deleteMultipleFormsSaga
    ),
    yield takeEvery(
      [
        requestsActionTypes.DELETE_FORM_SUCCEEDED,
        requestsActionTypes.DELETE_MULTIPLE_FORMS_SUCCEEDED
      ],
      refreshAfterDeleteSaga
    ),
    yield takeEvery(
      [
        requestsActionTypes.DELETE_FORM_FAILED,
        requestsActionTypes.DELETE_MULTIPLE_FORMS_FAILED,
        requestsActionTypes.DELETE_FORM_SUCCEEDED,
        requestsActionTypes.DELETE_MULTIPLE_FORMS_SUCCEEDED,
        requestsActionTypes.SEARCH_SUCCEEDED
      ],
      clearDeleteCallResponseSaga
    ),
    yield takeLatest(
      [requestsActionTypes.VALIDATE_FORM_143_CHANGE_TYPE],
      validateForm143ChangeSaga
    ),
    yield takeLatest(
      [requestsActionTypes.COMPLETE_FORM_143_CHANGE],
      completeForm143ChangeSaga
    )
  ]);
}
